import { ProgramFee } from './program-fee.model';

export class PaymentRequest {
    appId: string;
    paymentAmt: number;
    addFeeInd: string;  // C (Conversion) or B (Balance Due)
    previousProgramCode : string = '';  //empty unless Conversion, then pre-convert programCode
    source: string;
    reqType: string;
    programList : ProgramFee[];
    // programList: [{
    //   programCode: string;
    //   feeCode: string;
    //   amount: number;
    // }];
}
