<div class="page-content">
    <div class="row">
        <div class="col-12 progress-top-level centered">
            <h1><span translate>PROGRESS.SCHEDULE_INTERVIEW</span>: {{programName}}</h1>
            <p class="divider d-none d-md-block"></p>
        </div>
    </div>
    <div class="container">
        <div class="col-lg-12">
            <div class="row">
               <div class="content-panel interview-review" id="print-area">
                    <div class="row">
                        <div class="col-12">
                            <h2 translate>INTERVIEW_CANCEL.SUMMARY.TITLE</h2>
                            
                            <table class="payment-table table table-striped">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="title-row" translate>INTERVIEW_CANCEL.SUMMARY.SUBHEAD</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CANCEL.SUMMARY.DATE_CANCELLED</td>
                                        <td><span translate>{{dayOfWeek}}</span>, <span translate>{{month}}</span> {{appointmentModel?.lastUpdatedDateObject | date: 'dd, y'}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CANCEL.REASON</td>
                                        <td>{{appointmentModel?.notes}}</td>
                                    </tr>
                                    <tr *ngIf="!appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.ENROLLMENT_CENTER</td>
                                        <td>{{appointmentModel?.locationName}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.DATE</td>
                                        <td>{{appointmentModel?.startDateObject | date: 'EEE, MMM dd, y'}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.TIME</td>
                                        <td>{{appointmentModel?.startTime}}
                                            <span *ngIf="appointmentModel?.startDateObject?.getHours() >= 12" translate>INTERVIEW_CONFIRMATION.PM</span>
                                            <span *ngIf="appointmentModel?.startDateObject?.getHours() < 12" translate>INTERVIEW_CONFIRMATION.AM</span>
                                            <span> {{appointmentModel.tzData}} ({{moment.tz(appointmentModel.tzData).format('z')}})</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.PHONE</td>
                                        <td>{{appointmentModel?.locationPhone}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <!--<div class="sub-header">
                                <h3 translate>INTERVIEW_CANCEL.SUMMARY.SUBHEAD</h3>
                            </div>
                            <dl class="dl-horizontal">
                                <dt translate>INTERVIEW_CANCEL.SUMMARY.DATE_CANCELLED</dt>
                                    <dd>{{appointmentModel?.lastUpdatedDateObject | date}}</dd>
                                <dt translate>INTERVIEW_CANCEL.REASON</dt>
                                    <dd>{{appointmentModel?.notes}}</dd>
                                <dt translate>INTERVIEW_CONFIRMATION.ENROLLMENT_CENTER</dt>
                                    <dd>{{appointmentModel?.locationName}}</dd>
                                <dt translate>INTERVIEW_CONFIRMATION.DATE</dt>
                                    <dd>{{appointmentModel?.startDateObject | date: 'EEE, MMM dd, y'}}</dd>
                                <dt translate>INTERVIEW_CONFIRMATION.TIME</dt>
                                    <dd>{{appointmentModel?.startTime}}
                                        <span *ngIf="appointmentModel?.startDateObject?.getHours() >= 12" translate>INTERVIEW_CONFIRMATION.PM</span>
                                        <span *ngIf="appointmentModel?.startDateObject?.getHours() < 12" translate>INTERVIEW_CONFIRMATION.AM</span>
                                    </dd>
                                <dt translate>INTERVIEW_CONFIRMATION.PHONE</dt>
                                    <dd>{{appointmentModel?.locationPhone}}</dd>
                            </dl>-->

                            <table class="payment-table table table-striped">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="title-row" translate>INTERVIEW_CANCEL.APPLICATION</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.PROGRAM</td>
                                        <td>{{programName}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.APPLICATION_ID</td>
                                        <td>{{applicationMetadata?.applicationId}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.PASSID</td>
                                        <td>{{user?.membershipOrPassId}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <!--<div class="sub-header">
                                <h3 translate>INTERVIEW_CANCEL.APPLICATION</h3>
                            </div>

                            <dl class="dl-horizontal">
                                <dt translate>INTERVIEW_CONFIRMATION.PROGRAM</dt>
                                    <dd>{{programName}}</dd>
                                <dt translate>INTERVIEW_CONFIRMATION.APPLICATION_ID</dt>
                                    <dd>{{applicationMetadata?.applicationId}}</dd>
                                <dt translate>INTERVIEW_CONFIRMATION.PASSID</dt>
                                    <dd>{{user?.membershipOrPassId}}</dd>
                            </dl>-->

                            <div class="page-hint">
                                <i class="far fa-lightbulb"></i>
                                <span [innerHTML]="'INTERVIEW_CANCEL.SUMMARY.INFO' | translate"></span>
                            </div>

                        </div>
                    </div>
                </div> <!-- end content panel -->

                

            </div>

            <div class="row row-button-group">
                <div class="col-12 col-md-4 col-lg-3 ms-auto order-md-2">
                    <button class="btn btn-primary btn-nav d-block w-100" id="btnDone" (click)="onDone($event)"><span translate>GENERAL_REUSABLE.DONE</span></button>
                    <div class="col-12" style="text-align: right; color: #666">
                        <i translate>SIGN_UP.RETURN_TO_DASHBOARD</i>
                    </div>
                </div>
                <div class="col-12 col-md-4 col-lg-3 order-md-1">
                    <button id="btnPrint" class="btn btn-default d-block w-100 btn-nav" (click)="openPrintView($event)" (keyup.enter)="openPrintView($event)" ><span translate>GENERAL_REUSABLE.PRINT_BUTTON</span></button>
                </div>
            </div> <!-- end button group -->

        </div>
    </div> <!-- end container -->
</div> <!-- end page content -->