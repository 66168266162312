import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, from, of, throwError } from 'rxjs';
import { JwtInterceptor } from '@auth0/angular-jwt';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthHttpService } from './common/services/auth-http.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthService } from './common/services/auth.service';

@Injectable()
export class JwtHttpInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService,
                private authHttpervice: AuthHttpService,
                private jwtInterceptor: JwtInterceptor,
                private jwtHelper: JwtHelperService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let clone: HttpRequest<any>;

        if (environment.authenticateWithCookie) {
            const route = request.url;

            if (route.indexOf('goesapp/reference/data') >= 0) {   // white list
                clone = request.clone({ withCredentials: false });
            } else {
                clone = request.clone({ withCredentials: true });
            }
            if (route.indexOf('/credential/') >= 0) {
                return next.handle(clone);
            } else {
                return from(this.authHttpervice.doRefresh())
                    .pipe(
                        switchMap(() => {
                            return next.handle(clone).pipe(
                                catchError((err: HttpErrorResponse) => {
                                    if ([401].includes(err.status) && this.authService.isUserLoggedIn()) {
                                        // auto logout if 401 response returned from api
                                        this.authService.tokenInvalidatedHandler();
                                        return EMPTY;
                                    }

                                    if (this.isTTPAccessViolation(err) && this.authService.isUserLoggedIn()) {
                                        // Light logout on 4403 error from api
                                        this.authService.lightLogout();

                                        return EMPTY;
                                    }

                                    return throwError(err);
                                })
                            );
                        })
                    );
            }
        } else {
            clone = request.clone();
            const route = request.url;

            if (route.startsWith(environment.uriCSEndpoint)) {
                return next.handle(clone);
            } else {
                return from(this.authHttpervice.doRefresh())
                    .pipe(
                        switchMap(() => {
                            if (this.jwtInterceptor.isAllowedDomain(clone) &&
                                !this.jwtInterceptor.isDisallowedRoute(clone)) {
                                if (!this.jwtHelper.isTokenExpired()) {
                                    return next.handle(clone);
                                } else {
                                    return next.handle(clone);
                                }
                            } else {
                                return next.handle(clone);
                            }
                        }),
                        catchError((err: HttpErrorResponse) => {
                            if ([401].includes(err.status) && this.authService.isUserLoggedIn()) {
                                // auto logout if 401 response returned from api
                                this.authService.tokenInvalidatedHandler();
                                return EMPTY;
                            }

                            if (this.isTTPAccessViolation(err) && this.authService.isUserLoggedIn()) {
                                // Light logout on 4403 error from api
                                this.authService.lightLogout();

                                return EMPTY;
                            }

                            return throwError(err);
                        })
                    );
            }
        }
    }

    private isTTPAccessViolation(err: HttpErrorResponse) : boolean {
        if ([500].includes(err.status) && this.getErrorCodes(err) == '4403') {
            return true;
        }

        return false;
    }

    private getErrorCodes(error: any): string {
        try {
          const errorBody = (error as HttpErrorResponse).error;
 
          if (errorBody.hasOwnProperty('errorCode')) {
            return errorBody.errorCode.toString();
          }
 
        } catch (e) {
          // console.log('Failed to parse response.');
        }
        return '0'; // general error code
      }
}
