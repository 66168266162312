import { Component, OnInit, AfterViewChecked, ViewChild } from '@angular/core';
import { NgForm} from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SpinnerService } from '../shared/spinner/spinner.service';

import {Observable} from 'rxjs';
import { DashboardService } from '../common/services/dashboard.service';
import { ApplicationMetadata } from '../common/models/application-metadata.model';
import { DashboardMetadata } from '../common/models/dashboard-metadata.model';
import { SignUpPersonal } from '../common/models/sign-up-personal.model';
import { Country } from '../common/models/country.model';
import { AuthService } from '../common/services/auth.service';
import { SignUpService } from '../common/services/sign-up.service';
import { User } from '../common/models/user';
import { TranslateReferenceService } from '../common/services/translate-reference.service';
import { TemplateValidationService } from '../common/services/template-validation.service';
import { Phone } from '../common/models/phone';
import { SETTINGS } from '../app.constants';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../shared/modals/modal.service';
import Utils from '../common/utils/utils';
import { PhoneService } from '../common/services/phone.service';
import { GoesDate } from '../common/models/date.model';
import { map } from 'rxjs/operators';

// eslint-disable-next-line no-var
declare var $: any;
@Component({
    templateUrl: 'account-profile.component.html',
    standalone: false
})

export class AccountProfileComponent implements OnInit, AfterViewChecked {

    @ViewChild('personalInfoForm') currentForm: NgForm;
    @ViewChild('dateOfBirth') dateOfBirth: any;

    _submitted = false;

    private callbackURL: string;
    textPattern: RegExp = SETTINGS.textInputPattern;
    textAlphaPattern: RegExp = SETTINGS.oneAlphaInputPattern;
    numberPattern: RegExp = SETTINGS.numberPattern;
    infoForm: NgForm;
    applications: ApplicationMetadata[] =  [];
    memberships: any = [];
    dashboard: DashboardMetadata;
    alphabeticPattern: RegExp = SETTINGS.alphabeticPattern;
    emailPattern: RegExp = SETTINGS.emailPattern;
    fastIdPattern: RegExp = SETTINGS.fastIdPattern;
    passIdPattern: RegExp = SETTINGS.passIdPattern;
    prePopEmail: string;
    editMode = false;
    updateApp = false;
    nextCaption: string;
    wipeEmail = true;
    passIdProvided = false;
    hasConfirmed = false;

    secSubmitted = false;
    selectedQuestion = '';
    selectedAnswer = '';
    canceled = false;
    attemptsLeft: number;

    minDate = '1901-01-01';
    maxDate: string = Utils.getTodayStr();

    formErrors = {
        lastName: [''],
        firstName: [''],
        middleName: [''],
        dateOfBirth: [''],
        countryOfBirth: [''],
        cityOfBirth: [''],
        stateOfBirth: [''],
        emailAddress: [''],
        emailConfirmation: [''],
        appliedTPP: [''],
        appliedFast: [''],
        appliedBefore: [''],
        passId: [''],
        fastId: [''],
        appToggle: [''],
        goesId : ['']
    };

    validationMessages = {
        lastName: {
            required: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.LAST_NAME_REQUIRED',
            pattern: 'ERROR_MESSAGES.GENERAL.NAME_PATTERN'
        },
        firstName: {
            required: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.FIRST_NAME_REQUIRED',
            pattern : 'ERROR_MESSAGES.GENERAL.FIRST_NAME_PATTERN'
        },
        middleName: {
            pattern : 'ERROR_MESSAGES.GENERAL.NAME_PATTERN',
        },
        dateOfBirth: {
            required: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.DATE_OF_BIRTH_REQUIRED',
            postToday: ''
        },
        countryOfBirth: {
            required: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.COUNTRY_OF_BIRTH_REQUIRED'
        },
        cityOfBirth: {
            required: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.CITY_OF_BIRTH_REQUIRED'
        },
        stateOfBirth: {
            required: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.STATE_OF_BIRTH_REQUIRED'
        },
        emailAddress: {
            pattern: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.EMAIL_PATTERN'
        },
        emailConfirmation: {
            required: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.EMAIL_CONFIRM_REQUIRED',
            pattern: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.EMAIL_PATTERN',
            match: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.EMAIL_MATCH'
        },
        appliedTPP: {
            required: 'ERROR_MESSAGES.GENERAL.QUESTION_REQUIRED'
        },
        appliedFast: {
            required: 'ERROR_MESSAGES.GENERAL.QUESTION_REQUIRED'
        },
        appliedBefore: {
            required: 'ERROR_MESSAGES.GENERAL.QUESTION_REQUIRED'
        },
        passId: {
            required: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.MEMBERSHIP_PASSID_REQUIRED',
            pattern: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.MEMBERSHIP_PASSID_PATTERN',
            minlength: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.MEMBERSHIP_PASSID_MIN'
        },
        fastId: {
            required: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.FASTID_REQUIRED',
            minlength: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.FASTID_MIN',
            pattern: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.FASTID_PATTERN'
        },
        goesId : {
            required: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.GOESID_REQUIRED',
            minlength: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.GOESID_MIN',
            pattern: 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.GOESID_PATTERN'
        },
        appToggle: {
            required: 'ERROR_MESSAGES.GENERAL.QUESTION_REQUIRED'
        }
    };

    editDisabled = {
        lastName : true,
        firstName : true,
        middleName : true,
        dob : true,
        countryOfBirth : true,
        stateOfBirth : true,
        cityOfBirth : true,
        phone : true,
        fastId : false,
        passId : false
    };


    personalInfo: SignUpPersonal;
    countries: Country[];
    usStates: any;
    caStates: any;
    mxStates: any;
    appToggle: string;
    verificationToggle: string;
    user: User;
    docTypes: string[];
    docSubmitted = false;
    returned = false;

    constructor(private signUpService: SignUpService,
                private spinner: SpinnerService,
                private phoneService: PhoneService,
                private referenceService: TranslateReferenceService,
                private validationService: TemplateValidationService,
                private dashboardService: DashboardService,
                private authService: AuthService,
                private modalService: ModalService,
                private translate: TranslateService,
                private route: ActivatedRoute,
                private router: Router) {}

    ngOnInit(): void {

        this.user = this.authService.getUser();
        if (this.user && this.user.name) {
            this.editMode = true;
            // may need to call dashboard service, or safe to assume we'll have data ?
            this.applications = this.dashboardService.getActiveApplications();
            this.dashboard = this.dashboardService.dashboard;
            if (this.dashboard) { this.memberships = this.dashboard.memberships; }
            this.initDisabled();
            this.wipeEmail = false;
        }

        this.setup(this.user);
        this.initCallBack();
        this.initStates();
        this.initCountries();
    }

    ngAfterViewChecked() {
        this.formChanged();
    }

    private setup(user: User) {
        this.route.params.subscribe((params: Params) => {
            const userId = params.id;
            if (userId) {
                this.signUpService.getUser(userId).subscribe(res => {

                    this.personalInfo = this.signUpService.getSignUp();

                    // set user Id
                    if (user) {
                        this.personalInfo.userId = user.userId;
                    }

                    // set email address
                    if (this.personalInfo.emailAddress) {
                        // email address is present, so set it as confirmEmailAddress
                        this.personalInfo.confirmEmailAddress = this.personalInfo.emailAddress;
                    }
                    else if (user && user.userId) {
                        // email address is not present, so pre-populate with login.gov info
                        this.personalInfo.emailAddress = user.email;
                        this.personalInfo.confirmEmailAddress = user.email;
                    }

                    // initialize phone numbers
                    this.initPhoneNumbers();

                    // set if passId is provided
                    if (this.personalInfo.passId || this.personalInfo.fastId) {
                        this.passIdProvided = true;
                    }

                });
            }
        });
    }

    private initCountries() {
        this.referenceService.pullData().subscribe(res => {
            this.countries = this.referenceService.getCountries();
        });
    }

    getMinlength(phoneFormat: string): number {
        return Utils.getPhoneMinlength(phoneFormat);
    }

    private initCallBack(){
        const callback = this.signUpService.getCallback();
        if (callback) {
            if (callback.indexOf('application') > 0) {
                this.updateApp = true;
                this.nextCaption = 'SIGN_UP.RETURN_TO_APPLICATION';
            } else if (callback.indexOf('dashboard') > 0) {
                this.nextCaption = 'SIGN_UP.RETURN_TO_DASHBOARD';
            }
        }
    }

    private initStates() {
        this.usStates = this.referenceService.getBirthStates();
        this.caStates = this.referenceService.getRef('states.CA');
        this.mxStates = this.referenceService.getRef('states.MX');
    }

    private initPhoneNumbers(): void {
        if (this.personalInfo.phoneNumbers.length === 0) {
            const phone = {
                format: '',
                type: '',
                number: '',
                extension: '',
                countryCode: ''
            };
            this.personalInfo.phoneNumbers.push(phone);
        }
    }

    /* strip everything from phone number other than digits */
    private setPhone(): void {
        for (const phone of this.personalInfo.phoneNumbers) {
            phone.number = phone.number.replace(/[^0-9]/g, '');
        }
    }

    addPhone(): void {
        if (this.personalInfo.phoneNumbers.length <= 2) {
            const phone = {
                format: '',
                type: '',
                number: '',
                extension: '',
                countryCode: ''
            };
            this.personalInfo.phoneNumbers.push(phone);
        }
    }

    removePhone(phone: Phone): void {
        const index = this.personalInfo.phoneNumbers.indexOf(phone);
        this.personalInfo.phoneNumbers.splice(index, 1);
    }

    /* determines if the phone is the last entry in the phone number list */
    isLastEntry(phone: Phone): boolean {
        const index = this.personalInfo.phoneNumbers.indexOf(phone);
        const size = this.personalInfo.phoneNumbers.length;
        if (index === size - 1) { return true; }
        else { return false; }
    }

    cleanEmail(): void {
        if (this.wipeEmail) {
            this.personalInfo.confirmEmailAddress = '';
            this.wipeEmail = false;
        }
    }


    optOut(): void {
        if (!this.personalInfo.emailOptoutInd) {
            this.personalInfo.emailAddress = '';
            this.personalInfo.confirmEmailAddress = '';
        }
    }

    // function for clearing away any hidden data elements depending on section (id).
    cleanData(section: number): void {
        switch (section) {
            // previous program
            case 1:
                if (this.personalInfo.appliedTPP !== 'Y' && this.personalInfo.appliedFast !== 'Y') {
                    this.personalInfo.passId = '';
                    this.personalInfo.fastId = '';
                    this.personalInfo.goesId = '';
                    this.appToggle = '';
                }
                if (this.personalInfo.appliedFast !== 'Y') {
                    this.personalInfo.fastId = '';
                }
                if (this.personalInfo.appliedBefore !== 'Y') {
                    this.personalInfo.goesId = '';
                }
                if (this.personalInfo.passId) {
                    this.appToggle = 'TPP';
                }
                break;
                // program toggle
            case 2:
                if (this.appToggle === 'TPP') {
                    this.personalInfo.fastId = '';
                    this.personalInfo.goesId = '';
                }
                if (this.appToggle === 'FAST') {
                    this.personalInfo.passId = '';
                    this.personalInfo.goesId = '';
                }

                if (this.appToggle === 'PASS') {
                    this.personalInfo.fastId = '';
                    this.personalInfo.passId = '';
                }
                break;
        }
    }

     updateCode(phone: any): void {
         // console.log(phone);
         if (phone.format && phone.format === 'M'){
             phone.countryCode = '52';
                      }
         else{
             phone.countryCode = '';
         }
         phone.number = '';
     }

    private initDisabled(): void {
        if (this.dashboard) {

            const hasPassID: boolean = (this.dashboard.passId !== '');
            
            if (!hasPassID) {                
                this.editDisabled = {
                    lastName : false,
                    firstName : false,
                    middleName : false,
                    dob : false,
                    countryOfBirth : false,
                    stateOfBirth : false,
                    cityOfBirth : false,
                    phone : false,
                    passId : false,
                    fastId : false
                };
            } else {
                this.editDisabled = {
                    lastName : true,
                    firstName : true,
                    middleName : true,
                    dob : true,
                    countryOfBirth : true,
                    stateOfBirth : false,
                    cityOfBirth : false,
                    phone : false,
                    passId : true,
                    fastId : true
                };
            }
        }
        // if never been to dashboard (first time user), dont disable
        else {
            this.editDisabled = {
                lastName : false,
                firstName : false,
                middleName : false,
                dob : false,
                countryOfBirth : false,
                stateOfBirth : false,
                cityOfBirth : false,
                phone : false,
                passId: false,
                fastId : false
            };
        }

    }


    formChanged() {
        if (this.currentForm === this.infoForm) {
            return;
        }
        this.infoForm = this.currentForm;
        if (this.infoForm) {
            this.infoForm.valueChanges
                .subscribe(data => {
                    this.formErrors =
                        this.validationService.validateForm(this.infoForm, this.formErrors, this.validationMessages, this._submitted);
                });
        }
    }


    // since blur events don't trigger form valueChanges, manually trigger updates for blur fields
    updateBlur() {
        this.formErrors = this.validationService.validateForm(this.infoForm, this.formErrors, this.validationMessages, this._submitted);
    }

    validateNumber(phoneNumber: string, countryCode: string): boolean{
        // return this.phoneService.isValid(phoneNumber, countryCode);
        return true;
    }

    viewImage(): void {
         this.modalService.notify('SIGN_UP.MEMBERSHIP_CARD_BODY',
                                     'SIGN_UP.MEMBERSHIP_CARD_TITLE', 'APPLICATION.DOCUMENTS.DOCUMENT_CARD.DONE');
    }

    // when the user answers a security question, call service, update user object,
    // if correct, go to dashboard, if incorrect, show message, if incorrect x3, show message, go to dashboard
    /**
     * @deprecated
     */
    validateSecurityQuestion(): any {
        this.secSubmitted = true;
        if (this.selectedAnswer && this.selectedQuestion) {
            for (const question of this.personalInfo.questionList) {
                if (question.question && question.question === this.selectedQuestion) {
                    question.answer = this.selectedAnswer;
                }
                else {
                    question.answer = '';
                }
            }
            this.spinner.show();
            this.signUpService.validateSecurityQuestions(this.personalInfo).subscribe(res => {
                this.spinner.hide();
                if (res) {
                    this.personalInfo.answerSuccess = res.answerSuccess;
                    this.personalInfo.maxAttempt = res.maxAttempt;
                    if (res.answerSuccess === true) {
                        if (res.membershipId){
                            const dob = new GoesDate(null, null, null, res.dateOfBirth);
                            const mapUser = {
                                userId: res.userId,
                                username: res.userId,
                                membershipOrPassId: res.membershipId,
                                name: res.firstName + ' ' + res.lastName,
                                firstName: res.firstName,
                                lastName: res.lastName,
                                dateOfBirth: dob != null ? dob.getSimpleString() : this.personalInfo.dateOfBirth.getSimpleString(),
                                email: res.emailAddress,
                                loginGovEmail : this.user.loginGovEmail,
                                age: res.age
                            };
                            this.authService.setUser(mapUser);
                        }
                        if (res.linkedAccountEmail) {
                            this.personalInfo.passId = res.membershipId;
                            if ( res.linkedAccountEmail === 'No Valid login.gov email was bound to this account') {
                                this.translate.get('SIGN_UP.NO_EMAIL_FOUND').subscribe(res => {
                                    this.personalInfo.linkedAccountEmail = res;
                                 });
                            } else {
                                this.personalInfo.linkedAccountEmail = res.linkedAccountEmail;
                            }
                            this.openLinked();
                        }
                        else{
                            this.continue();
                        }

                    }
                    else if (res.answerSuccess === false && res.maxAttempt === 3) {
                        this.openVerificationOptionModal();
                    }
                }
                // check for 200 error? then start up confirm relink
            });
        }
    }

    getAttempts(attempts: any): number{
        if ( typeof attempts === 'string'){
            return 3 - parseInt(attempts);
        }
        if ( typeof attempts === 'number'){
            return 3 - attempts;
        }
        return attempts;
    }

    cancelSecurityQuestions(): void {
        this.canceled = true;
        $('#questionModal').modal('hide');
        $('#docInfoModal').modal('hide');
        $('#verificationOptionModal').modal('hide');
        // $('#whatNextModal').modal({
        //     backdrop : 'static',
        //     keyboard : false,
        //     show : true
        // });
        $('#whatNextModal').modal('show');
    }

    logout(): void {
        $('.modal').modal('hide');
        this.authService.logOut();
        Utils.scrollUp();
    }

    continue(): void {
        this.spinner.show();
        $('.modal').modal('hide');
        this.router.navigate(['/dashboard']).then(res => this.spinner.hide());
    }


    openConfirm(): void {
        $('#accountModal').modal('show');
    }

    confirm(): void {
        this.hasConfirmed = true;
        $('#accountModal').modal('hide');
        this.submitForm();
    }

    submitForm(): any {
        this._submitted = true;
        this.dateOfBirth.parentSubmitted = true;
        this.updateBlur();

        if (this.currentForm.valid && this.dateOfBirth.isValid()) {

            if (this.personalInfo.phoneNumbers) {
                this.personalInfo.phoneNumbers.forEach(phone => {
                    phone.countryCode = phone.format === 'N' ? '1' : phone.countryCode;
                });
            }
            this.spinner.show();
            if (this.editMode) {
                const makeGESLink = this.passIdProvided ? false : true;
                this.signUpService.updateUser(this.personalInfo, makeGESLink).subscribe(res => {
                        this.spinner.hide();
                        if (res) {
                            // security question link
                            if (this.personalInfo.goesId &&
                                    (this.personalInfo.maxAttempt !== 3  || this.personalInfo.maxDocAttempt !== 3) &&
                                    !this.personalInfo.passId && !this.personalInfo.fastId) {
                                if (res.questions) {
                                    this.personalInfo.questionList = res.questions;
                                    this.personalInfo.maxAttempt = res.maxAttempt;
                                    this.personalInfo.maxDocAttempt = res.maxDocAttempt;
                                    // this.initQuestionList();
                                    this.openVerificationOptionModal();

                                }
                            }
                            // relink found
                            else if (res.linkedAccountEmail && res.membershipId){
                                if ( res.linkedAccountEmail === 'No Valid login.gov email was bound to this account') {
                                    this.translate.get('SIGN_UP.NO_EMAIL_FOUND').subscribe(res => {
                                        this.personalInfo.linkedAccountEmail = res;
                                     });
                                } else {
                                    this.personalInfo.linkedAccountEmail = res.linkedAccountEmail;
                                }
                                this.personalInfo.passId = res.membershipId;
                                this.openLinked();
                            }


                            const tempUser = {
                                userId: res.userId,
                                username: res.userId,
                                membershipOrPassId: res.membershipId,
                                name: res.firstName + ' ' + res.lastName,
                                firstName: res.firstName,
                                lastName: res.lastName,
                                dateOfBirth : res.dateOfBirth,
                                email: res.emailAddress,
                                loginGovEmail : this.user.loginGovEmail,
                                age: res.age
                            };
                            this.authService.setUser(tempUser);
                        }
                        if (this.updateApp && res) {
                        this.signUpService.updateApplication(res);
                    }
                });
            } else {
                // open confirm modal
                if (!this.hasConfirmed) {
                    this.openConfirm();
                    this.spinner.hide();
                }
                else{
                    // if new user OR need questionList
                    this.signUpService.createUser(this.personalInfo).subscribe(res => {
                        this.spinner.hide();
                        if (res) {
                            // check for GOESID, then create popup
                            if (this.personalInfo.goesId){
                                if (res.questions){
                                    this.personalInfo.questionList = res.questions;
                                    this.personalInfo.maxAttempt = res.maxAttempt;
                                    this.personalInfo.maxDocAttempt = res.maxDocAttempt;
                                    // this.initQuestionList();
                                    this.openVerificationOptionModal();
                                }
                            }
                            // check for linked account email for relinking flow
                            else if (res.linkedAccountEmail && res.membershipId) {
                                if ( res.linkedAccountEmail === 'No Valid login.gov email was bound to this account') {
                                    this.translate.get('SIGN_UP.NO_EMAIL_FOUND').subscribe(res => {
                                        this.personalInfo.linkedAccountEmail = res;
                                     });
                                } else {
                                    this.personalInfo.linkedAccountEmail = res.linkedAccountEmail;
                                }
                                this.personalInfo.passId = res.membershipId;
                                // show previous linked popup
                                this.openLinked();
                            }

                            const dob = new GoesDate(null, null, null, res.dateOfBirth);
                            const mapUser = {
                                userId: res.userId,
                                username: res.userId,
                                membershipOrPassId: res.membershipId,
                                name: res.firstName + ' ' + res.lastName,
                                firstName: res.firstName,
                                lastName: res.lastName,
                                dateOfBirth: dob != null ? dob.getSimpleString() : this.personalInfo.dateOfBirth.getSimpleString(),
                                email: res.emailAddress,
                                loginGovEmail : this.user.loginGovEmail,
                                age: res.age

                            };
                            this.authService.setUser(mapUser);
                        }
                    });
                }
              }
            } else {
                this.modalService.alert('ERROR_MESSAGES.GENERAL.ERRORS_ON_PAGE');
                return false;
            }
    }

    private openLinked(): void {
        $('#questionModal').modal('hide');
        $('#docInfoModal').modal('hide');
        // $('#linkedModal').modal({
        //     backdrop : 'static',
        //     keyboard : false,
        //     show : true
        // });

        $('#linkedModal').modal('show');
    }

    confirmLink(): void {
        $('#linkedModal').modal('hide');
        // check if GOESID / answered correctly, then relink
        if (this.personalInfo.goesId && this.personalInfo.answerSuccess) {
            this.transferMembership(this.personalInfo).subscribe(res => {
                this.openSuccessModal();
            });

        }
        // else hit endpoint for docuemnt list
        else {
            this.fetchDocuments(this.personalInfo); // TODO
            // after fetchDocuments, open document info popup
        }
    }

    fetchDocuments(user: SignUpPersonal): void {
        // fetchDocuments, populate object, show modal.
        this.signUpService.fetchDocList(user).subscribe(res => {
            if (res) {
                this.docSubmitted = false;
                this.personalInfo.documentInfo.countryOfIssuance = '';
                this.personalInfo.documentInfo.docNumber = '';
                this.personalInfo.documentInfo.docType = '';
                this.docTypes = res.docTypes;
                // $('#docInfoModal').modal({
                //     backdrop: 'static',
                //     keyboard: false,
                //     show : true
                // });
                $('#docInfoModal').modal('show');
            }
        });
    }

    transferMembership(user: SignUpPersonal): Observable<any> {
        return this.signUpService.transferMembership(this.personalInfo).pipe(map(res => {
            user.answerSuccess = res.answerSuccess;
            if (res.membershipId) {
                const dob = new GoesDate(null, null, null, res.dateOfBirth);
                const mapUser = {
                    userId: res.userId,
                    username: res.userId,
                    membershipOrPassId: res.membershipId,
                    name: res.firstName + ' ' + res.lastName,
                    firstName: res.firstName,
                    lastName: res.lastName,
                    dateOfBirth: dob != null ? dob.getSimpleString() : user.dateOfBirth.getSimpleString(),
                    email: res.emailAddress,
                    loginGovEmail : this.user.loginGovEmail,
                    age: res.age
                };
                this.authService.setUser(mapUser);
            }
            return res;
        }));
    }

    // when the user answers a security question, call service, update user object, if correct,
    // go to dashboard, if incorrect, show message, if incorrect x3, show message, go to dashboard
    validateDocumentInfo(user: SignUpPersonal): any {
        this.docSubmitted = true;
        if (user && user.documentInfo.docType && user.documentInfo.docNumber && user.documentInfo.countryOfIssuance) {
            this.spinner.show();
            this.signUpService.validateDocInfo(user).subscribe(res => {
                this.spinner.hide();
                if (res) {
                    user.answerSuccess = res.answerSuccess;
                    user.maxDocAttempt = res.maxDocAttempt;
                    if (res.answerSuccess === true){
                        if (res.membershipId){
                            const dob = new GoesDate(null, null, null, res.dateOfBirth);
                            const mapUser = {
                                userId: res.userId,
                                username: res.userId,
                                membershipOrPassId: res.membershipId,
                                name: res.firstName + ' ' + res.lastName,
                                firstName: res.firstName,
                                lastName: res.lastName,
                                dateOfBirth: dob != null ? dob.getSimpleString() : user.dateOfBirth.getSimpleString(),
                                email: res.emailAddress,
                                loginGovEmail : this.user.loginGovEmail,
                                age: res.age
                            };
                            this.personalInfo.passId = res.membershipId;
                            this.authService.setUser(mapUser);
                        }

                        if (res.linkedAccountEmail) {
                            this.personalInfo.passId = res.membershipId;
                            if ( res.linkedAccountEmail === 'No Valid login.gov email was bound to this account') {
                                this.translate.get('SIGN_UP.NO_EMAIL_FOUND').subscribe(res => {
                                    this.personalInfo.linkedAccountEmail = res;
                                    console.log('personalInfo', res);
                              });
                            } else {
                                this.personalInfo.linkedAccountEmail = res.linkedAccountEmail;
                            }
                            this.openLinked();
                        } else {
                          // popup about continuing
                          this.openSuccessModal();
                        }
                    }
                    else if (res.answerSuccess === false && res.maxDocAttempt === 3){
                        this.openVerificationOptionModal();
                    }
                }
            });
        }
    }

    // checks user selection, opens appropriate dialogue
    chooseVerification(optionString: string): any {
        if (optionString){
            if (optionString === 'SECURITY'){
                $('#verificationOptionModal').modal('hide');
                this.initQuestionList();
            }
            else if (optionString === 'DOC'){
                $('#verificationOptionModal').modal('hide');
                this.fetchDocuments(this.personalInfo);
            }
        } else {
            this.modalService.alert('ERROR_MESSAGES.GENERAL.REQUIRED_REVIEW');
        }
    }

    openSuccessModal(): void {
        $('#questionModal').modal('hide');
        $('#docInfoModal').modal('hide');
        // $('#successModal').modal({
        //     backdrop : 'static',
        //     keyboard : false,
        //     show : true
        // });        
        $('#successModal').modal('show');
    }

    openVerificationOptionModal(): void {
        this.personalInfo.answerSuccess = true;
        $('#questionModal').modal('hide');
        $('#docInfoModal').modal('hide');
        this.verificationToggle = '';
        // $('#verificationOptionModal').modal({
        //     backdrop : 'static',
        //     keyboard : false,
        //     show : true
        // });
        $('#verificationOptionModal').modal('show');
    }


    cancelRelinking(): void {
        this.personalInfo.passId = '';
        $('#linkedModal').modal('hide');
        $('#questionModal').modal('hide');
        $('#docInfoModal').modal('hide');
        // $('#noChangeModal').modal({
        //     backdrop : 'static',
        //     keyboard : false,
        //     show : true
        // });
        $('#noChangeModal').modal('show');
    }

    private initQuestionList(): void {
        this.secSubmitted = false;
        this.selectedQuestion = '';
        this.selectedAnswer = '';

        // $('#questionModal').modal({
        //  backdrop: 'static',
        //  keyboard: false,
        //  show : true
        // });

        $('#questionModal').modal('show');
    }

    wrapperSubmit(): boolean {

        return this.submitForm();
    }

    provideDocFailureHelp() {
        $('#doc-failure-Instr').modal('show');
    }

}
