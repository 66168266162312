import { GoesDate } from '../common/models/date.model';
import { Component, OnInit} from '@angular/core';
import { PaymentHistoryService } from '../common/services/payment-history.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import Utils from '../common/utils/utils';


@Component({
    templateUrl: 'payment-history.component.html',
    standalone: false
})

export class PaymentHistoryComponent implements OnInit {


    paymentInfo : any;
    paymentDate : GoesDate;
    dob : GoesDate;

    constructor(private paymentHistoryService : PaymentHistoryService,
                private router : Router,
                private route : ActivatedRoute){}

    ngOnInit() {
       this.route.params.subscribe((params : Params) => {
            let paymentId = params['id'];
            if (paymentId) {
                this.paymentHistoryService.pullSinglePayment(paymentId).subscribe( res=> {
                    if (res) {
                        this.paymentInfo = res;
                        if(this.paymentInfo.paymentDate){
                            this.paymentDate = new GoesDate(null, null, null, this.paymentInfo.paymentDate);
                        }
                        if(this.paymentInfo.dateOfBirth){
                            this.dob = new GoesDate(null,null, null, this.paymentInfo.dateOfBirth);
                        }
                    }
                    else {
                        this.router.navigate(['/dashboard']);
                    }
                });
            }
            else {
                this.router.navigate(['/dashboard']);
            }
        });

    }

    printPage(): void {
        window.print();
    }

    public openPrintView() {
        Utils.openPrintView();
    }
}
