import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { AuthService } from '../common/services/auth.service';
declare var $:any;

@Component({
    selector: 'getstarted',
    templateUrl: 'getstarted.component.html',
    standalone: false
})

export class GetStartedComponent implements OnInit {

	stepDone:number;

	constructor(
		private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute) {}

	ngOnInit() {
		this.stepDone = this.route.snapshot.params['stepDone'];
		if(!this.stepDone) this.stepDone = 0;
	}

	login() {
		// if(!this.authService.isUserLoggedIn()) {
	 //      $('#security-warn').modal('show');
	 //    } else {
	    	if(this.stepDone > 0) {
	    		this.router.navigate(['/program-selection/select-program']);
	    	} else {
          this.authService.logIn().subscribe(res=>{
            if (res) {
              let user = this.authService.getUser();
              if (user.name) {
                this.router.navigate(['/dashboard']);
              }
              else {
                this.router.navigate(['/account-profile/' + user.userId]);
              }
            }
          });
	    	}
	    //}
	}

	learnMoreLogin() : void {
		$('#login-modal').modal('show');
	}
	learnMoreAlready() : void {
		$('#mdl-about-goesId').modal('show');
	}
}
