import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScheduleService } from '../../common/services/schedule.service';
import { AuthService } from '../../common/services/auth.service';
import { ApplicationService } from '../../common/services/application.service';
import { ApplicationMetadata } from '../../common/models/application-metadata.model';
import { AppointmentModel } from '../../common/models/appointments/appointment.model';
import { Location } from '../../common/models/appointments/location.model';
import { User } from '../../common/models/user';
import { TranslateReferenceService } from '../../common/services/translate-reference.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { Document } from '../../common/models/document.model';
import { Application } from '../../common/models/application.model';
import { DriversLicense } from '../../common/models/drivers-license.model';
import Utils from '../../common/utils/utils';
import { CalendarService } from '../../shared/services/calendar.service';
import * as moment from 'moment-timezone';

declare var $: any;

@Component({
    templateUrl: 'interview-summary.component.html',
    standalone: false
})
export class InterviewSummaryComponent implements OnInit {

    public appointmentModel: AppointmentModel = null;
    public appointmentLocationModel: Location = null;
    public appointmentLocationImage = '';
    public applicationMetadata: ApplicationMetadata;
    public user: User = new User();
    public documentsToBring: Array<Document> = new Array<Document>();
    public programName = '';
    protected programId = '';
    public month: string;
    public dayOfWeek: string;
    public moment: any;

    private appointmentDate: Date;
    public driversLicense: DriversLicense = null;

    constructor(private router: Router,
                private scheduleService: ScheduleService,
                private referenceService: TranslateReferenceService,
                private authService: AuthService,
                private applicationService: ApplicationService,
                private spinner: SpinnerService,
                private calService: CalendarService) {
                    this.moment = moment;
                }

    ngOnInit() {
        // this.setTestData();
        if (this.scheduleService.focusedAppointmentApp !== null) {
            this.setup();
        } else {
            // appointment data has not been gathered so redirect
            this.router.navigate(['/']);
        }

    }

    private setup(){
        this.spinner.show();
        this.applicationMetadata = this.scheduleService.focusedAppointmentApp;
        this.programId = this.applicationMetadata.programs.length > 0 ? this.applicationMetadata.programs[0] : '';
        this.user = this.authService.getUser();
        this.scheduleService.getAppointment(
            this.scheduleService.focusedAppointmentApp.applicationId,
            this.scheduleService.focusedAppointmentApp.source)
            .subscribe(
                (res: any) => {
                    if (this.scheduleService.appointmentModel !== null) {
                        this.spinner.hide();
                        this.appointmentModel = new AppointmentModel(res);

                        this.dayOfWeek = this.calService.getDayDisplayName(this.appointmentModel.startDateObject);
                        this.appointmentDate = this.appointmentModel.startDateObject;

                        this.scheduleService.clearNotification();
                        this.getLocationDetails();
                        this.getLocationMap();

                        this.month = 'GENERAL_REUSABLE.MONTH.';
                        switch (this.appointmentDate.getMonth() + 1) {
                            case 1:
                                this.month += 'JAN';
                                break;
                            case 2:
                                this.month += 'FEB';
                                break;
                            case 3:
                                this.month += 'MAR';
                                break;
                            case 4:
                                this.month += 'APR';
                                break;
                            case 5:
                                this.month += 'MAY';
                                break;
                            case 6:
                                this.month += 'JUN';
                                break;
                            case 7:
                                this.month += 'JUL';
                                break;
                            case 8:
                                this.month += 'AUG';
                                break;
                            case 9:
                                this.month += 'SEP';
                                break;
                            case 10:
                                this.month += 'OCT';
                                break;
                            case 11:
                                this.month += 'NOV';
                                break;
                            case 12:
                                this.month += 'DEC';
                                break;
                        }

                    } else {
                        this.spinner.hide();
                        this.onDone(null);
                    }
                }, error => {
                    // console.log('error ', error);
                    this.spinner.hide();
                }
            );

        this.setupReferenceData();
    }

    private getLocationDetails(){
        this.scheduleService.getLocationDetails(this.appointmentModel.locationId)
            .subscribe(
                (res: any) => {
                    this.appointmentLocationModel = new Location(res);
                }, error => {
                    // console.log('error ', error);
                }
            );
    }

    private getLocationMap(){
        this.scheduleService.getLocationMap(this.appointmentModel.locationId)
            .subscribe(
                (res: any) => {
                    this.appointmentLocationImage = res;
                    $('#imageMap').attr('src', this.appointmentLocationImage);
                }, error => {
                    this.appointmentLocationImage = ' ';
                    // console.log('error ', error);
                }
            );
    }

    protected setupReferenceData(){

        this.programName = this.referenceService.getProgramName(this.programId);

        this.applicationService.getGESApplication(this.user.userId, Number(this.applicationMetadata.applicationId),
                                                  this.applicationMetadata.source).subscribe(res => {
            const application = new Application(res);
            // const application = res;
            if (application && application.citizenshipDocuments.length > 0) {
                application.citizenshipDocuments.forEach(val => {
                    val.documents.forEach(doc => {
                        doc.countryOfIssuance = this.referenceService.getCountryByIsoCode(doc.countryOfIssuance.isoCountryCode);
                        this.documentsToBring.push(doc);
                    });
                });
            }
            if (application && application.driversLicense.haveLicense === 'Y') {
                this.driversLicense = application.driversLicense;
            }

        });


    }

    public goToReschedule(event: any) {

        if (this.scheduleService.focusedAppointmentApp.canDoRemoteInterview) {
            this.router.navigate(['dashboard/select-interview-type'],
            { queryParams: { appId: this.scheduleService.focusedAppointmentApp.applicationId,
                scheduleType: AppointmentModel.TYPE_UPDATE } });
        } else {
            const url: string = this.scheduleService.returnRescheduleUri(
                this.scheduleService.focusedAppointmentApp, this.authService.getUser());

            this.authService.refreshTokenAndGotoExternalUrl(url);
        }
    }

    public goToSchedule(event: any) {
        const url: string = this.scheduleService.returnNewAppointmentUri(
            this.scheduleService.focusedAppointmentApp, this.user);

        this.authService.refreshTokenAndGotoExternalUrl(url);
    }

    public goToCancel(event: any){
        this.router.navigate(['/cancel-interview']);
    }

    public onDone(event: any){
        this.router.navigate(['/dashboard']);
    }

    public openPrintView(event: any){
        // window.print();
        Utils.openPrintView();
    }
}
