<header role="banner">
  <go-toolbar></go-toolbar>
</header>
<go-navbar></go-navbar>
<announcements></announcements>
<help-floater></help-floater>
<spinner></spinner>
<div id="main-content" class="overflow-hidden">
	<router-outlet></router-outlet>
</div>
<alert-modal></alert-modal>
<confirm-modal></confirm-modal>
<notify-modal></notify-modal>
<print-modal></print-modal>
<go-footer></go-footer>


