import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../environments/environment';
import { SETTINGS } from '../app.constants';

/**
 * This class represents the lazy loaded HomeComponent.
 */
@Component({
    selector: 'enrollment-overview',
    templateUrl: 'enrollment-overview.component.html',
    standalone: false
})

export class EnrollmentOverviewComponent implements OnInit {

	@Input('step-done')
	stepDone: number;
    schedulerViewLocationsUrl : string;
	ngOnInit() {
        this.schedulerViewLocationsUrl = environment.uriScheduler + "schedule-interview/location?vo=true&returnUrl=" + SETTINGS.baseReturnService + "&service=";
    }
}
