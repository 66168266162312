import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home.component';
import { GetStartedComponent } from './getstarted.component';
import { EnrollmentOverviewComponent } from './enrollment-overview.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckInterviewAvailModalComponent } from './check-int-avail-modal/check-int-avail-modal.component';
import { ComparisonToolComponent } from './comparison-tool/comparison-tool.component';
import { ComparisonResultsComponent } from './comparison-tool/comparison-results.component';

@NgModule({
  imports: [CommonModule, SharedModule, TranslateModule],
  declarations: [
    HomeComponent,
    GetStartedComponent,
    EnrollmentOverviewComponent,
    ComparisonToolComponent,
    ComparisonResultsComponent,
    CheckInterviewAvailModalComponent
  ],
  exports: [
    HomeComponent,
    GetStartedComponent,
    EnrollmentOverviewComponent,
    ComparisonToolComponent,
    ComparisonResultsComponent],
  providers: []
})

export class HomeModule { }
