<div class="row">
	<div class="col-lg-12 col-lg-12 progress-top-level centered">
        <h1 translate>PRIVACY_ACT.HEADER</h1>
        <p class="divider d-none d-md-block"></p>
    </div>
</div>

<div class="page-content">
    <div class="container">
        <div class="content-panel privacy-act">
            <div class="row">
                <div class="col-12 row-centered">
                    <h4 translate>PRIVACY_ACT.SUB_HEADER</h4>                    
                </div>
                <div class="col-12">
                    <p translate>PRIVACY_ACT.SUB_HEADER_NOTE</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h4>{{'PRIVACY_ACT.AUTHORITY.HEADER' | translate | uppercase}}:</h4>
                    <p translate>PRIVACY_ACT.AUTHORITY.STATEMENT</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h4>{{'PRIVACY_ACT.PURPOSE.HEADER' | translate | uppercase}}:</h4>
                    <p translate>PRIVACY_ACT.PURPOSE.STATEMENT</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h4>{{'PRIVACY_ACT.ROUTINE_USES.HEADER' | translate | uppercase}}:</h4>
                    <p innerHTML="{{'PRIVACY_ACT.ROUTINE_USES.STATEMENT' | translate}}"></p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h4>{{'PRIVACY_ACT.CONSEQUENCES.HEADER' | translate | uppercase}}:</h4>
                    <p innerHTML="{{'PRIVACY_ACT.CONSEQUENCES.STATEMENT' | translate}}"></p>
                </div>
            </div>

            <div class="row"  class="privacy-act-close">
                <div class="col-12 col-md-3 ms-auto">
                    <a id="privacyActCloseBtn" class="btn btn-nav btn-primary btn-nav btn-nowrap d-block w-100" 
                        (click)="closePrivacyAct()" (keypress.enter) ="closePrivacyAct()" 
                        tabindex="0" name="close" translate>MODAL.BUTTON_CLOSE</a>
                </div>
            </div>
        </div>

    </div>
</div>