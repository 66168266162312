import { EnhancedModel } from './enhanced.model';

export class ReconsiderationAttachment extends EnhancedModel {
    id : string = '';
    fileName: string = '';
    fileSize : string = '';
    fileObjectKey : string = '';
    reconsiderationIdStr : string = '';
    fileUploadDateStr:string='';
    contentType: string = '';

    constructor(obj:any) {
        super();
        if (obj instanceof ReconsiderationAttachment) {
            this.mapClone(obj);
        }
        else if(obj instanceof Object) {
            // this must be a json object let's deserialize it
            this.mapJson(obj);
        }
        else {
            throw new Error("You must specify the correct parameter");
        }
    }

    public clone() {
        let cloned:ReconsiderationAttachment = new ReconsiderationAttachment(this);
        return cloned;
    }

    protected mapClone(recon:ReconsiderationAttachment) {
        this.id = recon.id;
        this.fileName = recon.fileName;
        this.fileSize = recon.fileSize;
        this.fileObjectKey = recon.fileObjectKey;
        this.reconsiderationIdStr = recon.reconsiderationIdStr;
        this.fileUploadDateStr = recon.fileUploadDateStr;
        this.contentType = recon.contentType;
    }

    protected mapJson(jsonObject: any) {
        if (jsonObject.hasOwnProperty('id')) {
            this.id = jsonObject.id;
        }
         if (jsonObject.hasOwnProperty('fileName')) {
            this.fileName = jsonObject.fileName;
        }
        if (jsonObject.hasOwnProperty('fileSize')) {
            this.fileSize = jsonObject.fileSize;
        }
        if (jsonObject.hasOwnProperty('fileObjectKey')) {
            this.fileObjectKey = jsonObject.fileObjectKey;
        }
        if (jsonObject.hasOwnProperty('reconsiderationIdStr')) {
            this.reconsiderationIdStr = jsonObject.reconsiderationIdStr;
        }
        if (jsonObject.hasOwnProperty('fileUploadDateStr')) {
            this.fileUploadDateStr = jsonObject.fileUploadDateStr;
        }
        if (jsonObject.hasOwnProperty('contentType')) {
            this.contentType = jsonObject.contentType;
        }
    }

}
