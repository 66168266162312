<div class="row">
	<div class="col-12 progress-top-level centered">
        <h1 translate>PROGRESS.FAQ</h1>
        <p class="divider d-none d-md-block"></p>
    </div>
</div>

<div class="page-content d-flex">
    <div class="container">
        <div class="content-panel">
            <div class="row">
                <div class="col-12">
                    <h1 class="float-start" translate>FAQ.TOPICS</h1>
                    <div class="float-end faq-accordion-controls d-none d-md-block">
                        <div class="controls">
                            <ul class="nav nav-pills">
                                <li role="presentation" class="nav-item">
                                    <a tabindex="0" class="nav-link" (click)="expandAll(); expColToggle=1;" (keyup.enter)="expandAll()" [class.active]="expColToggle === 1"><span translate>APPLICATION.REVIEW.EXPAND_ALL</span></a>
                                </li>
                                <li role="presentation" class="nav-item">
                                    <a  tabindex="0"  class="nav-link" (click)="collapseAll(); expColToggle=2;" (keyup.enter)="collapseAll()" [class.active]="expColToggle === 2"><span translate>APPLICATION.REVIEW.COLLAPSE_ALL</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 faq-sub-header">
                    <h3 class="" [innerHTML]="'DASHBOARD.TSA_PRECHECK' | translate"></h3>
                    <div>
                        <span>
                            <a class="faq-anchor" target="_blank" rel="noopener noreferrer" href="https://www.tsa.gov/travel/frequently-asked-questions" [innerHTML]="'FAQ.TSA_PRECHECK_FAQ' | translate"></a>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="faq-sub-header">
                        <h3 class="" [innerHTML]="'FAQ.TTP_PROGRAMS' | translate"></h3>
                    </div>
                    <div class="faq-columns">
                        <p *ngFor="let faq of faqList; let i = index">
                            <span>
                                <a class="faq-anchor" (click)="scrollTo('topic_'+i)" tabindex="0" (keyup.enter)="scrollTo('topic_'+i)" [innerHTML]="faq.topic | translate"></a>
                            </span>
                        </p>
                    </div>
                </div>
            </div>

            <div class="faq-content" *ngFor="let faq of faqList; let i = index">
                <!--FAQ Topic Title -->
                <h3 id="topic_{{i}}">{{faq.topic}}</h3>

                <!--FAQ content accordions-->
                <div class="faq-accordion" *ngFor="let content of faq.content; let j = index">
                    <!--FAQ title-->
                    <div class="accordion-title" tabindex="-1">
                        <div class="row">
                            <div class="col-12">
                                <a tabindex="0" href="#section_{{i}}{{j}}" aria-expanded="true" data-bs-toggle="collapse">
                                    <span id="collapse-arrow" class="fa fa-chevron-right float-start"></span>
                                    <span class="faq-title" [innerHTML]="content.title | translate"></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div id="section_{{i}}{{j}}" class="collapse accordion-body">
                        <div class="row">
                            <div class="col-12">
                                <p [innerHTML]="content.body"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row"  style="padding-bottom: 40px;">
            <div class="col-12 col-lg-3 ms-auto">
                <a id="close" class="btn btn-nav btn-primary btn-nav btn-nowrap d-block w-100" (click)="closeFAQ()" (keypress.enter) ="closeFAQ()" tabindex="0" name="close" translate>FAQ.CLOSE_FAQ</a>
            </div>
        </div>
    </div>
</div>
