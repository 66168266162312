import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LegalGuardian } from "src/app/common/models/legal-guardian.model";
import { AuthService } from "src/app/common/services/auth.service";
import { LegalGuardianService } from "src/app/common/services/legal-guardian.service";
import { ModalService } from "src/app/shared/modals/modal.service";
import { SpinnerService } from "src/app/shared/spinner";
import { SETTINGS } from "../app.constants";
import Utils from "../common/utils/utils";
import { NgForm } from "@angular/forms";
import { TemplateValidationService } from "../common/services/template-validation.service";
import { StepStatus } from "../common/utils/enums";
import { GoesDate } from "../common/models/date.model";

declare var $ :any;
@Component({
    templateUrl: 'legal-guardian-info.component.html',
    standalone: false
})

export class LegalGuardianComponent implements OnInit {

    @ViewChild('legalGuardianForm', {static: true}) currentForm : NgForm;
    
    minDate: string = '1901-01-01';
    maxDate: string;
    alphabeticPattern: RegExp = SETTINGS.alphabeticPattern;
	textPattern : RegExp = SETTINGS.textInputPattern;
    textAlphaPattern : RegExp = SETTINGS.oneAlphaInputPattern;
    numberPattern : RegExp = SETTINGS.numberPattern;
	alphanumericPattern: RegExp = SETTINGS.alphabeticPattern;	
    dateErrors: boolean;
    months: any = [];
    gBirthDate: GoesDate;

    public dateFormErrors = {
        guardianDateOfBirth : new Array<string>(),
    };

    formErrors = {
		'g_lastname': new Array<string>(),
		'g_firstname': new Array<string>(),
		'g_middlename': new Array<string>(),
		'g_gender': new Array<string>(),
		'g_dateOfBirth': new Array<string>(),
		'g_passId': new Array<string>(),
		'g_applicationId': new Array<string>()
	};

    validationMessages = {
        'g_lastname': {
			'required': 'ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_LAST_NAME_REQUIRED',
            'pattern': 'ERROR_MESSAGES.GENERAL.NAME_PATTERN'
		},
        'g_firstname': {
			'required': 'ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_FIRST_NAME_REQUIRED',
			'pattern': 'ERROR_MESSAGES.GENERAL.FIRST_NAME_PATTERN'
		},
        'g_middlename': {
            'pattern': 'ERROR_MESSAGES.GENERAL.NAME_PATTERN'
		},
        'g_gender': {
			'required': 'ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_GENDER_REQUIRED'
		},
		'aliasFirstName': {
			'required': 'ERROR_MESSAGES.PERSONAL_INFO.ALIAS_FIRSTNAME_REQUIRED',
			'pattern': 'Pattern failed'
		},
		'g_passId': {
			'required': 'ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_PASSID_REQUIRED',
			'stateEligibility' : 'ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_PASSID_REQUIRED',
            'minlength': 'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.MEMBERSHIP_PASSID_MIN'
		},
		'g_applicationId': {
			'required': 'ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_APPLICATIONID_REQUIRED',
			'stateEligibility' : 'ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_APPLICATIONID_REQUIRED',
		}
    }

    public legalGuardian: LegalGuardian;

    private applicationId: number;

    constructor(private authService: AuthService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private legalGuardianService: LegalGuardianService,
        private modalService: ModalService,
        private spinner: SpinnerService,
        private validationService : TemplateValidationService,) {
    }

    ngOnInit(): void {
        this.spinner.show();

        this.legalGuardian = new LegalGuardian();

        this.getMaxDate();
        let user = this.authService.getUser();
        this.translate.get('GENERAL_REUSABLE.MONTHS')
            .subscribe(
                res => {
                    if (res instanceof Array){
                        this.months = res;
                    } else {
                        this.months = new Array<any>();
                    }
                 });
        this.route.queryParams.subscribe(params => {

            this.applicationId = params['appId'] || null;

            this.legalGuardianService.getLegalGuardianInfo(user.userId, this.applicationId).subscribe((res: LegalGuardian) => {
                this.legalGuardian = res;
                this.initLegalGuardianAccountLookupType();
            });

            this.spinner.hide();
        });
    }

    public cancel(): void {
        this.router.navigate(['dashboard']);
    }

    public clearGuardianAccountLookupType() {
		this.legalGuardian.passId = null;
		this.legalGuardian.applicationId = null;
	} 

    public submitForm() {

        this.validateGuardianDateOfBirth();

        let status = this.validationService.validateForm2(
			this.currentForm, this.formErrors, this.validationMessages, true);

        if(status === StepStatus.Valid 
            && this.dateFormErrors.guardianDateOfBirth.length > 0 
                && (this.dateFormErrors.guardianDateOfBirth[0] === 'dateError' 
                    || this.dateFormErrors.guardianDateOfBirth[0] === 'minError' 
                    || this.dateFormErrors.guardianDateOfBirth[0] === 'maxError')) {
                        status = this.validateGuardianDateOfBirth();
        } else {
            this.validateGuardianDateOfBirth();
        }

        if (status === StepStatus.Valid) {

            this.validateLeglGuardianAccountInfo().then(res => {
                if (res == StepStatus.Valid) {
                    this.spinner.show();
                    // update the mailing address
                    let user = this.authService.getUser();

                    this.legalGuardian.dateOfBirth = this.legalGuardian.dob.getSimpleString();

                    this.legalGuardianService.updateLegalGuardianInfo(user.userId, this.legalGuardian).subscribe(res => {
                        
                        if (res) {
                            // Forward to purchase summary page to initiate a conversion
                            const queryParams = {
                                appId: this.applicationId,
                                payAction: 'C'
                            };
                            this.router.navigate(['purchase-summary'], { queryParams }).then(res => Utils.scrollUp()); 
                        }
                        this.spinner.hide();
                    });
                }
            });
        } else {
            this.modalService.alert('ERROR_MESSAGES.GENERAL.ERRORS_ON_PAGE');
        }
    }

    public validateGuardianDateOfBirth(): any {
        let status = StepStatus.Invalid;
		let dobMonthObj = <HTMLSelectElement>document.getElementById('g_dateOfBirth_month');
		let dobDayObj = <HTMLInputElement>document.getElementById('g_dateOfBirth_day');
		let dobYearObj = <HTMLInputElement>document.getElementById('g_dateOfBirth_year');
		let month = dobMonthObj?dobMonthObj.value:'';
		let day = dobDayObj?dobDayObj.value:'';
		let year = dobYearObj?dobYearObj.value:'';
        let monthStr = month?month.split(':')[1].trim():'';
		let date = new GoesDate(monthStr, day, year);
		let dateStr = date.getSimpleString();
        this.dateFormErrors.guardianDateOfBirth = new Array<string>();
		if(!month || !day || !year) {
			this.dateFormErrors.guardianDateOfBirth.push('requiredFieldError');
		} else {
            if(!Utils.isValidDateInput(dateStr)) {
                this.dateFormErrors.guardianDateOfBirth.push('dateError');
            }
            if(!Utils.isDateBefore(dateStr, this.maxDate)) {
                this.dateFormErrors.guardianDateOfBirth.push('maxError');
            } else if(!Utils.isDateAfter(dateStr, this.minDate)) {
                this.dateFormErrors.guardianDateOfBirth.push('minError');
            } else {
               this.legalGuardian.dob = date;
               status = StepStatus.Valid;
            }
		}
        return status;
    }

    private getMaxDate() : void {
        let now = new Date();
        let date18Years = new Date(now.setFullYear(now.getFullYear() - 18));
        let month = (date18Years.getMonth() + 1);
        let day = (date18Years.getDate());
        //if compared date is Feb 29th, and not leap year, push to march 1st
        if (!Utils.isLeapYear(date18Years.getUTCFullYear()) && month === 2 && day === 29){
                month = 3;
                day = 1;
        }
		let monthStr = (month < 10 ? '0' : '') + month;
        let dayStr = (day < 10 ? '0' : '') + day;
        this.maxDate = date18Years.getUTCFullYear() + '-' + monthStr + '-' + dayStr;
    }

    private initLegalGuardianAccountLookupType() {
		if (!this.legalGuardian.idType) {
			if (this.legalGuardian.passId) {
				this.legalGuardian.idType = 'P';
			} else {
				this.legalGuardian.idType = 'A';
			}
		}
	}

    private isAccountInfoValid(): boolean {
        if (this.legalGuardian.idType == 'P' && !this.legalGuardian.passId) {
            return false;
        } else if (this.legalGuardian.idType === 'A' && !this.legalGuardian.applicationId) {
            return false;
        }
		return true;
	}

    private validateLeglGuardianAccountInfo(): Promise<number> {
		if (!this.isAccountInfoValid()) {
			// Hack since validateForm2 will only add validation errors in certain scenarios. 
			// The stateEligibility key is one
			if (this.legalGuardian.idType == 'P') {
		 		this.currentForm.controls['g_passId'].setErrors({'stateEligibility': true});

				// If the form is not dirty validation service call does not trigger
				this.formErrors.g_passId = new Array<string>();
				this.formErrors.g_passId.push('ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_PASSID_REQUIRED');
		 	} else if  (this.legalGuardian.idType == 'A') {
				this.currentForm.controls['g_applicationId'].setErrors({'stateEligibility': true});

				// If the form is not dirty validation service call does not trigger
				this.formErrors.g_applicationId = new Array<string>();
				this.formErrors.g_applicationId.push('ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_APPLICATIONID_REQUIRED');
			}

			return Promise.resolve(StepStatus.Invalid);
            
		} else if (this.legalGuardian.idType == 'N') {
			return this.modalService.confirm('APPLICATION.PERSONAL_INFO.GUARDIAN_NOACCOUNT_WARNING')
            .then(res =>  {
                if (res) { 
					return Promise.resolve(StepStatus.Valid);
				} else {
					return Promise.resolve(StepStatus.Stay);
				}
            });
		} else {
			return Promise.resolve(StepStatus.Valid);
		}
	}
};