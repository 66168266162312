<div class="" id="comparison-tool-container">

    <div class="tt-widget">
      <p class="text-start tt-widget-not-sure" translate>HOME.COMPARISON_TOOL.WIDGET_TITLE_TEXT</p>
      <div id="comparison-tool"  class="comparison-tool myFormWrap">
          <div class="row">
            <div class="col-12 col-md-4">
                <fieldset class="comparison-tool__col1">
                  <legend translate>HOME.COMPARISON_TOOL.WIDGET_TITLE_CITIZENSHIP</legend>
                  <label for="select-citizenship" class="comparison-tool__label" translate>HOME.COMPARISON_TOOL.WHAT_COUNTRY_TEXT</label>
                  <select id="select-citizenship" class="form-select" [(ngModel)]="citizenship"  (change)="checkCommercialTruckerAllowed(); updateShowSixMonthCheck();">
                    <option value="">--</option>
                    <option value="US" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.US_CITIZEN</option>
                    <option value="USLPR" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.US_RESIDENT</option>
                    <option value="AR" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.AR_CITIZEN</option>
                    <option value="AU" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.AU_CITIZEN</option>
                    <option value="BH" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.BH_CITIZEN</option>
                    <option value="BR" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.BR_CITIZEN</option>
                    <option value="CA" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.CA_CITIZEN</option>
                    <option value="CALPR" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.CA_RESIDENT</option>
                    <option value="CO" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.CO_CITIZEN</option>
                    <option value="HR" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.HR_CITIZEN</option>
                    <option value="DO" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.DO_CITIZEN</option>
                    <option value="DE" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.DE_CITIZEN</option>
                    <option value="IN" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.IN_CITIZEN</option>
                    <option value="IS" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.IS_CITIZEN</option>
                    <option value="JA" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.JA_CITIZEN</option>
                    <option value="KR" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.KR_CITIZEN</option>
                    <option value="MX" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.MX_CITIZEN</option>
                    <option value="NL" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.NL_CITIZEN</option>
                    <option value="PA" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.PA_CITIZEN</option>
                    <option value="QA" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.QA_CITIZEN</option>                    
                    <option value="SA" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.SA_CITIZEN</option>
                    <option value="SG" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.SG_CITIZEN</option>
                    <option value="CH" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.CH_CITIZEN</option>
                    <option value="TW" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.TW_CITIZEN</option>
                    <option value="AE" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.AE_CITIZEN</option>                    
                    <option value="UK" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.UK_CITIZEN</option>
                    <option value="OTHER" translate>HOME.COMPARISON_TOOL.CIT_COUNTRIES.OTHER_CITIZEN</option>
                  </select>
                </fieldset>
            </div>

              <div class="col-12 col-md-4">
                <fieldset class="comparison-tool__col2">
                  <legend translate>HOME.COMPARISON_TOOL.AVERAGE_FLIGHT_YEAR</legend>
                  <p class="visually-hidden" id="describe-travel-habits" [innerHTML]="'HOME.COMPARISON_TOOL.DESCRIBE_TRAVEL_HABITS' | translate"></p>
                  <div class="row">
                      <div class="col-6">
                        <div>
                          <label for="international-travel" class="comparison-tool__label" translate>HOME.COMPARISON_TOOL.INTERNATIONA_AIR_TEXT</label>
                        </div>
                        <div>
                          <select name="" id="international-travel" class="form-select" aria-describedby="describe-travel-habits" [(ngModel)]="internationalTravelCount" (change)="updateShowSixMonthCheck();">
                            <option [ngValue]="0">0 {{'HOME.COMPARISON_TOOL.TRIPS' | translate}}</option>
                            <option [ngValue]="1">1-3 {{'HOME.COMPARISON_TOOL.TRIPS' | translate}}</option>
                            <option [ngValue]="2">4+ {{'HOME.COMPARISON_TOOL.TRIPS' | translate}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div>
                          <label for="domestic-travel" class="comparison-tool__label" translate>HOME.COMPARISON_TOOL.DOMESTIC_AIR_TEXT</label>
                        </div>
                        <div>
                          <select name="" id="domestic-travel" class="form-select" aria-describedby="describe-travel-habits" [(ngModel)]="domesticTravelCount">
                            <option [ngValue]="0">0 {{'HOME.COMPARISON_TOOL.TRIPS' | translate}}</option>
                            <option [ngValue]="1">1 {{'HOME.COMPARISON_TOOL.TRIP' | translate}}</option>
                            <option [ngValue]="2">2+ {{'HOME.COMPARISON_TOOL.TRIPS' | translate}}</option>
                          </select>
                        </div>
                      </div>
                  </div>
                </fieldset>
              </div>

              <div class="col-12 col-md-4">
                <fieldset class="comparison-tool__col2">
                  <legend translate>HOME.COMPARISON_TOOL.OTHER_CONSIDERATION_TEXT</legend>
                  <div class="row">
                      <div class="col-6">
                        <div>
                          <label for="canada-travel" class="comparison-tool__label" translate>HOME.COMPARISON_TOOL.YEARLY_CANADA_TEXT</label>
                        </div>
                        <div>
                          <select name="" id="canada-travel" class="form-select" aria-describedby="describe-travel-habits" [(ngModel)]="canadaTravelCount">
                            <option [ngValue]="0">0 {{'HOME.COMPARISON_TOOL.TRIPS' | translate}}</option>
                            <option [ngValue]="1">1 {{'HOME.COMPARISON_TOOL.TRIP' | translate}}</option>
                            <option [ngValue]="2">2+ {{'HOME.COMPARISON_TOOL.TRIPS' | translate}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div>
                          <label for="mexico-travel" class="comparison-tool__label" translate>HOME.COMPARISON_TOOL.YEARLY_MEXICO_TEXT</label>
                        </div>
                        <div>
                          <select name="" id="mexico-travel" class="form-select" aria-describedby="describe-travel-habits" [(ngModel)]="mexicoTravelCount">
                            <option [ngValue]="0">0 {{'HOME.COMPARISON_TOOL.TRIPS' | translate}}</option>
                            <option [ngValue]="1">1 {{'HOME.COMPARISON_TOOL.TRIP' | translate}}</option>
                            <option [ngValue]="2">2+ {{'HOME.COMPARISON_TOOL.TRIPS' | translate}}</option>
                          </select>
                        </div>
                      </div>
                  </div>
                </fieldset>
              </div>
          </div>

          <!-- 6 month check -->
          <div *ngIf="showIntTravelQuestion" class="row">
              <div class="col-12">
                  <fieldset class="comparison-tool__col2">

                    <legend class="visually-hidden" translate>HOME.COMPARISON_TOOL.ADDITIONAL_CONSIDERATIONS</legend>
                    <span class="label-wrap">
                      <label for="intTravelInSixMonths" class="comparison-tool__label" translate>HOME.COMPARISON_TOOL.INT_TRAVEL_IN_SIX_MONTHS</label>
                    </span>
                    <select id="intTravelInSixMonths" class="form-select" [(ngModel)]="intTravelInSixMonths">
                      <option value="">--</option>
                      <option value="yes" translate>GENERAL_REUSABLE.YES_BUTTON</option>
                      <option value="no" translate>GENERAL_REUSABLE.NO_BUTTON</option>
                    </select>
                  </fieldset>
            </div>
          </div>

          <div class="row comparison-tool__bottom-row">
            <div class="col-12 col-lg-5">
              <fieldset class="comparison-tool__col3">
                <legend class="visually-hidden" translate>HOME.COMPARISON_TOOL.ADDITIONAL_CONSIDERATIONS</legend>
                <span class="label-wrap">
                  <label for="passport" class="comparison-tool__label" translate>HOME.COMPARISON_TOOL.PASSPORT_INPLACE_TEXT</label>
                  <tooltip [tooltip-text] ="'HOME.COMPARISON_TOOL.MACHINE_READABLE_PASSPORT'"></tooltip>
                </span>
                <select id="passport" class="form-select" [(ngModel)]="hasPassport">
                  <option value="">--</option>
                  <option value="yes" translate>GENERAL_REUSABLE.YES_BUTTON</option>
                  <option value="no" translate>GENERAL_REUSABLE.NO_BUTTON</option>
                </select>
                <span class="label-wrap">
                  <label for="commercial-trucker" class="comparison-tool__label" translate>HOME.COMPARISON_TOOL.COMMERCIAL_DRIVER_TEXT</label>
                  <tooltip [tooltip-text] ="'HOME.COMPARISON_TOOL.COMMERCIAL_DRIVER_TOOLTIP'"></tooltip>
                </span>
                <select id="commercial-trucker" class="form-select" [(ngModel)]="isCommercialTrucker" [disabled]="!isCommercialTruckerAllowed">
                  <option value="yes" translate>GENERAL_REUSABLE.YES_BUTTON</option>
                  <option value="no" translate>GENERAL_REUSABLE.NO_BUTTON</option>
                </select>
              </fieldset>
            </div>

            <div class="col-12 col-lg-7 comparison-tool__actions-container ">
                <div class="comparison-tool__actions">
                  <div class="text-justify-xs text-justify-sm text-justify-md">
                      <p class="comparison-tool__reset">
                        <a id="submit-comparison" class="btn btn-info widget-button" (click)="submitClicked()" (keyup.enter)="submitClicked()" tabindex="0" translate>HOME.COMPARISON_TOOL.SHOW_BEST_MATCH_TEXT</a>
                        <a id="reset-comparison-tool" class="btn btn-info widget-button" (click)="reset()" (keyup.enter)="reset()" tabindex="0" translate>HOME.COMPARISON_TOOL.RESET_COMPARISON_TEXT</a>
                      </p>
                  </div>
                </div>
            </div>
          </div>

        </div>
    </div>
</div>
