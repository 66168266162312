import { Routes } from '@angular/router';
import { HomeRoutes } from './home/index';
import { LoginComponent } from './login.component';
import { PaymentRoutingComponent } from './payment-routing.component';
import { AccountProfileComponent } from './sign-up/account-profile.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { PurchaseSummaryComponent } from './purchase-summary/purchase-summary.component';
import { ScheduleComponent } from './interview/schedule/schedule.component';
import { InterviewSummaryComponent } from './interview/summary/interview-summary.component';
import { InterviewCancellationComponent } from './interview/cancellation/interview-cancellation.component';
import { InterviewCancelComponent } from './interview/cancellation/interview-cancel.component';
import { FAQComponent} from './faq/faq.component';
import { ReconsiderationComponent} from './reconsideration/reconsideration.component';
import { ViewReconsiderationComponent} from './reconsideration/viewReconsideration.component';
import { AuthGuard } from './common/services/auth-guard.service';
import { BypassComponent } from './bypass.component';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';

import { AnnouncementsComponent } from './announcements/announcements.component';
import { GECRoutingComponent } from './gec-routing/gec-routing.component';
import { PrivacyActComponent } from './privacy-act/privacy-act.compontent';
import { LegalGuardianComponent } from './legal-guardian/legal-guardian-info.component';

export const routes: Routes = [
  ...HomeRoutes,
  {
    path: 'application/:id',
    canActivate: [AuthGuard],
    // loadChildren: './application/application.module#ApplicationModule'
    loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule)
  },
  {
    path: 'program-selection',
    canActivate: [AuthGuard],
    // loadChildren: './progselection/progselection.module#ProgSelectionModule'
    loadChildren: () => import('./progselection/progselection.module').then(m => m.ProgSelectionModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    // loadChildren: './dashboard/dashboard.module#DashboardModule'
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'ombudsman',
    canActivate: [AuthGuard],
    component: ReconsiderationComponent
  },
  {
    path: 'viewReconsideration',
    canActivate: [AuthGuard],
    component: ViewReconsiderationComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'bypass',
    component: BypassComponent
  },
  {
    path: 'faq',
    component: FAQComponent
  },
  {
    path: 'payment/status/:status',
    component: PaymentRoutingComponent,
    data: { title: 'PAYMENT_HISTORY.PAYMENT_STATUS'}
  },
  {
    path: 'payment-history/:id',
    canActivate: [AuthGuard],
    component: PaymentHistoryComponent,
    data: { title: 'PROGRESS.PAYMENT_RECEIPT'}
  },
  {
    path: 'account-profile/:id',
    canActivate: [AuthGuard],
    component: AccountProfileComponent,
    data: { title: 'PROGRESS.ACCOUNT_PROFILE'}
  },
  {
    path: 'purchase-summary',
    canActivate: [AuthGuard],
    component: PurchaseSummaryComponent,
    data: { title: 'PURCHASE_SUMMARY.CONTENT_HEADER'}
  },
  {
    path: 'scheduler',
    component: ScheduleComponent
  },
  {
    path: 'interview-summary',
    canActivate: [AuthGuard],
    component: InterviewSummaryComponent,
    data: { title: 'INTERVIEW_CONFIRMATION.TITLE'}
  },
  {
    path: 'cancel-interview',
    canActivate: [AuthGuard],
    component: InterviewCancelComponent,
    data: { title: 'DASHBOARD.CANCEL_INTER'}
  },
  {
    path: 'cancellation-summary',
    canActivate: [AuthGuard],
    component: InterviewCancellationComponent,
    data: { title: 'INTERVIEW_CANCEL.SUMMARY.TITLE'}
  },
  {
    path : 'under-maintenance',
    component : UnderMaintenanceComponent
  },
  {
    path : 'announcements',
    component : AnnouncementsComponent
  },
  {
    path: 'gec',
    component: GECRoutingComponent
  },
  {
    path: 'privacy-act',
    component: PrivacyActComponent
  },
  {
    path: 'legal-guardian',
    canActivate: [AuthGuard],
    component: LegalGuardianComponent
  },
  {
      path: '**',
      redirectTo: '',
      pathMatch: 'full'
  }
];
