import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { ErrorHandlerService } from '../services/error-handler.service';
import { ApplicationSummary } from '../models/application-summary.model';
import { ProgramFee } from '../models/program-fee.model';
import { AuthService } from './auth.service';
import { PaymentRequest } from '../models/payment-request.model';
import Utils from '../utils/utils';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ModalService } from '../../shared/modals/modal.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { Router } from '@angular/router';

@Injectable()
export class PurchaseSummaryService {

  private unpaidApplications: ApplicationSummary[];
  protected totalFee: number;

  constructor(private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private authService: AuthService,
    private translate: TranslateService,
    private modalService: ModalService,
    private spinner: SpinnerService,
    private router: Router) { }

  /** Returns array of unpaid applciation summary **/
  getUnpaidApplications(userId: string, language: String): Observable<ApplicationSummary[]> {
    this.unpaidApplications = [];

    let uri = environment.uriEndpoint + environment.apiVersion +
      'goesapp/users/' + userId +'/applications/metadata/';

    return this.http.get(uri).pipe(
      map((res:any) => {
        if (res) {
          let result = res;

          for (let app of result) {
            if (app.status === 'CM' || app.status === 'PC') {

              const age = this.authService.getUser().age;
              let tempApp = new ApplicationSummary(app);
              for (let programFee of tempApp.feeList) {
                // if (!Utils.isMinAge(this.authService.getUser().dateOfBirth, programFee.minAge)) {
                if (age < programFee.minAge) {
                  if (programFee.programCode === 'AB') {
                      tempApp.abtcAddon = true;
                      tempApp.abtcFee = 0;
                  }
                  else {
                    tempApp.fee = 0;
                  }
                }
              }
              this.unpaidApplications.push( this.addDisplayData(tempApp) );
            }
          }
        }

      return this.unpaidApplications;
    }));
  }


  addDisplayData(summary: ApplicationSummary) {

    switch (summary.programCode) {
      case "GE":
        summary.programLogoAlt = 'IMAGE_ALT.GLOBAL_ENTRY_LOGO';
        summary.programLogo = 'assets/images/logos/logo-global-entry.png';
        summary.programName = 'Global Entry';
        break;

      case "UP":
        summary.programLogoAlt = 'IMAGE_ALT.GLOBAL_ENTRY_LOGO';
        summary.programLogo = 'assets/images/logos/logo-global-entry.png';
        summary.programName = 'Global Entry';
        break;

      case "NH":
        summary.programLogoAlt = 'IMAGE_ALT.NEXUS_LOGO';
        summary.programLogo = 'assets/images/logos/logo-nexus.png';
        summary.programName = 'NEXUS';
        break;

      case "SH":
        summary.programLogoAlt = 'IMAGE_ALT.SENTRI_LOGO';
        summary.programLogo = 'assets/images/logos/logo-sentri.png';
        summary.programName = 'SENTRI';
        break;

      case "FN":
        summary.programLogoAlt = 'IMAGE_ALT.FAST_EXPRES_LOGO';
        summary.programLogo = 'assets/images/logos/logo-us-canada-fast.gif';
        summary.programName = 'U.S. / Canada FAST';
        break;

      case "FS":
        summary.programLogoAlt = 'IMAGE_ALT.FAST_LOGO';
        summary.programLogo = 'assets/images/logos/logo-us-mexico-fast.jpg';
        summary.programName = 'U.S. / Mexico FAST';
        break;

      case "SE":
        summary.programLogoAlt = 'IMAGE_ALT.SMART_ENTRY_SERVICE_LOGO';
        summary.programLogo = 'assets/images/logos/SES-logo.png';
        summary.programName = 'Korea Smart Entry Service';
        break;

      case "AB":
          summary.programLogoAlt = 'IMAGE_ALT.APEC_LOGO';
          summary.programLogo = 'assets/images/logos/logo-apec-business-travel-card.png';
          summary.programName = 'APEC Business Travel Card';
          break;

    }

    return summary;
  }



  /*
   * Loops through and array of program fee dto.
   * program fee dto: programCode : string
   *                  feeCode : string
   *                  minAge : number
   *                  amount : number
   */
  getProgramFee(feeObjArr: any): number {
    // Loop through all of the applcations and add up fees
    let total = 0; // TESTING
    for (let feeObj of feeObjArr) {
      total += feeObj.amount;
    }

    return total;
  }

  initiatePayment(userId: string, appSummaries: ApplicationSummary[], fee: number): Observable<any> {

    let headers= {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
    }

    let paymentURI = environment.uriEndpoint + environment.apiVersion + 'goesapp/payment';

    let params = '?';
    let now = Utils.getTodayStr();
    params += 'personId=' + encodeURIComponent(userId);
    params += '&lang=' + this.translate.currentLang;
    params += '&totalAmount=' + fee;
    params += '&localDate=' + now;

    let paymentInfoArr: any[] = [];

    // addFeeInd : C (Conversion) or B (Balance Due)
    for (let appSummary of appSummaries) {
      let paymentReq = new PaymentRequest();
      paymentReq.appId = appSummary.id;

      if (appSummary.preConvertProgramCode !== "") {
          paymentReq.previousProgramCode = appSummary.preConvertProgramCode;
      }

      paymentReq.paymentAmt = appSummary.fee;
      if (appSummary.abtcFee) {
        paymentReq.paymentAmt += appSummary.abtcFee;
      }

      paymentReq.source = appSummary.source;
      paymentReq.reqType = appSummary.appType;
      paymentReq.programList = appSummary.feeList;
      paymentReq.addFeeInd = appSummary.addFeeInd;

      paymentInfoArr.push(paymentReq);
    }

    paymentURI += params;

    return this.http.post(paymentURI, JSON.stringify(paymentInfoArr), headers).pipe(
      map((response:any) => {
        if (response) {
          this.authService.refreshTokenAndGotoExternalUrl(response);
        }
      }),
      catchError(
        error => {          
          if(error.error && error.error.errorCode === 781){           
            Utils.scrollUp();            
            this.modalService.notify('DASHBOARD.DUPLICATE_PAYMENT_INITIALIZATION', 'DASHBOARD.DUPLICATE_PAYMENT_INITIALIZATION_HEADER', 'OK').then(res =>  {
              if (res) {   
                this.spinner.show();            
                this.router.navigate(['/dashboard']).then(res => this.spinner.hide());                   
              }
            });
            return of(false);
          } else {
            return this.errorHandler.handleError(error);                      
          }
        }
    ));
  }
  
  getProgramFeeList(payAction : string, appSummary : ApplicationSummary, userId?: string) : Observable<Array<ProgramFee>>{

    let programFeeList : ProgramFee[] = [];

    if (payAction === 'B' || payAction === 'C') {
      let programFeeURI = environment.uriEndpoint + environment.apiVersion + 'goesapp/program/fee/programfee/additional/' + userId;
      let params = '?indicator=' + payAction;
      params += '&applicationID=' + appSummary.id;
      params += '&applicationSrc=' + appSummary.source;
      params += '&programCodeFrom=' + appSummary.programCode;
      params += '&requestCodeFrom=' + appSummary.appType;

      if (payAction === 'C') {
          params += '&programCodeTo=UP';
      }

      programFeeURI += params;

      return this.http.get(programFeeURI).pipe(
        map((response:any) => {
          if (response) {
            let programFee = new ProgramFee(response);
            programFeeList.push(programFee);
            return programFeeList;
          }
          return null;
        }),
        catchError(error => this.errorHandler.handleError(error)));
    }
    else {
      let programFeeURI = environment.uriEndpoint + environment.apiVersion + 'getProgramFee/' + appSummary.id;

      return this.http.get(programFeeURI).pipe(
        map((response:any) => {
          if (response) {
            return response;
          }
        }),
        catchError(error => this.errorHandler.handleError(error)));
    }
  }
}