export class GESAppointmentModel {

    public applicationCode = 0;
    public appointmentId = 0;
    public cancellationReason = '';
    public enrollmentCenterName = '';

    public eventTypeId = 0;
    public rescheduledEventTypeId: number = null;
    public goesUserId = '';
    public locationId = 0;
    public passId = '';

    public programId = '';
    public serviceId = 1;
    public source = '';

    public startTimestamp = '';
    public endTimestamp = '';
    public type = ''; // sch = schedule, sch = reschedule, cna = cancelled
    public userId = 1; // generic scheduler id: always 1
    public lastUpdatedBy = '';

    public tzData = '';

    public get startDateDisplayString(): string {
        const splitArray: Array<string> = this.startTimestamp.split('T');
        if (splitArray.length > 0){
            return splitArray[0];
        } else {
            return this.startTimestamp;
        }
    }

    public get startTimeDisplayString(): string {
        const splitArray: Array<string> = this.startTimestamp.split('T');
        if (splitArray.length > 0){
            let str: string = splitArray[1];
            if (str.length >= 4){
                str = str.substr(0, 4);
            }
            return str;

        } else {
            return this.startTimestamp;
        }
    }

    constructor(){ }

    public toJSONforCreate(): any {
        const json: any = this.getCommonJSON();
        return json;
    }

    public toJSONforUpdate(): any {
        const json: any = this.getCommonJSON();
        json.appointmentId = this.appointmentId;
        json.cancellationReason = this.cancellationReason;
        return json;
    }

    public toJSONforDelete(): any {
        const json: any = this.getCommonJSON();
        json.appointmentId = this.appointmentId;
        json.cancellationReason = this.cancellationReason;
        return json;
    }

    private getCommonJSON(): any {
        const json: any = {};
        json.applicationCode = this.applicationCode;
        json.startTimestamp = this.startTimestamp;
        json.endTimestamp = this.endTimestamp;
        json.enrollmentCenterName = this.enrollmentCenterName;
        json.eventTypeId = this.eventTypeId;
        json.rescheduledEventTypeId = this.rescheduledEventTypeId;
        json.goesUserId = this.goesUserId;
        json.locationId = this.locationId;
        json.passId = this.passId;
        json.programId = this.programId.toLowerCase();
        json.serviceId = this.serviceId;
        json.source = this.source;
        json.type = this.type;
        json.userId = this.userId;
        json.lastUpdatedBy = this.lastUpdatedBy;
        json.tzData = this.tzData;
        return json;
    }

}
