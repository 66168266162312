<div id="announcement-container" *ngIf="!isUserLoggedIn() && goldBarItems.length > 0 && showAnnouncements">
    <div class="row announcement">
        <div class="col-12 col-md-2 announcement-heading d-none d-md-block">
            <span translate>ALERTS.ALERTS_LINE_1</span> <span class="sub" translate> ALERTS.ALERTS_LINE_2</span>
        </div>
        <div class="col-12 col-md-10 announcement-body">
            <div class="announcement-close">
                <button type="button" class="ttp-btn-close" aria-label="Close" (click)="showAnnouncements=false"><span aria-hidden="true">×</span></button>
                <!-- <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="showAnnouncements=false"></button> -->
            </div>
            <div *ngFor="let goldBarItem of goldBarItems; let i = index">
                <span class="fas fa-info-circle" ng-class="{'fa-exclamation': item.style == 2, 'fa-info-circle': item.style == 1}"></span>
                <span [innerHTML]="goldBarItems[i]?.name"></span> <a class="read-more-link" *ngIf="goldBarItems[i]?.content" href="javascript:void(0)" (click)="showAlertContent(goldBarItem)" (key.enter)="showAlertContent(goldBarItem)" translate>HOME.ABOUT.LEARN_MORE</a>
            </div>
        </div>
    </div>
</div>

<info-modal [id]="'alert-info-modal'"  [title]="alertTitle" [buttonText]="'MODAL.BUTTON_CLOSE'" [translate-src]="alertContent"></info-modal>
