<div class="row">
	<div class="col-lg-12 col-lg-12 progress-top-level centered">
        <h1 translate>PROGRESS.ACCOUNT_PROFILE</h1>
        <p class="divider d-none d-md-block"></p>
    </div>
</div> 

<div class="page-content d-flex">
    <div class="container">
        <form #personalInfoForm = "ngForm" novalidate>

        <div class="content-panel" *ngIf="countries && personalInfo">
            <div class="row">
                <div class="col-8">
                    <h2 translate>SIGN_UP.YOUR_INFO</h2>
                </div>    
                <div class="col-4 required-div">
                    <span class="float-end required" translate>GENERAL_REUSABLE.REQUIRED_FIELD</span>
                </div>
            </div>


            <div class="page-hint col-12 col-lg-8">
                <span><i class="far fa-lightbulb"> </i><span translate>SIGN_UP.HAVE_PASSPORT</span></span>
            </div>


            <div class="row">
                <div class="col-md-5 col-12">
                    <label for="lastName" class="label-with-tooltip">
                       <span class="required">*</span> <span translate>SIGN_UP.LAST_NAME</span>
                        <tooltip [tooltip-text] ="'SIGN_UP.LASTNAME_HELP'"></tooltip>
                    </label>
                    <input type="text" [attr.disabled]= "editMode && editDisabled.lastName ? '' : null" class="form-control" id="lastName" maxlength = "50" [(ngModel)]= "personalInfo.lastName" [pattern]="textAlphaPattern" name="lastName" required>

                    <div class= "field-error" *ngIf = "formErrors.lastName">
                         <p *ngFor= "let error of formErrors.lastName" [innerHTML]= "error | translate"></p>
                    </div>
                </div>    
                
                <div class="col-md-5 col-12">
                    <label for="firstName" class="label-with-tooltip">
                        <span class="required">*</span> <span translate>SIGN_UP.FIRST_NAME</span>
                        <tooltip [tooltip-text] ="'SIGN_UP.FIRSTNAME_HELP'"></tooltip>
                    </label>

                    <input type="text" [attr.disabled]= "editMode && editDisabled.firstName ? '' : null" class="form-control" id="firstName" [(ngModel)]= "personalInfo.firstName" [pattern]="textAlphaPattern" maxlength = "30" name="firstName" required>

                    <div class= "field-error" *ngIf = "formErrors.firstName">
                        <p *ngFor= "let error of formErrors.firstName" [innerHTML]= "error | translate"></p>
                    </div>
                </div>
            </div>
       
            <div class="row">
                <div class="col-md-5 col-12">
                    <label for="middleName">
                        <span translate>SIGN_UP.MIDDLE_NAME</span>
                    </label>
                    <input type="text" class="form-control" [attr.disabled]= "editMode && editDisabled.middleName ? '' : null" id="middleName"  [(ngModel)]= "personalInfo.middleName" [pattern]="textAlphaPattern" maxlength = "30" name="middleName">
                    <div class= "field-error" *ngIf = "formErrors.middleName">
                         <p *ngFor= "let error of formErrors.middleName" [innerHTML]= "error | translate"></p>
                    </div>
                </div>
            
                <div class="col-md-6 col-12">
                    <date-selection [disabled]= "editMode && editDisabled.dob" [minDate]="minDate" [domId]="'DOB'" [errorLabel]="'DOB'" [maxDate]="maxDate" [(ngModel)]= "personalInfo.dateOfBirth" #dateOfBirth name="dateOfBirth" [label] = "'SIGN_UP.DATE_OF_BIRTH'" [required]="true"></date-selection>  
                </div>
            </div>
            
            
            
            <div class="row">
                <div class="col-md-5 col-12">
                    <label for="countryOfBirth">
                        <span class="required">*</span> <span translate>SIGN_UP.COUNTRY_OF_BIRTH</span>
                    </label>
                    <select class="form-select" [disabled]= "editMode && editDisabled.countryOfBirth ? '' : null" name="countryOfBirth" id="countryOfBirth"  required (change)= "personalInfo.stateOfBirth = ''"  [(ngModel)]= "personalInfo.countryOfBirth" >
                        <option value=""></option>
                        <option *ngFor="let country of countries" [ngValue]= "country.isoCountryCode" translate> GENERAL_REUSABLE.COUNTRY.{{country.isoCountryCode}} </option>
                    </select>
                    <div class= "field-error" *ngIf = "formErrors.countryOfBirth">
                        <p *ngFor= "let error of formErrors.countryOfBirth" [innerHTML]= "error | translate"></p>
                    </div>

                </div>
                <div class="col-md-5 col-12">            
                    <label for="stateOfBirth">
                        <span *ngIf="personalInfo.countryOfBirth === 'US' ||
                                personalInfo.countryOfBirth === 'MX' ||
                                personalInfo.countryOfBirth === 'CA'" class="required">*</span><span translate>SIGN_UP.STATE_OF_BIRTH</span>
                    </label>
                    <div *ngIf="personalInfo.countryOfBirth === 'US'">
                        <select class="form-select" name="stateOfBirth" [disabled]= "editMode && editDisabled.stateOfBirth ? '' : null" id="stateOfBirth" [(ngModel)]= "personalInfo.stateOfBirth" required>
                            <option value=""></option>
                            <option *ngFor="let state of usStates" [ngValue]= "state.code">{{state.name}}</option>
                        </select>
                        <div class= "field-error" *ngIf = "formErrors.stateOfBirth">
                            <p *ngFor= "let error of formErrors.stateOfBirth" [innerHTML]= "error | translate"></p>
                        </div>
                    </div>
                    <div *ngIf="personalInfo.countryOfBirth === 'MX'">
                        <select class="form-select" name="stateOfBirth" [disabled]= "editMode && editDisabled.stateOfBirth ? '' : null" id="stateOfBirth" [(ngModel)]= "personalInfo.stateOfBirth" required>
                            <option value=""></option>
                            <option *ngFor="let state of mxStates" [ngValue]= "state.code">{{state.name}}</option>
                        </select>
                        <div class= "field-error" *ngIf = "formErrors.stateOfBirth">
                            <p *ngFor= "let error of formErrors.stateOfBirth" [innerHTML]= "error | translate"></p>
                        </div>
                    </div>
                    <div *ngIf="personalInfo.countryOfBirth === 'CA'">
                        <select class="form-select" name="stateOfBirth" id="stateOfBirth" [disabled]= "editMode && editDisabled.stateOfBirth ? '' : null" [(ngModel)]= "personalInfo.stateOfBirth" required>
                            <option value=""></option>
                            <option *ngFor="let state of caStates" [ngValue]= "state.code">{{state.name}}</option>
                        </select>
                        <div class= "field-error" *ngIf = "formErrors.stateOfBirth">
                             <p *ngFor= "let error of formErrors.stateOfBirth" [innerHTML]= "error | translate"></p>
                        </div>
                    </div>
                    <div *ngIf="!(personalInfo.countryOfBirth === 'US' ||
                            personalInfo.countryOfBirth === 'MX' ||
                            personalInfo.countryOfBirth === 'CA')">

                        <input type = "text" class="form-control" name = "stateOfBirth" [disabled]= "editMode && editDisabled.stateOfBirth ? '' : null" id= "stateOfBirth" maxlength = "70"  [(ngModel)]= "personalInfo.stateOfBirth">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-5 col-12">
                    <label for="cityOfBirth">
                        <span class="required">*</span> <span translate>SIGN_UP.CITY_OF_BIRTH</span>
                    </label>
                    <input type = "text" class="form-control" id="cityOfBirth" [attr.disabled]= "editMode && editDisabled.cityOfBirth ? '' : null" name = "cityOfBirth" [(ngModel)] = "personalInfo.cityOfBirth" maxlength = "70" required>                                                                                                                    <div class= "field-error" *ngIf = "formErrors.cityOfBirth">
                        <p *ngFor= "let error of formErrors.cityOfBirth" [innerHTML]= "error | translate"></p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 col-sm-12">
                    <div class="row">
                        <div class="col-sm-12">
                            <label for ="emailAddress" class="label-with-tooltip">
                                <span translate>SIGN_UP.EMAIL_ADDRESS</span>
                                <tooltip [tooltip-text] ="'SIGN_UP.EMAIL_ADDRESS_HELP'"></tooltip>
                            </label>
                            <input type="text" allowSpecial="true" class="form-control" id="emailAddress" maxlength = "50" [disabled]="personalInfo.emailOptoutInd" #email="ngModel" (change)="cleanEmail()"  name="emailAddress" match="emailConfirmation" reverse="true" (blur) = "updateBlur()" [(ngModel)] = "personalInfo.emailAddress"  [pattern]="emailPattern">
                            <div class= "field-error" *ngIf = "formErrors.emailAddress">
                                <p *ngFor= "let error of formErrors.emailAddress" [innerHTML]= "error | translate"></p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label for="emailConfirmation">
                                <span translate><span *ngIf="personalInfo.emailAddress != '' && email.dirty" class="required">*</span>SIGN_UP.CONFIRM_EMAIL_ADDRESS</span>
                            </label>
                            <input type="text" allowSpecial = "true" class="form-control" id="emailConfirmation" maxlength = "50" [disabled]="personalInfo.emailOptoutInd" #emailConfirmation="ngModel" match="emailAddress"  name = "emailConfirmation" (blur) = "updateBlur()" [(ngModel)]= "personalInfo.confirmEmailAddress" [required]= "personalInfo.emailAddress != '' && email.dirty" [pattern]="emailPattern">
                            <div class= "field-error" *ngIf = "formErrors.emailConfirmation">
                                 <p *ngFor= "let error of formErrors.emailConfirmation" [innerHTML]= "error | translate"></p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-sm-6 col-md-5 col-11 email-note">
                    <label for="emailOpt" id="emailOptLabel" [ngClass]="{'active' : personalInfo.emailOptoutInd  === true}" (click)="optOut()" (keyup.enter)=" optOut(); personalInfo.emailOptoutInd = !personalInfo.emailOptoutInd" tabindex="0" > 
                        <input type="checkbox" name = "emailOpt"  [(ngModel)]= "personalInfo.emailOptoutInd"  [value] = "true" tabindex="-1"  id="emailOpt"  >
                        <span [innerHTML]= "'SIGN_UP.DO_NOT_SEND_EMAIL' | translate"></span>   
                    </label>
                    <div>
                        <p [innerHTML]= "'SIGN_UP.EMAIL_NOTE' | translate"></p>
                    </div>
                </div>
            </div>
            
            
            <div *ngFor="let phone of personalInfo.phoneNumbers; let i=index">
                <div class="mb-3">
                    <div class="row">
                        <div class="col-12 col-lg-3 col-xl-2">
                            <!-- phone type -->
                            <label for="phonetype_{{i}}" id="phoneType_label_{{i}}" class="phone-label-no-tooltip">
                                <span class="required">*</span> <span translate>APPLICATION.PERSONAL_INFO.PHONETYPE</span>
                            </label>
                            <select id="phonetype_{{i}}" name="phonetype_{{i}}" [attr.disabled]= "editMode && editDisabled.phone ? '' : null" #phonetype= "ngModel" class="form-select" [(ngModel)]="phone.type" required>
                                <option value=""></option>
                                <option value="H" translate>APPLICATION.PERSONAL_INFO.HOME</option>
                                <option value="C" translate>APPLICATION.PERSONAL_INFO.MOBILE</option>
                                <option value="W" translate>APPLICATION.PERSONAL_INFO.WORK</option>             
                            </select>
                        </div>

                        <div class="col-12 col-lg-4 col-xl-4">
                            <!-- phone format -->
                            <label for="phoneformat_{{i}}" id="phoneFormat_label_{{i}}" class="label-with-tooltip">
                                <span class="required">*</span> <span translate>APPLICATION.PERSONAL_INFO.PHONEFORMAT</span>
                                <tooltip [tooltip-text] ="'APPLICATION.PERSONAL_INFO.PHONEFORMAT_HELP'"></tooltip>
                            </label>
                            <select id="phoneformat_{{i}}" name="phoneformat_{{i}}" #phoneformat="ngModel" [attr.disabled]= "editMode && editDisabled.phone ? '' : null" class="form-select" (change)="updateCode(phone)" [(ngModel)]="phone.format" required>
                                <option value=""></option>
                                <option value="N" translate>APPLICATION.PERSONAL_INFO.US_CAN_FORMAT</option>
                                <option value="M" translate>APPLICATION.PERSONAL_INFO.MX_FORMAT</option>
                                <option value="I" translate>APPLICATION.PERSONAL_INFO.OTHER_FORMAT</option>
                            </select>
                        </div>

                        <div class="col-12 col-lg-3 col-xl-2" [hidden]="phone.format != 'M' && phone.format != 'I'">
                            <!-- country code -->
                            <label for="countryCode_{{i}}" id="countryCode_label_{{i}}" class="phone-label-no-tooltip">
                                <span class="required">*</span> <span translate>APPLICATION.PERSONAL_INFO.COUNTRYCODE</span>
                            </label>
                            <!-- <tooltip [tooltip-text] ="'APPLICATION.PERSONAL_INFO.COUNTRY_CODE_HELP'"></tooltip>  -->
                            <input type="text" [value-type]="'numeric'" [pattern]="numberPattern" [attr.disabled]= "editMode && editDisabled.phone ? '' : null" class="form-control" #countrycode="ngModel" id="countryCode_{{i}}" name="countryCode_{{i}}" [(ngModel)]="phone.countryCode" maxlength="3" [required]="phone.format == 'M' || phone.format == 'I'">
                        </div>
                            
                        <div class="col-12 col-lg-3 col-xl-3">
                            <!-- phone number -->
                            <label for="phoneNumber_{{i}}" id="phoneNumber_label_{{i}}" class="label-with-tooltip">
                                <span class="required">*</span> <span translate>APPLICATION.PERSONAL_INFO.PHONENUMBER</span>
                                <tooltip [tooltip-text] ="'APPLICATION.PERSONAL_INFO.PHONENUMBER_HELP'"></tooltip>
                            </label>

                            <input [attr.disabled]= "editMode && editDisabled.phone ? '' : null"  [pattern]="numberPattern" [value-type]="'numeric'" maxlength = "{{ phone.format === 'N'? 10 : 15 }}" [minlength] = "getMinlength(phone.format)" class="form-control" #phonenumber="ngModel"  id="phoneNumber_{{i}}" name="phoneNumber_{{i}}" [(ngModel)]="phone.number" required>
                        </div>

                        <div class="col-6 col-lg-2 col-xl-1">
                            <!-- extension -->
                            <label for="ext_{{i}}" id="ext_label_{{i}}" class="phone-label-no-tooltip">
                                <span translate>APPLICATION.PERSONAL_INFO.PHONEEXT</span>
                            </label>

                            <input type="text" #phoneExt="ngModel" [value-type]="'numeric'"[pattern]="numberPattern" [attr.disabled]= "editMode && editDisabled.phone ? '' : null" class="form-control" id="ext_{{i}}" name="ext_{{i}}" maxlength = "6" [(ngModel)]="phone.extension">              

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6" *ngIf="personalInfo.phoneNumbers.length > 1 && !(editMode && editDisabled.phone)">
                            <a href="javascript:void(0)" (click)="removePhone(phone)" (keyup.enter)="removePhone(phone)" title="{{'SIGN_UP.REMOVE_PHONE_TITLE' | translate}}" class="link-red" tabindex="0">
                                <i class="far fa-minus-square"></i> <b translate>APPLICATION.PERSONAL_INFO.REMOVE_PHONE</b>
                            </a>
                        </div>
                    </div>
                </div>
                
                <p class="field-error"  *ngIf="_submitted && (
                          (phonenumber.errors && phonenumber.errors['required']) ||
                          (countrycode.errors && countrycode.errors['required']) ||
                          (phoneformat.errors && phoneformat.errors['required']) ||
                          (phonetype.errors && phonetype.errors['required']))" [innerHTML]="'ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.PHONE_REQUIRED' | translate"></p>

                <p class="field-error" *ngIf="_submitted && (
                          (phonenumber.errors && phonenumber.errors['maxlength']) || (phonenumber.errors && phonenumber.errors['minlength']))" [innerHTML]="'ERROR_MESSAGES.GENERAL.PHONE_LENGTH' | translate"></p>

                <p class="field-error" *ngIf="_submitted && (
                          (countrycode.errors && countrycode.errors['maxlength']))" [innerHTML]="'ERROR_MESSAGES.GENERAL.COUNTRYCODE_LENGTH' | translate"></p>
               
                <p class="field-error" *ngIf="_submitted && (
                          (phoneExt.errors && phoneExt.errors['maxlength']))" [innerHTML]="'ERROR_MESSAGES.GENERAL.EXTENSION_LENGTH' | translate"></p>
                
                <p class="field-error" *ngIf="_submitted && (
                          (phonenumber.errors && phonenumber.errors['pattern']) || (countrycode.errors && countrycode.errors['pattern']) || (phoneExt.errors && phoneExt.errors['pattern']))" [innerHTML]="'ERROR_MESSAGES.GENERAL.CONTAINS_INVALID' | translate"></p>
            </div>

            <div class="row" *ngIf="personalInfo?.phoneNumbers?.length <=2 && !(editMode && editDisabled.phone)">
                    <div class="col-12">
                        <a href="javascript:void(0)" (click)="addPhone()" (keyup.enter)="addPhone()" title="{{'SIGN_UP.ADD_PHONE_NUMBER_TITLE' | translate}}" tabindex="0">
                            <i class="far fa-plus-square"></i> <b translate>APPLICATION.PERSONAL_INFO.ADD_ANOTHER_PHONE</b>
                        </a>
                    </div>
                </div>
        </div>


        <div class="content-panel" *ngIf="personalInfo && !editMode">

            <div class="row">
                <div class="col-8">
                    <h2 translate>SIGN_UP.PREVIOUS_APPLICATIONS</h2>
                </div>
            </div>    

            <div class="page-hint">
                <span><i class="far fa-lightbulb"></i></span>  
                <span translate>SIGN_UP.PREVIOUSLY_SUBMITTED</span>
            </div>

            <div class="list-group row flex-row">
                <div class="col-12 col-lg-7">
                    <div class="row">
                        <label for="appliedTPP" id="applied_ttp_label" class="label-with-tooltip">
                            <span class="required">*</span><span translate>SIGN_UP.APPLIED_GE</span>
                            <tooltip class="no-float" [tooltip-text]="'SIGN_UP.APPLIED_GE_HELP'"></tooltip>
                        </label>                   
                        
                    </div>  
                    <div class="row" role="radiogroup" attr.aria-label="{{'SIGN_UP.APPLIED_GE' | translate}}">
                        <div class="col-12">
                            <label for="appliedTPPYes" id="appliedTPPY" class="btn btn-radio" [ngClass]="{'active' : personalInfo.appliedTPP ==='Y'}" role="button" tabindex="0"
                                (keyup.enter)="personalInfo.appliedTPP = 'Y'; cleanData(1)" >
                                <span aria-hidden="true"></span>
                                <input type="radio" name="appliedTPP" (change)= "cleanData(1)" value= "Y" id="appliedTPPYes" tabindex="1" [(ngModel)] = "personalInfo.appliedTPP" required>{{'GENERAL_REUSABLE.YES_BUTTON' | translate}}
                            </label>
                            <label for="appliedTPPNo" class="btn btn-radio" id="appliedTPPN" [ngClass]="{'active' : personalInfo.appliedTPP ==='N'}" role="button" tabindex="0"
                                (keyup.enter)="personalInfo.appliedTPP = 'N'; cleanData(1)" >
                                <span aria-hidden="true"></span>
                                <input type="radio" name="appliedTPP" (change)= "cleanData(1)" value= "N" id="appliedTPPNo" [(ngModel)] = "personalInfo.appliedTPP" required>{{'GENERAL_REUSABLE.NO_BUTTON' | translate}}
                            </label> 
                        </div>
                        <div class= "field-error" *ngIf = "formErrors.appliedTPP">
                            <p *ngFor= "let error of formErrors.appliedTPP" [innerHTML]= "error | translate"></p>
                        </div> 
                    </div> 
                </div>
                <div class="col-12 col-lg-5 text-center separated-list">
                    <div id="ge">
                        <img style="width : 100%;" alt="{{'IMAGE_ALT.GLOBAL_ENTRY_LOGO' | translate}}" src="assets/images/logos/logo-global-entry.png"/>
                    </div>
                    <div id="nexus">
                        <img style="width : 100%;" alt="{{'IMAGE_ALT.NEXUS_LOGO' | translate}}" src="assets/images/logos/logo-nexus.png"/>
                    </div>
                    <div id="sentri">
                        <img style="width : 100%;" alt="{{'IMAGE_ALT.SENTRI_LOGO' | translate}}" src="assets/images/logos/logo-sentri.png"/>
                    </div>
                </div>
            </div>

            <div class="list-group row flex-row">
                <div class="col-12 col-lg-7">
                    <div class="row">
                        <label for="appliedFast" id="applied_fast_label" class="label-with-tooltip">
                            <span class="required">*</span><span translate>SIGN_UP.APPLIED_FAST</span>
                            <tooltip class="no-float" [tooltip-text]="'SIGN_UP.APPLIED_FAST_HELP'"></tooltip> 
                        </label>                   
                    </div>  
                    <div class="row" role="radiogroup" attr.aria-label="{{'SIGN_UP.APPLIED_FAST' | translate}}">
                        <div class="col-12">
                            <label id="appliedFastY" class="btn btn-radio" [ngClass]="{'active' : personalInfo.appliedFast ==='Y'}" role="button" tabindex="0"
                                (keyup.enter)="personalInfo.appliedFast = 'Y'; cleanData(1)" >
                                <span aria-hidden="true"></span>
                                <input type="radio" name="appliedFast" (change)= "cleanData(1)" value= "Y" id="appliedFastYes" 
                                    [(ngModel)] = "personalInfo.appliedFast" required>{{'GENERAL_REUSABLE.YES_BUTTON' | translate}}
                            </label>
                            <label for="appliedFastNo" class="btn btn-radio" id="appliedFastN" [ngClass]="{'active' : personalInfo.appliedFast ==='N'}" role="button" tabindex="0"
                                (keyup.enter)="personalInfo.appliedFast = 'N' ; cleanData(1)" >
                                <span aria-hidden="true"></span>
                                <input type="radio" name="appliedFast" (change)= "cleanData(1)" value= "N" id="appliedFastNo" 
                                    [(ngModel)] = "personalInfo.appliedFast" required>{{'GENERAL_REUSABLE.NO_BUTTON' | translate}}
                            </label> 
                        </div>
                        <div class= "field-error" *ngIf = "formErrors.appliedFast">
                            <p *ngFor= "let error of formErrors.appliedFast" [innerHTML]= "error | translate"></p>
                        </div> 
                    </div>
                </div>
                
                <div class="col-12 col-lg-5 text-center separated-list">
                    <div class="fast-logo-account">
                        <img alt="{{'IMAGE_ALT.FAST_LOGO' | translate}}" src="assets/images/logos/logo-us-mexico-fast.jpg"/>
                        <p>U.S.-Mexico Fast</p>           
                    </div>
                    <div class="fast-logo-account">
                        <img alt="{{'IMAGE_ALT.FAST_EXPRES_LOGO' | translate}}" src="assets/images/logos/logo-us-canada-fast.gif"/>
                        <p>U.S.-Canada Fast</p>
                    </div>
                </div>
            </div> 

            <div class="sublevel-1" *ngIf= "personalInfo.appliedFast === 'Y'">
                <div class="row">
                    <div class="col-12">
                        <div class="sub-level" role="group" aria-labelledby="membershipNumber_label">
                            <div class="row">
                                <label for="membershipNumber" id="membershipNumber_label" class="ps-0">
                                    <span class="required">*</span> <span translate>SIGN_UP.PROVIDE_PROGRAM</span>
                                </label>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-8">
                                    <div class="row">
                                        <div class="col-md-7 col-12">
                                            <label for="appToggleTPP" id="appToggleTpp_label" class="btn btn-radio" style="padding-right: 15px;" [ngClass]="{'active' : appToggle ==='TPP'}" role="button" tabindex="0"
                                                (keyup.enter)="appToggle = 'TPP' ; cleanData(2)" >
                                                <span aria-hidden="true"></span>
                                                <input type="radio" name="appToggle" (change)= "cleanData(2)" value= "TPP" id="appToggleTPP"
                                                    [(ngModel)] = "appToggle" required>{{'SIGN_UP.MEMBERSHIP_LABEL' | translate}}
                                            </label>                         
                                            <tooltip [tooltip-text]="'SIGN_UP.MEMBERSHIP_ID_HELP'"></tooltip> 
                                        </div> 

                                        <div class="col-md-5 col-12">
                                            <div class="row">
                                                    <input  class="form-control" (blur) = "updateBlur()"  
                                                        [disabled]= "(appToggle === 'FAST' || appToggle === 'PASS' || !appToggle )? '' : null" 
                                                        [pattern] ="passIdPattern" [value-type]="'numeric'" name="passId" id="passId"  maxlength = '9' minlength = '9' 
                                                        [(ngModel)] = "personalInfo.passId" [required] = "appToggle === 'TPP'" attr.aria-label="{{'SIGN_UP.MEMBERSHIP_IS_EXACTLY' | translate}}">                            
                                            </div>
                                            <span class="subtext" translate>SIGN_UP.MEMBERSHIP_IS_EXACTLY</span>
                                        </div>
                                        <div class= "field-error col-12" *ngIf = "formErrors.passId">
                                            <p *ngFor= "let error of formErrors.passId" [innerHTML]= "error | translate"></p>
                                        </div>
                                        <!-- <div class="pt-2 ps-0 pe-0"> -->
                                            <div class="col-md-7 col-12 pt-2">
                                                <label for="appToggleFast" id="appToggleFast_label" class="btn btn-radio" style="width : 259.41px;" [ngClass]="{'active' : appToggle ==='FAST'}" role="button"
                                                    (keyup.enter)="appToggle = 'FAST' ; cleanData(2)" tabindex="0">
                                                    <span aria-hidden="true"></span>
                                                    <input type="radio" name="appToggle" (change)= "cleanData(2)" value="FAST" id="appToggleFast"
                                                        [(ngModel)] = "appToggle" required>{{'SIGN_UP.FAST_ID_LABEL' | translate}}
                                                </label>
                                                <tooltip [tooltip-text]="'SIGN_UP.FAST_ID_HELP'"></tooltip> 
                                            </div>

                                            <div class="col-md-5 col-12 pt2">
                                                <div class="row">
                                                    <input class="form-control" (blur) = "updateBlur()" 
                                                        [disabled]= "(appToggle === 'TPP' || appToggle === 'PASS' || !appToggle )? '' : null" 
                                                        name="fastId" [value-type]="'numeric'" id="fastId" [pattern] ="fastIdPattern" maxlength = '14' minlength = '14' 
                                                        [(ngModel)] = "personalInfo.fastId" [required] = "appToggle === 'FAST'" attr.aria-label="{{'SIGN_UP.FAST_IS_EXACTLY' | translate}}">
                                                </div> 
                                                <span class="subtext" translate>SIGN_UP.FAST_IS_EXACTLY</span>
                                            </div>
                                            <div class= "field-error col-12" *ngIf = "formErrors.fastId">
                                                <p *ngFor= "let error of formErrors.fastId" [innerHTML]= "error | translate"></p>
                                            </div>
                                        <!-- </div> -->

                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 position-relative pt-2"> 
                                    <button class="btn btn-primary btn-sample-image" style="margin-right: 12px;" (click)="viewImage()" (keyup.enter)="viewImage()" title="{{'SIGN_UP.VIEW_MEM_CARD_TITLE' | translate}}" tabindex="0">
                                        <i class="fa fa-search"></i>
                                    </button>
                                    <img alt="{{'IMAGE_ALT.TRUSTED_TRAVELER_MEMBERSHIP_CARD_SAMPLE' | translate}}" style="width:300px;" src="assets/images/samples/sample-preview-Membership-Card.jpg" class="img-fluid float-end">
                                    <a class="float-end" href="javascript:void(0)" style="padding-right : 30px" (click)="viewImage()">Click to view sample membership card</a>               
                                </div>
                            </div>

           
                             <div class= "field-error" *ngIf = "formErrors.appToggle">
                                 <p *ngFor= "let error of formErrors.appToggle" [innerHTML]= "error | translate"></p>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
            <div class="sublevel-1" *ngIf= "(!personalInfo.appliedFast || personalInfo.appliedFast === 'N') &&  personalInfo.appliedTPP === 'Y'">
                <div class="row">
                    <div class="col-12">
                        <div class="sub-level" role="group" aria-labelledby="membershipNumber_label2">

                            <!--START CHANGE-->
                            <div class="row">
                                <label for="membershipNumber" id="membershipNumber_label2" class="ps-0">
                                    <span class="required">*</span> <span translate>SIGN_UP.PROVIDE_PROGRAM</span>
                                </label>
                            </div>
                            <div class="row">
                                <div class="col-12 col-lg-8">
                                    <div class="row">
                                        <div class="col-md-7 col-12">
                                            <label for="appToggleTPP" id="appToggleTpp_label" class="btn btn-radio" style="padding-right: 15px;" [ngClass]="{'active' : appToggle ==='TPP'}" tabindex="0" role="button"
                                                (keyup.enter)="appToggle = 'TPP' ; cleanData(2)" >
                                                <span aria-hidden="true"></span>
                                                <input type="radio" name="appToggle" (change)= "cleanData(2)" value= "TPP" id="appToggleTPP" [(ngModel)] = "appToggle" required>{{'SIGN_UP.MEMBERSHIP_LABEL' | translate}}
                                            </label>                         
                                            <tooltip [tooltip-text]="'SIGN_UP.MEMBERSHIP_ID_HELP'"></tooltip> 
                                        </div> 

                                        <div class="col-md-5 col-12">
                                            <div class="row">
                                              <!--//  <div class="col-xs-11 col-sm-10">-->
                                                    <input  class="form-control" (blur) = "updateBlur()"
                                                        [disabled]= "(appToggle === 'FAST' || appToggle === 'PASS' || !appToggle ) ? '' : null"
                                                        [pattern] ="passIdPattern" [value-type]="'numeric'" name="passId" id="passId"
                                                        maxlength = '9' minlength = '9' [(ngModel)] = "personalInfo.passId" [required] = "appToggle === 'TPP'"
                                                        attr.aria-label="{{'SIGN_UP.MEMBERSHIP_IS_EXACTLY' | translate}}">                            
                                                <!--</div>   -->
                                            </div>
                                            <span class="subtext" translate>SIGN_UP.MEMBERSHIP_IS_EXACTLY</span>
                                        </div>
                                        <div class= "field-error col-12" *ngIf = "formErrors.passId">
                                            <p *ngFor= "let error of formErrors.passId" [innerHTML]= "error | translate"></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 position-relative"> 
                                    <button class="btn btn-primary btn-sample-image" style="margin-right: 12px;" (click)="viewImage()" (keyup.enter)="viewImage()" title="{{'SIGN_UP.VIEW_MEM_CARD_TITLE' | translate}}" tabindex="0">
                                        <i class="fa fa-search"></i>
                                    </button>
                                    <img alt="{{'IMAGE_ALT.TRUSTED_TRAVELER_MEMBERSHIP_CARD_SAMPLE' | translate}}" style="width:300px;" src="assets/images/samples/sample-preview-Membership-Card.jpg" class="img-fluid float-end">
                                    <a href="javascript:void(0)" class="float-end" style="padding-right : 30px" (click)="viewImage()">Click to view sample membership card</a>               
                                </div>
                            </div>
                            <!--END CHANGE-->


                        </div>
                    </div>
                </div>    
            </div>

        </div>
            
        <div class="content-panel" *ngIf="personalInfo && editMode">
            <div class="row">
                
                <div class="col-8">
                    <h2 translate>SIGN_UP.PROGRAM_IDENTIFICATION</h2>
                </div>
                <div class="col-8" *ngIf="!passIdProvided">
                        <p translate>SIGN_UP.PROGRAM_IDENTIFICATION_INFO_MSG</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12">
                            <label for="passId" class="label-with-tooltip">
                                <span translate>SIGN_UP.MEMBERSHIP_LABEL</span>
                                <tooltip [tooltip-text]="'SIGN_UP.MEMBERSHIP_ID_HELP'"></tooltip> 
                            </label>
                            <input [attr.disabled]= "editDisabled.passId || passIdProvided? '' : null"  type="text" class="form-control"
                                (blur) = "updateBlur()" [value-type]="'numeric'" [pattern] ="passIdPattern" name="passId" id="passId"  maxlength = '9' minlength = '9' [(ngModel)] = "personalInfo.passId" >                            
                            <div class= "required" *ngIf = "formErrors.passId">
                                <p *ngFor= "let error of formErrors.passId" [innerHTML]= "error | translate"></p>
                            </div>    
                        </div>
                        <div class="col-12" *ngIf="!passIdProvided">
                            <label for="fastId" class="label-with-tooltip">
                                <span translate>SIGN_UP.FAST_ID</span>
                                <tooltip [tooltip-text]="'SIGN_UP.FAST_ID_HELP'"></tooltip> 
                            </label>
                            <input [attr.disabled]= "editDisabled.fastId ? '' : null" type="text" class="form-control" (blur) = "updateBlur()"
                                name="fastId" [value-type]="'numeric'" id="fastId" [pattern] ="fastIdPattern"   maxlength = '14' minlength = '14' [(ngModel)] = "personalInfo.fastId">
                            <div class= "required" *ngIf = "formErrors.fastId">
                                <p *ngFor= "let error of formErrors.fastId" [innerHTML]= "error | translate"></p>
                            </div>
                        </div>

                        <span class="col-12" *ngIf="(!passIdProvided && personalInfo?.maxAttempt != 3) || (!passIdProvided && personalInfo?.maxDocAttempt !=3)">
                            <hr class="separator"/>
                        </span>

                        <!--Pagehint for optout HAS FAST-->
                        <div *ngIf="personalInfo.goesId && (personalInfo?.maxAttempt < 3 || personalInfo?.maxDocAttempt < 3 )" class="page-hint col-12">
                            <span><i class="far fa-lightbulb"> </i><span translate>SIGN_UP.DONT_HAVE_PASSID_HELP</span></span>
                        </div>  
                    </div>
                </div>
                
                <div class="col-12 col-md-6 position-relative"> 
                    <button class="btn btn-primary btn-sample-image" style="margin-right: 12px;" (click)="viewImage()" (keyup.enter)="viewImage()" title="{{'SIGN_UP.VIEW_MEM_CARD_TITLE' | translate}}" tabindex="0">
                        <i class="fa fa-search"></i>
                    </button>
                    <img alt="{{'IMAGE_ALT.TRUSTED_TRAVELER_MEMBERSHIP_CARD_SAMPLE' | translate}}" style="width:300px;" src="assets/images/samples/sample-preview-Membership-Card.jpg" class="img-fluid float-end">
                    <a class="float-end" href="javascript:void(0)" (click)="viewImage()" style="padding-right : 30px">Click to view sample membership card</a>               
                </div>
            </div>
        </div>


<div id="questionModal" class="modal fade" tabindex="-1" role="dialog" *ngIf="personalInfo">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
          <h4 class="modal-title w-100 text-center" translate>SIGN_UP.QUESTION_MODAL_TITLE</h4>
          <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
          <div class="content panel">
             <p translate>SIGN_UP.VERIFY_ID</p> 
             <div class="field-error" *ngIf="personalInfo?.maxAttempt === 3 || canceled">
                 <p [innerHTML] = "'SIGN_UP.TOO_MANY_ATTEMPTS' | translate"></p>
             </div>
            <div class="field-error" *ngIf="personalInfo?.maxAttempt !== 3 && personalInfo.answerSuccess === false && secSubmitted ">
                 <p translate>SIGN_UP.ANSWER_PROVIDED_INCORRECT</p>
             </div>
            <form #securityModalForm = "ngForm" novalidate> 
                <div class="row">
                    <div class="col-12 col-md-6">
                        <label for="questionDropdown">
                            <span translate>SIGN_UP.SECURITY_QUESTION</span>
                        </label>
                        <select name="questionDropdown" class="form-select" id="questionDropdown" tabindex="0" #question ="ngModel" required [(ngModel)]="selectedQuestion">
                            <option value=""></option>
                            <option *ngFor="let question of personalInfo?.questionList" [ngValue]="question.question">{{question.question}}</option>
                        </select>
                       <div class="field-error" *ngIf=" (question.errors && question.errors['required']) && secSubmitted ">
                            <p translate>ERROR_MESSAGES.GENERAL.REQUIRED</p>
                       </div>   
                    </div>
                    <div class="col-12 col-md-6">
                        <label for="secAnswer">
                            <span translate>SIGN_UP.SECURITY_ANSWER</span>
                        </label>
                        <input [value-type]="'textPattern'" class="form-control" maxlength = "200" tabindex = "0" name="secAnswer" #answer="ngModel" id="secAnswer" [(ngModel)]="selectedAnswer" trimSpaces required>
                        <div class="field-error" *ngIf=" (answer.errors && answer.errors['required']) && secSubmitted">
                            <p translate>ERROR_MESSAGES.GENERAL.REQUIRED</p>
                       </div>  
                    </div>
                </div>  
            </form>
          </div>
        
      </div>
      <div class="modal-footer">
        <div class="row row-button-group justify-content-between">
    	  	<div class="col-12 col-md-3 order-1  order-md-3">
                <button type="button" class="btn d-block w-100 btn-primary btn-nowrap" *ngIf="personalInfo?.maxAttempt !== 3 && canceled !== true"  (click)="validateSecurityQuestion()">
                    <span translate>GENERAL_REUSABLE.SUBMIT_BUTTON</span>
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn d-block w-100 btn-primary btn-nowrap" *ngIf="personalInfo?.maxAttempt === 3 || canceled === true"  (click)="continue()">
                    <span>OK</span>
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
            </div>
            <div class="col-12 col-md-5 order-2 order-md-2" >
                <button type="button" (click)="openVerificationOptionModal(); returned = true"class="btn d-block w-100 btn-default btn-nowrap">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                <span translate>SIGN_UP.CHANGE_VERIFICATION</span>
                </button>
            </div>
            <div class="col-12 col-md-3 order-3 order-md-1">
            <button type="button" (click)="cancelSecurityQuestions()"class="btn d-block w-100 btn-default" *ngIf="!canceled && personalInfo?.maxAttempt !== 3 ">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <span translate>GENERAL_REUSABLE.CANCEL_BUTTON</span>
            </button>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->



<!--linked modal-->
<div id="linkedModal" class="modal fade" tabindex="-1" role="dialog" *ngIf="personalInfo">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100 text-center" translate>SIGN_UP.PASSID_FOUND</h4>
        <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
          <div>
             <p translate>SIGN_UP.PASSID_ASSOCIATED</p> 
             <span [innerHTML]="'SIGN_UP.RELINK_MODAL_BODY'| translate : {passid: personalInfo?.passId, logingov: personalInfo?.linkedAccountEmail, current : user?.loginGovEmail}"></span> <!--TODO -->
             <!-- <span *ngIf="personalInfo.goesId" [innerHTML]="'SIGN_UP.RELINK_MODAL_BODY_GOES_ID'| translate : {passid: personalInfo?.passId, logingov: personalInfo?.linkedAccountEmail, current : user?.loginGovEmail}"></span> TODO -->
          </div>
      </div>
      <div class="modal-footer">
        <div class="row row-button-group justify-content-between flex-md-row-reverse">
    	  	<div class="col-12 col-lg-4">
          	<button type="button" class="btn d-block w-100 btn-primary btn-nowrap" (click)="confirmLink()">
              <span translate>SIGN_UP.RELINK_MODAL_YES</span>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
        	</div>
            <div class="col-12 col-lg-4">
            <button type="button" (click)="cancelRelinking()"class="btn d-block w-100 btn-default btn-nowrap">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <span translate>SIGN_UP.RELINK_MODAL_NO</span>
            </button>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<!--nochange modal-->
<div id="noChangeModal" class="modal fade" tabindex="-1" role="dialog" *ngIf="personalInfo">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
          <h4 class="modal-title w-100 text-center" translate>SIGN_UP.NO_CHANGES</h4>
          <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
          <div>
             <span [innerHTML]="'SIGN_UP.NO_CHANGES_MADE_CONTENT'| translate : {passid: personalInfo.passId, logingovemail: personalInfo.linkedAccountEmail}"></span> <!--TODO -->
          </div>
      </div>
      <div class="modal-footer justify-content-center">   
        <div class="col-12 col-md-6">
        <button type="button" class="btn d-block w-100 btn-primary" (click)="logout()">
            <span translate>SIGN_UP.CONFIRM_MODAL_LOG_OUT</span>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<!--successful change modal-->
<div id="successModal" class="modal fade" tabindex="-1" role="dialog" *ngIf="personalInfo">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
          <h4 class="modal-title w-100 text-center" translate>SIGN_UP.CONFIRMATION</h4>
          <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
          <div>
             <span [innerHTML]="'SIGN_UP.CONFIRMATION_CONTENT'| translate : {passid: personalInfo.passId, logingovemail: user?.loginGovEmail}"></span> <!--TODO -->
          </div>
      </div>
      <div class="modal-footer justify-content-center">   
        <div class="col-12 col-md-6">
        <button type="button" class="btn d-block w-100 btn-primary" (click)="continue()">
            <span translate>HOME.GOTO_DASHBOARD</span>
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<!--document info modal-->
<div id="docInfoModal" class="modal fade" tabindex="-1" role="dialog" *ngIf="personalInfo?.documentInfo">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
          <h4 class="modal-title w-100 text-center" translate>SIGN_UP.PROVIDE_DOC_INFO</h4>
          <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="content-panel" style="margin:0px; padding-top: 0px;">
            <form #docForm="ngForm" novalidate>
            <div class="row">
                <div class="col-5 offset-7 required-div">
                    <span class="float-end required" translate>GENERAL_REUSABLE.REQUIRED_FIELD</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span   [innerHTML]="'SIGN_UP.PROVIDE_DOC_CONTENT'| translate "></span>
                </div>
            </div>
            <div class="field-error" *ngIf="!personalInfo?.goesId && personalInfo?.maxDocAttempt === 3">
                <p [innerHTML]="'SIGN_UP.TOO_MANY_ATTEMPTS_BOTH' | translate"></p>
            </div>  
            <div class="row" *ngIf="personalInfo?.maxDocAttempt < 3 && personalInfo.answerSuccess === false && docSubmitted ">
                <div class="col-12 field-error">
                    <span [innerHTML]="'SIGN_UP.DOC_FAILURE_HELP_MSG' | translate"></span><a href="javascript:void(0)" (click)="provideDocFailureHelp()" (key.enter)="provideDocFailureHelp()" translate>SIGN_UP.CLICK_FOR_INSTRUCTION</a>
                </div>    
            </div>

            <div class="row">
                <!--doc type-->
                <div class="col-12 col-sm-6">
                    <label for="docType">
                        <span class="required">*</span> <span translate>SIGN_UP.DOCUMENT_TYPE</span>
                    </label>
                    <select class="form-select" name="docType" id="docType" #docType="ngModel" [(ngModel)]="personalInfo.documentInfo.docType" (change)="personalInfo.documentInfo.docNumber = ''; personalInfo.documentInfo.countryOfIssuance = ''" required>
                        <option value=""></option>
                        <option *ngFor="let type of docTypes" [ngValue]= "type" translate>APPLICATION.DOCUMENTS.TYPE.{{type}}</option>
                    </select>
                    <div class="field-error" *ngIf=" (docType.errors && docType.errors['required']) && docSubmitted ">
                        <p translate>ERROR_MESSAGES.GENERAL.REQUIRED</p>
                    </div>  
                </div>
            </div>
            <div class="sub-level-1" *ngIf="personalInfo?.documentInfo?.docType">
                <div class="row sub-level">
                    <!--doc country of issuane-->
                    <div class="col-12 col-sm-8">
                        <label for="docCountry">
                            <span class="required">*</span> <span translate>SIGN_UP.COUNTRY_OF_ISSUANCE</span>
                        </label>
                        <select class="form-select" name="docCountry" #docCountry="ngModel" id="docCountry" [(ngModel)]="personalInfo.documentInfo.countryOfIssuance" required>
                            <option value=""></option>
                            <option *ngFor="let country of countries" [ngValue]= "country.isoCountryCode" translate> GENERAL_REUSABLE.COUNTRY.{{country.isoCountryCode}} </option>
                        </select>
                        <div class="field-error" *ngIf=" (docCountry.errors && docCountry.errors['required']) && docSubmitted ">
                            <p translate>ERROR_MESSAGES.GENERAL.REQUIRED</p>
                        </div>  
                    </div>
                    <!--doc number-->
                    <div class="col-12 col-sm-8">
                        <label for="docNumber">
                            <span class="required">*</span> <span translate>SIGN_UP.DOCUMENT_NUMBER</span>
                        </label>
                        <input [value-type]="textPattern" #docNum="ngModel" class="form-control" name="docNumber" id="docNumber" [(ngModel)]="personalInfo.documentInfo.docNumber" maxlength="35" trimSpaces required>
                        <div class="field-error" *ngIf=" (docNum.errors && docNum.errors['required']) && docSubmitted ">
                            <p translate>ERROR_MESSAGES.GENERAL.REQUIRED</p>
                        </div>  
                    </div>  
                </div>
            </div>
            </form>
        </div>
      </div>
      <div class="modal-footer">   
        <div class="row row-button-group w-100 justify-content-between">
    	  <div class="col-12 col-md-3 order-1 order-md-3">
          	<button type="button" class="btn d-block w-100 btn-primary btn-nowrap" (click)="validateDocumentInfo(personalInfo);">
              <span translate>GENERAL_REUSABLE.SUBMIT_BUTTON</span>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
            </div>
            
            <div class="col-12 col-md-4 order-2" > <!---->
                <button type="button" (click)="openVerificationOptionModal(); returned = true"class="btn d-block w-100 btn-default btn-nowrap" *ngIf="personalInfo?.maxAttempt !== 3 && personalInfo?.goesId">
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                    <span translate>SIGN_UP.CHANGE_VERIFICATION</span>
                </button>
            </div>
            <div class="col-12 col-md-3 order-3 order-md-1" >
                <button type="button" (click)="cancelRelinking()" class="btn d-block w-100 btn-default btn-nowrap" *ngIf="!personalInfo?.goesId">
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                    <span translate>GENERAL_REUSABLE.CANCEL_BUTTON</span>
                </button>
                <button type="button" (click)="cancelSecurityQuestions()" class="btn d-block w-100 btn-default btn-nowrap" *ngIf="personalInfo?.goesId">
                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                    <span translate>GENERAL_REUSABLE.CANCEL_BUTTON</span>
                </button>
            </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<!--verification option modal-->
<div id="verificationOptionModal" class="modal fade" tabindex="-1" role="dialog" *ngIf="personalInfo">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
          <h4 class="modal-title w-100 text-center" translate>SIGN_UP.CHOOSE_VERIFICATION</h4>
          <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div class="content-panel" style="margin:0px; padding-top: 0px;">
            <div class="row">
                <div class="col-5 offset-7 required-div">
                    <span class="float-end required" translate>GENERAL_REUSABLE.REQUIRED_FIELD</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p translate>SIGN_UP.PLEASE_CHOOSE</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <!--BOTH OPTIONS LEFT-->
                    <div  *ngIf="personalInfo?.maxAttempt !== 3 && personalInfo?.maxDocAttempt !== 3 && returned">
                        <p [innerHTML]="'SIGN_UP.UNABLE_TO_VERIFY_2_OPTIONS' | translate"></p>
                    </div>
                    <!--ONE OPTION LEFT-->
                    <div class="field-error" *ngIf="( (personalInfo?.maxAttempt === 3 && personalInfo?.maxDocAttempt !== 3) || (personalInfo?.maxAttempt !== 3 && personalInfo?.maxDocAttempt === 3) ) &&  secSubmitted">
                        <p [innerHTML]="'SIGN_UP.UNABLE_TO_VERIFY_1_OPTION' | translate"></p>
                        <p translate>SIGN_UP.UNABLE_TO_VERIFY</p>
                    </div>  
                    <div  *ngIf="personalInfo?.maxAttempt === 3  && ! secSubmitted">
                        <p [innerHTML]="'SIGN_UP.UNABLE_TO_VERIFY_1_OPTION' | translate"></p>
                    </div>
                    <!--NO OPTION LEFT-->
                    <div class="field-error" *ngIf="personalInfo?.maxAttempt === 3 && personalInfo?.maxDocAttempt === 3">
                        <p [innerHTML]="'SIGN_UP.TOO_MANY_ATTEMPTS_BOTH' | translate"></p>
                    </div>  
                </div>
            </div>

            <div class="row">
                <!--CHOOSE SECURITY QUESTION-->
                <div class="col-sm-7 col-12">
                <label>
                    <span class="required">*</span><span translate>SIGN_UP.SELECT_ONE</span>
                </label>
                </div>
                <div class="col-sm-7 col-12">
                    <label for="verificationToggleSec" [attr.disabled]= "personalInfo?.maxAttempt === 3 ? '' : null" id="verificationToggleSec_label" class="btn btn-radio" style="width : 300px;" [ngClass]="{'active' : verificationToggle === 'SECURITY'}" tabindex="0" role="button"
                        (keyup.enter)="personalInfo?.maxAttempt === 3 ? null : verificationToggle = 'SECURITY'" >
                        <span aria-hidden="true"></span>
                        <input type="radio" name="verificationToggleSec" [attr.disabled]= "personalInfo?.maxAttempt === 3 ? '' : null" value= "SECURITY" id="verificationToggleSec" [(ngModel)] = "verificationToggle">{{'SIGN_UP.ANSWER_SECURITY' | translate}}
                    </label>    
                    <p *ngIf="personalInfo?.maxAttempt !== 3" class="subtext" [innerHTML]="'SIGN_UP.ATTEMPTS_REMAINING' | translate : {attempts : getAttempts(personalInfo?.maxAttempt)}"></p>
                    <p *ngIf="personalInfo?.maxAttempt === 3" class="subtext" [innerHTML]="'SIGN_UP.NO_ATTEMPTS_REMAINING' | translate"></p>
                </div>

                <!--CHOOSE DOCUMENT INFO-->
                <div class="col-sm-7 col-12">
                    <label for="verificationToggleDoc" [attr.disabled]= "personalInfo?.maxDocAttempt === 3 ? '' : null" id="verificationToggleDoc_label" class="btn btn-radio" style="width : 300px;" [ngClass]="{'active' : verificationToggle === 'DOC'}" tabindex="0" role="button"
                        (keyup.enter)="personalInfo?.maxDocAttempt === 3 ? null : verificationToggle = 'DOC'" >
                        <span aria-hidden="true"></span>
                        <input type="radio" name="verificationToggleDoc" [attr.disabled]= "personalInfo?.maxDocAttempt === 3 ? '' : null" value= "DOC" id="verificationToggleDoc" [(ngModel)] = "verificationToggle">{{'SIGN_UP.ANSWER_DOC' | translate}}
                    </label>  
                </div>
            </div>
            <br />
            <div class="row" *ngIf="!(personalInfo?.maxAttempt === 3 && personalInfo?.maxDocAttempt === 3)">
                <div class="col-12">
                    <p translate>SIGN_UP.QUESTIONS_ASSOCIATED</p>
                </div>
            </div>
        </div>
      </div>
      <div class="modal-footer">   
        <div class="row row-button-group justify-content-between w-100" *ngIf="!(personalInfo?.maxAttempt === 3 && personalInfo?.maxDocAttempt === 3)">
    	  	<div class="col-12 col-md-6 order-1 order-md-2">
                <button type="button" class="btn d-block w-100 btn-primary" (click)="chooseVerification(verificationToggle)">
                <span translate>GENERAL_REUSABLE.CONTINUE</span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
        	</div>
            <div class="col-12 col-md-5 order-2 order-md-1">
                <button type="button" (click)="cancelSecurityQuestions()" class="btn d-block w-100 btn-default "> <!--TODO UPDATE CANCEL LOGIC -->
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                <span translate>GENERAL_REUSABLE.CANCEL_BUTTON</span>
                </button>
          </div>
        </div>
        <div class="row row-button-group w-100" *ngIf="personalInfo?.maxAttempt === 3 && personalInfo?.maxDocAttempt === 3">
            <div class="col-12 col-md-6">
          	<button type="button" class="btn d-block w-100 btn-primary" (click)="continue()">
              <span translate>HOME.GOTO_DASHBOARD</span>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
            </div>
        </div>
       </div> 
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->



<div id="accountModal" class="modal fade" tabindex="-1" role="dialog" *ngIf="personalInfo">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
          <h4 class="modal-title w-100 text-center" translate>SIGN_UP.CONFIRM_MODAL_TITLE</h4>
          <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <span [innerHTML]="'SIGN_UP.CONFIRM_MODAL_BODY_1' | translate : {logingovemail : user?.loginGovEmail}"></span>
                    <span [innerHTML]="'SIGN_UP.CONFIRM_MODAL_NAME' | translate : {firstName : personalInfo.firstName, lastName :personalInfo.lastName }"></span>
                    <span [innerHTML]="'SIGN_UP.CONFIRM_MODAL_DOB' | translate : {dob : personalInfo?.dateOfBirth?.getFormattedString()}"></span>
                    <span *ngIf="personalInfo.passId" [innerHTML]="'SIGN_UP.CONFIRM_MODAL_MEMBER' | translate : {membership : personalInfo.passId}"></span>
                    <span *ngIf="personalInfo.fastId" [innerHTML]="'SIGN_UP.CONFIRM_MODAL_FAST_ID' | translate : {fastId : personalInfo.fastId}"></span>
                    <!-- <span *ngIf="personalInfo.goesId" [innerHTML]="'SIGN_UP.CONFIRM_MODAL_GOES_ID' | translate : {goesId : personalInfo.goesId}"></span> -->
                    <span *ngIf="personalInfo.emailAddress" [innerHTML]="'SIGN_UP.CONFIRM_MODAL_EMAIL' | translate : {email : personalInfo.emailAddress}"></span>
                    <p></p>
                    <span [innerHTML]="'SIGN_UP.CONFIRM_MODAL_NOT_CORRECT' | translate"></span>
                    <ul>
                        <li><a href="#" (click)="logout()" translate>SIGN_UP.CONFIRM_MODAL_LOG_OUT</a><span translate>SIGN_UP.CONFIRM_MODAL_TO_RETURN</span></li>
                        <li><span translate>SIGN_UP.CONFIRM_MODAL_FROM_HOME</span>
                    </ul>
                </div>
            </div>  
      </div>
      <div class="modal-footer">
        <div class="row row-button-group w-100 justify-content-between flex-md-row-reverse">
    	  	<div class="col-12 col-md-6">
          	<button type="button" class="btn d-block w-100 btn-primary" (click)="confirm()">
              <span translate>MODAL.CONFIRM</span>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
        	</div>
            <div class="col-12 col-md-5">
            <button type="button" data-bs-dismiss="modal" class="btn d-block w-100 btn-default">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              <span translate>GENERAL_REUSABLE.CANCEL_BUTTON</span>
            </button>
          </div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<div id="whatNextModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!--<button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>-->
        <h4 class="modal-title" translate>SIGN_UP.WHATS_NEXT_TITLE</h4>
      </div>
      <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <p translate>SIGN_UP.WHATS_NEXT_CONTENT_P1</p>
                </div>
                <div class="col-12">
                    <span *ngIf="personalInfo?.maxAttempt !== 3" [innerHTML]="'SIGN_UP.WHATS_NEXT_CONTENT_P2' | translate : {numsecurity : getAttempts(personalInfo?.maxAttempt) }"></span>
                    <span *ngIf="personalInfo?.maxAttempt === 3" [innerHTML]="'SIGN_UP.WHATS_NEXT_CONTENT_P2_ALT' | translate"></span>
                </div>
            </div>  
      </div>
      <div class="modal-footer">
        <div class="row row-button-group">
    	  	<div class="col-12 col-lg-6 col-lg-push-5 col-md-offset-1">
          	<button type="button" class="btn d-block w-100 btn-primary" (click)="continue()">
              <span translate>HOME.GOTO_DASHBOARD</span>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
        	</div>
        </div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div id="doc-failure-Instr" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" translate>SIGN_UP.DOC_FAILURE_HELP_TITLE</h4>
            </div>
            <div class="modal-body">
              <p [innerHTML]="'SIGN_UP.DOC_FAILURE_HELP_TEXT' | translate"></p>
            </div>
            <div class="modal-footer" >
                <div class="col-lg-4 offset-lg-4">
                    <button type="button" class="btn d-block w-100 btn-primary" data-bs-dismiss="modal">   
                      <span translate>MODAL.BUTTON_CLOSE</span>
                    </button>
                </div>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

            
         <div class="row row-button-group">
                <div class="col-12 col-md-3 col-lg-2 ms-auto">
                    <button id="next" class="btn btn-nav btn-primary d-block w-100" type="button" tabindex="0" (click)="submitForm()" (keyup.enter)= "submitForm()">
                        <span translate>GENERAL_REUSABLE.SAVE_BUTTON</span>
                    </button>
                </div>
                <div class="col-12" style="text-align: right; color: #666">
                    <i translate>{{nextCaption}}</i>
                </div>
            </div>    
        </form>
    </div>
</div>
