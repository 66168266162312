<div class="content-panel">
    <div class="under-maintenance">

        <img id="underMaintenace" alt="{{'IMAGE_ALT.UNDER_MAINTENANCE' | translate}}" src="assets/images/maintenance-icon.png">
    
        <h4> Under construction</h4>
        <p>This feature is currently under construction.</p>
        <p> Please check back soon.</p>
    </div>
</div>
