<div class="page-content">
    <div class="row">
        <div class="col-12 progress-top-level centered">
            <h1 translate>INTERVIEW_CONFIRMATION.TITLE</h1>
            <p class="divider d-none d-md-block"></p>
        </div>
    </div>

    <div class="container">

        <div class="row" *ngIf="appointmentModel">
            <div class="col-12">

                <!-- Interview Confirmation -->
                <div id="print-area" class="content-panel interview-review">
                    <div class="row" ><!-- *ngIf="appointmentModel" -->
                        <div class="col-12">

                            <h2 translate>INTERVIEW_CONFIRMATION.INFORMATION</h2>

                            <table class="payment-table table table-striped">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="title-row" translate>INTERVIEW_CONFIRMATION.SUMMARY</td>
                                    </tr>
                                    <tr *ngIf="!appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.ENROLLMENT_CENTER</td>
                                        <td>{{appointmentModel.locationName}}</td>
                                    </tr>
                                    <tr *ngIf="appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.ENROLLMENT_CENTER</td>
                                        <td translate>SELECT_INTERVIEW_TYPE.REMOTE_VIA_ZOOM</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.DATE</td>
                                        <td><span translate>{{dayOfWeek}}</span>, <span translate>{{month}}</span> {{appointmentModel?.startDateObject | date: 'dd, y'}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.TIME</td>
                                        <td>
                                            {{appointmentModel.startTime}} <!-- todo: local time zone info -->
                                            <span *ngIf="appointmentModel.startDateObject.getHours() >= 12" translate>INTERVIEW_CONFIRMATION.PM</span>
                                            <span *ngIf="appointmentModel.startDateObject.getHours() < 12" translate>INTERVIEW_CONFIRMATION.AM</span>
                                            <span> {{appointmentModel.tzData}} ({{moment.tz(appointmentModel.tzData).format('z')}})</span>
                                            <a tabindex="0" href="javascript:void(0)" *ngIf="appointmentModel.eventTypeId == 1 || appointmentModel.eventTypeId == 2 || appointmentModel.eventTypeId == 3 || appointmentModel.eventTypeId == 8" (click)="goToReschedule($event)" (keyup.enter)="goToReschedule($event)"><i class="fas fa-edit"></i> <span translate>INTERVIEW_CONFIRMATION.RESCHEDULE_APPT</span></a>
                                            <a tabindex="0" href="javascript:void(0)" *ngIf="appointmentModel.eventTypeId == 1 || appointmentModel.eventTypeId == 2 || appointmentModel.eventTypeId == 3 || appointmentModel.eventTypeId == 8" (click)="goToCancel($event)" (keyup.enter)="goToCancel($event)"><i class="far fa-calendar-times"></i> <span translate>INTERVIEW_CONFIRMATION.CANCEL_APPT</span></a>
                                            <a tabindex="0" href="javascript:void(0)" *ngIf="appointmentModel.eventTypeId != 1 && appointmentModel.eventTypeId != 2 && appointmentModel.eventTypeId != 3 && appointmentModel.eventTypeId != 7 && appointmentModel.eventTypeId != 8" (click)="goToSchedule($event)" (keyup.enter)="goToSchedule($event)"><i class="far fa-calendar-times"></i> <span translate>ENROLLMENT.SCHEDULE_INTERVIEW</span></a>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.ADDRESS</td>
                                        <td><img *ngIf="!appointmentLocationModel" src='assets/images/mini-preloader.gif' width="16" height="16" alt="{{'IMAGE_ALT.LOADING_INDICATOR' | translate}}">
                                            {{appointmentLocationModel?.address}}<span *ngIf="appointmentLocationModel && appointmentLocationModel?.addressAdditional">,</span>
                                            {{appointmentLocationModel?.addressAdditional}}
                                            {{appointmentLocationModel?.city}}<span *ngIf="appointmentLocationModel">,</span>
                                            {{appointmentLocationModel?.state}}
                                            {{appointmentLocationModel?.postalCode}}
                                            {{appointmentLocationModel?.countryCode}}
                                        </td>
                                    </tr>
                                    <tr *ngIf="!appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.PHONE</td>
                                        <td>{{appointmentModel.locationPhone}}</td>
                                    </tr>
                                    <tr *ngIf="!appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.DIRECTIONS</td>
                                        <td>{{appointmentModel.locationDirections}}</td>
                                    </tr>
                                    <tr *ngIf="!appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.MAP</td>
                                        <td>
                                            <img id="imageMap" alt="{{'INTERVIEW_CONFIRMATION.MAP' | translate | lowercase}}" class="img-fluid img-interview-map" [class.hidden]="appointmentLocationImage.length <= 1">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.SPECIAL_INSTRUCTIONS</td>
                                        <td *ngIf="!appointmentModel?.remote">{{appointmentLocationModel?.specialInstructions}}</td>
                                        <td *ngIf="appointmentModel?.remote" translate>INTERVIEW_CONFIRMATION.REMOTE_INSTRUCTIONS
                                            <br/><br/>
                                                <p><span translate>INTERVIEW_CONFIRMATION.ADDITIONAL_REMOTE_INSTRUCTIONS_TOPIC</span></p>
                                                <ul>
                                                    <li><span translate>INTERVIEW_CONFIRMATION.ADDITIONAL_REMOTE_INSTRUCTIONS_1</span></li>
                                                    <li><span translate>INTERVIEW_CONFIRMATION.ADDITIONAL_REMOTE_INSTRUCTIONS_2</span></li>
                                                    <li><span translate>INTERVIEW_CONFIRMATION.ADDITIONAL_REMOTE_INSTRUCTIONS_3</span></li>
                                                </ul>                                        
                                        </td>
                                    </tr>
                                    <tr *ngIf="appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.REMOTE_URL</td>
                                        <td><a class="remote-url" target="_blank" rel="noopener" href="{{appointmentModel?.remoteUrl}}">{{appointmentModel?.remoteUrl}}</a></td>
                                    </tr>
                                </tbody>
                            </table>


                            <table class="payment-table table table-striped">
                                <tbody>
                                    <tr>
                                        <td class="title-row" colspan="2" translate>INTERVIEW_CONFIRMATION.APPLICATION</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.PROGRAM</td>
                                        <td>{{programName}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.APPLICATION_ID</td>
                                        <td>{{applicationMetadata.applicationId}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.PASSID</td>
                                        <td>{{user.membershipOrPassId}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="divider" *ngIf="documentsToBring.length > 0"></div>

                    <div class="row" *ngIf="documentsToBring.length > 0">
                        <div class="col-12">
                            <h4 translate>INTERVIEW_CONFIRMATION.INSTRUCTIONS.INTRO</h4>
                            <ul>
                                <li *ngFor="let document of documentsToBring">
                                    <span translate>APPLICATION.DOCUMENTS.TYPE.{{document.type}}</span> - <span translate>GENERAL_REUSABLE.COUNTRY.{{document.countryOfIssuance.isoCountryCode}}</span>
                                    <span class="text-muted" *ngIf="document.citizenshipProofInd && !document.admissibilityProofInd">(<em translate>INTERVIEW_CONFIRMATION.INSTRUCTIONS.PROOF_CIT</em>)</span>
                                    <span class="text-muted" *ngIf="!document.citizenshipProofInd && document.admissibilityProofInd">(<em translate>INTERVIEW_CONFIRMATION.INSTRUCTIONS.PROOF_ADMIS</em>)</span>
                                    <span class="text-muted" *ngIf="document.citizenshipProofInd && document.admissibilityProofInd">(<em translate>INTERVIEW_CONFIRMATION.INSTRUCTIONS.PROOF_CIT_ADMIS</em>)</span>
                                </li>
                                <li *ngIf="driversLicense" translate>INTERVIEW_CONFIRMATION.INSTRUCTIONS.DRIVER_LICENSE</li>
                                <li [innerHTML]="'INTERVIEW_CONFIRMATION.INSTRUCTIONS.YOUR_PASSID' | translate"></li>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>

        </div><!-- end of row -->

        <!-- print/cancel -->
        <div class="row row-button-group flex-row">
            <div class="col-12 col-md-4 col-lg-3 order-md-2 ms-auto">
                <button class="btn btn-primary btn-nav d-block w-100" id="btnDone" (click)="onDone($event)"><span translate>GENERAL_REUSABLE.DONE</span></button>
                <div class="col-12" style="text-align: right; color: #666">
                    <i translate>SIGN_UP.RETURN_TO_DASHBOARD</i>
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-3 order-md-1">
                <button id="btnPrint" class="btn btn-default d-block w-100 btn-nav" (click)="openPrintView($event)" (keyup.enter)="openPrintView($event)" ><span translate>GENERAL_REUSABLE.PRINT_BUTTON</span></button>
            </div>
        </div> <!-- end button group -->

    </div><!-- end of container -->

</div>
