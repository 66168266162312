import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component ({
    templateUrl: 'privacy-act.component.html',
    standalone: false
})

export class PrivacyActComponent {

    constructor() {
    }

    public closePrivacyAct() : void {
        window.close();
    }
}
