import { Injectable } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';
var phoneUtil = PhoneNumberUtil.getInstance();


@Injectable()
export class PhoneService {



    constructor(){}

    isValid(number : string, countryCode : string) : boolean {
        try {
            let phoneNumber : any = phoneUtil.parse(number, countryCode);
            return phoneUtil.isValidNumber(phoneNumber);
        } catch(error) {
            //console.log(error);
            return true;
        }
    }

}
