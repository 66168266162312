<div id="{{id}}" class="modal modal-lg fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100 text-center" translate>HOME.ABOUT.CHECK_INTERVIEW_AVAILABILITY</h4>
        <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="chk-interview-ins">
          <span translate>HOME.CHECK_INTERVIEW_AVAILABILITY_INSTR</span>
        </div>
        <div id="chkIntLinks" class="text-center">
          <a class="btn btn-info chk-interview-btn" target="_blank" rel="noopener noreferrer" href="https://www.tsa.gov/precheck/enrollment-centers">TSA PreCheck<sup>&reg;</sup></a>
          <a class="btn btn-info chk-interview-btn" id="chkUPAvail" target="_blank" [attr.href]="schedulerViewLocationsUrl + 'UP'">{{ globalEntryName }}</a>
          <a class="btn btn-info chk-interview-btn" id="chkNHAvail" target="_blank" [attr.href]="schedulerViewLocationsUrl + 'NH'">{{ nexusName }}</a>
          <a class="btn btn-info chk-interview-btn" id="chkSHAvail" target="_blank" [attr.href]="schedulerViewLocationsUrl + 'SH'">{{ sentriName }}</a>
          <a class="btn btn-info chk-interview-btn" id="chkFNAvail" target="_blank" [attr.href]="schedulerViewLocationsUrl + 'FS'">{{ fsName }}</a>
          <a class="btn btn-info chk-interview-btn" id="chkFSAvail" target="_blank" [attr.href]="schedulerViewLocationsUrl + 'FN'">{{ fnName }}</a>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
  	  	<div class="col-12 col-lg-4">
        	<button type="button" class="btn d-block w-100 btn-primary" data-bs-dismiss="modal" translate>MODAL.BUTTON_CLOSE</button>
      	</div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
