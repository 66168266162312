import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SETTINGS } from '../app.constants';
import { environment } from '../../environments/environment';

@Component ({
    templateUrl: 'under-maintenance.component.html',
    standalone: false
})

export class UnderMaintenanceComponent implements OnInit {

    constructor(private router : Router) {

    }

    ngOnInit(){
        if(!environment.underMaintenance){
            this.router.navigate(['']);
        }
    }

}
