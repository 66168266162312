<div class="page-content">
  <div class="row">
    <div class="col-lg-12 progress-top-level centered">
      <h1 translate>PURCHASE_SUMMARY.TITLE</h1>
      <p class="divider d-none d-md-block"></p>
    </div>
  </div>
  <div class="container">

    <div class="content-panel">
      <div class="row">
        <div class="col-8">
          <h2 translate>PURCHASE_SUMMARY.CONTENT_HEADER</h2>
          <p translate>PURCHASE_SUMMARY.CONTENT_HEADER_INFO</p>
        </div>
        <div class="col-4 required-div">
          <span class="float-end required" translate>GENERAL_REUSABLE.REQUIRED_FIELD</span>
        </div>
      </div>

      <!-- Begin left side -->
      <div class="row shopping-cart match-my-cols">
        <div class="col-12 col-md-12 col-lg-8">
          <div class="row">
            <div class="col-12">
              <h4 *ngIf="payAction === 'ALL'">{{'PURCHASE_SUMMARY.COMPLETED_APPLICATIONS_HEADER' | translate }}({{ applicationsSummary?.length }})</h4>
              <h4 *ngIf="payAction === 'B'">{{'PURCHASE_SUMMARY.BALANCE_DUE_HEADER' | translate }}</h4>
              <h4 *ngIf="payAction === 'C'">{{'PURCHASE_SUMMARY.PROGRAM_CONV_HEADER' | translate }}</h4>
              <hr>
            </div>
          </div>

            <div class="row cart-item" *ngFor="let applicationSummary of applicationsSummary; let i = index">
              <div class="col-12 col-md-12 col-lg-12">
                <div class="row" *ngIf="payAction === 'ALL'">
                  <div class="col-12">
                    <a href="javascript:void(0)" (click)="removeApplication(applicationSummary.id)"><i class="far fa-trash-alt" aria-hidden="true"></i>
                        <span>{{'PURCHASE_SUMMARY.CANCEL_DELETE_LINK' | translate}}</span>
                      </a>

                    <a href="javascript:void(0)" (click)="removeABTC(applicationSummary.id)" *ngIf="applicationSummary.abtcAddon">
                      <i class="far fa-trash-alt" aria-hidden="true"></i>
                      <span>{{'PURCHASE_SUMMARY.REMOVE_ABTC_LINK' | translate}}</span>
                    </a>
                  </div>
                </div>
              <!-- </div>


              <div class="col-xs-12 cart-data"> -->


                <div class="row cart-data">
                  <div class="col-12 col-md-4 col-lg-4">
                    <div class="img-container">
                      <img alt="{{ applicationSummary.programLogoAlt | translate }}" src="{{ applicationSummary.programLogo }}" />
                    </div>
                  </div>
                  <div class="col-12 col-md-6 col-lg-5">
                    <span class="application-type">{{ getProgramHeader(applicationSummary) }}</span>
                    <span *ngIf="applicationSummary.appType === 'VE'">{{'PURCHASE_SUMMARY.QUANTITY_LABEL' | translate}} : <span style="font-weight: bold">{{ getQuantity(applicationSummary) }} {{'PURCHASE_SUMMARY.VEHICLES' | translate}}</span></span><br>
                    <span>{{'PAYMENT_HISTORY.APPLICATION_ID' | translate}} :</span> <span><b>{{ applicationSummary.id }}</b></span>
                  </div>
                  <div class="col-12 col-md-2 col-lg-3 price-column">
                    <span class="price">{{ getPerUnitAmount(applicationSummary) | currency :'USD':'symbol' }}</span>
                    <span class="price-per-vehicle" *ngIf="applicationSummary.appType === 'VE'" translate>{{ 'PURCHASE_SUMMARY.PER_VEHICLE' }}</span>
                  </div>
                </div>

                <div class="row abtc-item" *ngIf="applicationSummary.abtcAddon">
                  <div class="col-12 col-md-4 apec-image" style="text-align: right;">
                    <img style="width : 75%;" alt="{{ 'IMAGE_ALT.APEC_LOGO' | translate }}" src="assets/images/logos/logo-apec-business-travel-card.png" alt="APEC business travel card logo" />
                  </div>
                  <div class="col-12 col-md-5 apec-label">
                    <h4 translate>PROGRAM_SELECTION.SELECT_A_PROGRAM.APEC_LABEL</h4>
                  </div>
                  <div class="col-12 col-md-3 price" style="text-align: right;">
                    <span>{{ applicationSummary.abtcFee | currency :'USD':'symbol' }}</span>
                  </div>
                </div>
                  
              </div>
            </div>

            <!-- cancel button -->
            <div class="row row-button-group mobile-hide">
                <div class="col-12 col-md-12 col-lg-3">
                    <button id="back" class="btn btn-nav btn-default d-block w-100" (click)="cancel()" (keyup.enter)="cancel()" name="back">
                        <span translate>GENERAL_REUSABLE.CANCEL_BUTTON</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- End left side -->

        <div class="col-12 col-md-12 col-lg-4 cart-total">
          <div class="row">
            <div class="col-12">
              <h4 translate>PURCHASE_SUMMARY.PAYMENT_SUMMARY_PANE</h4>
              <hr>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="application-total">
                <span class="total-price">{{ totalFee | currency : 'USD' :'symbol' }}</span>
              </div>

              <div  *ngIf="totalFee > 0 || isSESOnly" class="fee-certification btn-checkbox">
                <input type="checkbox" name="paymentNotice" id="paymentNotice" [(ngModel)]="paymentNotice" required>
                <label for="paymentNotice" class="">
                    <div><span class="required">*</span>{{ getFeeConfirmationDisclaimer() | translate }}</div>
                </label>
              </div>

              <div *ngIf="totalFee > 0" class="pay-button-part">
                <button  class="btn d-block w-100 btn-primary" type="submit" (click)="submitForm()" (keyup.enter)= "submitForm()">
                  <span translate>PURCHASE_SUMMARY.PAY_NOW_BUTTON</span>
                </button>
                <span [innerHTML]="'PURCHASE_SUMMARY.PAY_NOW_NAV_HINT' | translate"></span>
              </div>

              <div *ngIf="totalFee === 0" class="pay-button-part">
                <button class="btn d-block w-100 btn-primary" type="submit" (click)="submitForm()" (keyup.enter)= "submitForm()">
                  <span translate>DASHBOARD.SUBMIT_APP</span>
                </button>
                <span [innerHTML]="'PURCHASE_SUMMARY.PROCEED_TO_DASHBOARD' | translate"></span>
              </div>

              <div *ngIf="payAction === 'ALL' && totalFee > 0" class="pay-later-block">

                <div>
                  <!-- <span translate>PURCHASE_SUMMARY.NOT_READY_INFO_1</span> <b><span translate>GENERAL_REUSABLE.DAY.{{dueDateDay}}</span>, <span translate>GENERAL_REUSABLE.MONTH.{{dueDateMonth}}</span> <span>{{dueDateDate}}</span>, <span>{{dueDateYear}}</span></b><span translate>PURCHASE_SUMMARY.NOT_READY_INFO_2</span> -->
                  <span translate>PURCHASE_SUMMARY.NOT_READY_INFO_1</span> <b><span>&nbsp;{{translatedDueDate}}</span></b><span translate>PURCHASE_SUMMARY.NOT_READY_INFO_2</span> 
                </div>
                
                <div class="italic"><span [innerHTML]="'PURCHASE_SUMMARY.PAYMENT_NOTE' | translate "></span> </div>

                <div>
                  <a tabindex="0" (keyup.enter)="showPayLaterFAQ()" (click)="showPayLaterFAQ()" href="javascript:void(0)">
                    <span>{{'PURCHASE_SUMMARY.PAY_LATER_LINK' | translate}}</span>
                  </a>
                </div>
                <div>
                  <a tabindex="0" (keyup.enter)="showAlreadyPaidFAQ()" (click)="showAlreadyPaidFAQ()" href="javascript:void(0)">
                    <span>{{'PURCHASE_SUMMARY.ALREADY_PAID' | translate}}</span>
                  </a>
                </div>
                <div  class="cancel-button-part mobile-reveal">  
                    <button id="back" class="btn btn-nav btn-default d-block w-100" (click)="cancel()" (keyup.enter)="cancel()" name="back">
                        <span translate>GENERAL_REUSABLE.CANCEL_BUTTON</span>
                    </button>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<info-modal [id]="'mdl-already-paid'" [title]="'FAQ.ALREADY_PAID_TITLE'" [buttonText]="'MODAL.BUTTON_OK'" [translate-src]="'FAQ.ALREADY_PAID_TEXT'"></info-modal>
