import { OnInit, Component, Output, EventEmitter } from "@angular/core";

declare var $: any;
declare function unescape(s: string): string;

@Component({
    selector: 'ttp-comparison-tool',
    templateUrl: 'comparison-tool.component.html',
    standalone: false
})

export class ComparisonToolComponent implements OnInit {

  public citizenship = '';
  public isCommercialTrucker: string = 'no';
  public isCommercialTruckerAllowed: boolean = true;
  public hasPassport: string = '';

  public internationalTravelCount: number = 0;
  public domesticTravelCount: number = 0;
  public canadaTravelCount: number = 0;
  public mexicoTravelCount: number = 0;

  public commercialTruckerSelect = '';

  public params: any = [];

  public intTravelInSixMonths = '';
  public showIntTravelQuestion = false;

  @Output() runComparison = new EventEmitter < any > ();
  @Output() resetComparison = new EventEmitter < any > ();

  ngOnInit(): void {}

  public submitClicked() {

    if (!this.citizenship) {
      this.citizenship = 'US';
      this.updateShowSixMonthCheck();
    }
    let choices = {
      citizenship: this.citizenship,
      isCommercialTrucker: this.isCommercialTrucker,
      hasPassport: this.hasPassport,
      internationalTravelCount: this.internationalTravelCount,
      domesticTravelCount: this.domesticTravelCount,
      canadaTravelCount: this.canadaTravelCount,
      mexicoTravelCount: this.mexicoTravelCount,
      intTravelInSixMonths: this.intTravelInSixMonths
    };

    this.runComparison.emit(choices);
  }

  public reset() {
    this.citizenship = '';
    this.isCommercialTrucker = 'no';
    this.hasPassport = '';
    this.internationalTravelCount = 0;
    this.domesticTravelCount = 0;
    this.canadaTravelCount = 0;
    this.mexicoTravelCount = 0;
    this.isCommercialTruckerAllowed = true;
    this.intTravelInSixMonths = '';
    this.showIntTravelQuestion = false;

    this.resetComparison.emit(true);
  }

  public toggleInfoIcon(id: string) {
    $('#'+id).next().toggleClass('active');
    if ($('#'+id).attr('aria-expanded') == false) {
      $('#'+id).attr('aria-expanded', 'true');
    } else {
      $('#'+id).attr('aria-expanded', 'false');
    }
  }

  public checkCommercialTruckerAllowed() {
    if (!this.citizenship ||
          (this.citizenship ==='US' || this.citizenship === 'USLPR' ||
           this.citizenship ==='CA' || this.citizenship === 'CALPR' ||
           this.citizenship === 'MX')) {
      this.isCommercialTruckerAllowed = true;
      return true;
    }

    this.isCommercialTrucker = 'no';
    this.isCommercialTruckerAllowed = false;
    return false;
  }

  public updateShowSixMonthCheck() {
    if ((this.citizenship && this.citizenship ==='US' || this.citizenship === 'USLPR') && this.internationalTravelCount >=2) {
      this.showIntTravelQuestion = true;
    } else {
      this.intTravelInSixMonths = '';
      this.showIntTravelQuestion = false;
    }
  }
}
