import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../app/common/services/auth.service';
import { ErrorHandlerService } from '../app/common/services/error-handler.service';
import { SpinnerService } from '../app/shared/spinner/index';
import { ModalService } from './shared/modals/modal.service';
import { User } from '../app/common/models/user';
import { environment } from '../environments/environment';

@Component({
    selector: 'bypass',
    template: ``,
    standalone: false
})
export class BypassComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    private spinner: SpinnerService,
    private router: Router,
    private modalService: ModalService) { }

  ngOnInit(): void {
    if (environment.BYPASS_ENABLED) {
      this._bypass();
    }
    else {
      this.router.navigate(['/']);
    }
  }

  private _bypass() {
    this.spinner.show();

    let route = '';

    this.route.queryParams.subscribe((params: Params) => {
      let dev_uuid = params['dev_uuid'];
      let email = params['email'];

 //     if (!this.authService.isUserLoggedIn()) {
        this.authService.requestBypassToken(dev_uuid, email).subscribe(res => {
          if (res) {
            //console.log(res);
            let email = res['email'];
            let uuid = res['sub'];

            this.authService.loadUser(res['id_token'], uuid, email).subscribe(res => {
              let user = new User();
              if (res) {
                user = this.authService.getUser();
                this.authService.startTimeoutWarningTimer();
              }

              if (user.name) {
                route = '/dashboard';
              } else if (user.userId) {
                route = '/account-profile/' + user.userId;
              } else {
                route = '';
              }

              this.spinner.hide();
              this.router.navigate([route]);
            });
          }
        });
//      }
    });
  }
}
