import { GoesDate } from "./date.model";

export class LegalGuardian {

    lastName: string;
    firstName: string;
    middleName: string;
    dateOfBirth: string;
    dob: GoesDate;          // For the UI
    gender: string;
    passId: number;
    applicationId: number;
    idType: string;

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof LegalGuardian) {
                this.lastName = jsonObject.lastName;
                this.firstName = jsonObject.firstName;
                this.middleName = jsonObject.middleName;
                this.dateOfBirth = jsonObject.dateOfBirth;
                this.gender = jsonObject.gender;
                this.passId = jsonObject.passId;
                this.idType = jsonObject.idType;
            } else {
                this.lastName = jsonObject.lastName || '';
                this.firstName = jsonObject.firstName || '';
                this.middleName = jsonObject.middleName || '';
                if (jsonObject.dateOfBirth) {
                    this.dateOfBirth = jsonObject.dateOfBirth;
                    this.dob = new GoesDate(null, null, null, jsonObject.dateOfBirth);
                } else {
                    this.dob = new GoesDate();
                }
                this.gender = jsonObject.gender || '';
                this.passId = jsonObject.passId;
                this.applicationId = jsonObject.applicationId;
                this.idType = jsonObject.idType;
            }
        } else {
            this.lastName = '';
            this.firstName = '';
            this.middleName = '';
            this.dateOfBirth = '';
            this.dob = new GoesDate();
            this.gender = '';
            this.passId = null;
            this.applicationId = null;
            this.idType = null;
        }
    }
}