import { EnhancedModel } from './enhanced.model';

export class ProgramFee extends EnhancedModel {
    programCode: string;
    feeCode: string;
    amount: number;
    minAge: number;
    unit: number;

    constructor(obj: any) {
      super();
      if (obj instanceof ProgramFee) {
        this.mapClone(obj);
      }
      else if(obj instanceof Object) {
        // this must be a json object let's deserialize it
        this.mapJson(obj);
      } else {
        throw new Error("You must specify the correct parameter");
      }
    }

    public clone() {
        let cloned:ProgramFee = new ProgramFee(this);
        return cloned;
    }

    protected mapClone(programFee:ProgramFee) {
        this.amount = programFee.amount;
        this.feeCode = programFee.feeCode;
        this.programCode = programFee.programCode;
        this.minAge = programFee.minAge;
    }

    protected mapJson(jsonObject: any) {
        if (jsonObject.hasOwnProperty('amount')) {
          this.amount = jsonObject.amount;
        }

        if (jsonObject.hasOwnProperty('feeCode')) {
          this.feeCode = jsonObject.feeCode;
        }

        if (jsonObject.hasOwnProperty('minAge')) {
          this.minAge = jsonObject.minAge;
        }

        if (jsonObject.hasOwnProperty('programCode')) {
          this.programCode = jsonObject.programCode;

        }
        if (jsonObject.hasOwnProperty('unit')) {
          this.unit = jsonObject.unit;
        }
    }

}
