
import {throwError as observableThrowError,  Subject ,  Observable ,  Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from '../../app/common/models/user';

import { ErrorHandlerService } from '../../app/common/services/error-handler.service';

import { ReconsiderationRequest } from '../common/models/reconsideration-request.model';
import { Reconsideration } from '../common/models/reconsideration.model';
import { ReconsiderationAttachment } from '../common/models/reconsideration-attachment.model';
import { DashboardService } from '../common/services/dashboard.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
let $: any;

@Injectable()
export class ReconsiderationService {
  public get authHttp(): HttpClient {
    return this._authHttp;
  }
  public set authHttp(value: HttpClient) {
    this._authHttp = value;
  }

  private authorizedUserSource = new Subject<User>();

  // observable streams
  authorizedUser$ = this.authorizedUserSource.asObservable();
  token = '';
  logoutParams = '';
  reconRequest: Reconsideration;
  reconRequestAttachments: Array<ReconsiderationAttachment>;

  constructor(
    // tslint:disable-next-line:variable-name
    private _authHttp: HttpClient,
    private errorHandler: ErrorHandlerService,
    private dashboard: DashboardService
  ) {

  }

  public saveRequestData(formData: FormData, request: ReconsiderationRequest, userId: string): Observable<any> {

    const requestURI = environment.uriEndpoint + environment.apiVersion;

    return this.authHttp.post(
        environment.uriEndpoint + environment.apiVersion + 'goesapp/users/' + userId + '/reconsiderations/', formData).pipe(
      map((response: any) => {
        this.dashboard.bannerMessage =  response.id;
        this.dashboard.bannerStatus = 'success';
        return response;
      }),
      catchError(error =>
        this.errorHandler.handleError(error)
      ));
  }

  private extractData(res: Response) {
    const body = res;
    console.error(body);
    this.dashboard.bannerMessage = 'OMBUDSMAN.BANNER_ERR_MSG_RECONSIDERATION';
    this.dashboard.bannerStatus = 'failure';
    return body || {};
  }

  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    this.dashboard.bannerMessage = 'OMBUDSMAN.BANNER_ERR_MSG_RECONSIDERATION';
    this.dashboard.bannerStatus = 'failure';
    return observableThrowError(error.message || error);
  }

  getReconsiderationRequest(userId: string, ReconsiderationId: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
    };

    if (!userId || !ReconsiderationId) {
    }
    const requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/reconsiderations/' + ReconsiderationId;

    return this.authHttp.get(requestURI).pipe(
      map((res: any) => {
        const data = res;
        if (this.errorHandler.checkErrorCode(data)) {
          this.reconRequest = this.initReconsideration(data);
          return this.reconRequest;
        }
        return null;
      }),
      catchError(error => this.errorHandler.handleError(error)));

  }

  getReconsiderationAttachments(userId: string, ReconsiderationId: string): Observable<any> {
    const requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/reconsiderations/attachment/' + ReconsiderationId;

    return this.authHttp.get(requestURI).pipe(
      map((res: any) => {
        const jasonData = res;
        const attachmentData: ReconsiderationAttachment[] = new Array<ReconsiderationAttachment>();
        if (this.errorHandler.checkErrorCode(jasonData)) {
          res.forEach((eachAttachment: any) => {
            attachmentData.push(new ReconsiderationAttachment(eachAttachment));
          });
          return attachmentData;
        }
        return attachmentData;
      }),
      catchError(error => this.errorHandler.handleError(error)));

  }

  private initReconsideration(res: any): Reconsideration {
    const reconRequest = new Reconsideration(res);
    return reconRequest;
  }
  private initReconsiderationAttachments(res: any): Array<ReconsiderationAttachment> {
    const reconRequestAttachment = new Array<ReconsiderationAttachment>(res);
    return reconRequestAttachment;
  }

  downloadAttachment(userId: string, token: string, fileName: string, objKey: string, contentType: string): Observable<any> {
    const options = {
      responseType: 'blob' as 'json'
    };

    const requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/reconsiderations/getAttachment/' + objKey;

    return this.authHttp.get(requestURI, options).pipe(
      map((res: Blob) => {
        return res;
      }),
      catchError(error => this.errorHandler.handleError(error)));
  }

}

