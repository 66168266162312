import { Route } from '@angular/router';
import { GetStartedComponent } from './getstarted.component';
import { HomeComponent } from './home.component';

export const HomeRoutes: Route[] = [
  {
    path: '',
    component: HomeComponent
  },
  {
	path: 'getstarted',
	component: GetStartedComponent,
	children: []
  }
];
