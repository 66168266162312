import { Injectable } from '@angular/core';
import { Utils } from '../utils/utils';
import { TimeDay } from '../models/time-day.model';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable()
export class CalendarService {

    protected timeDates:Array<TimeDay> = new Array<TimeDay>();

    constructor(private spinnerService: SpinnerService) { }

    public getDayDisplayName(date:Date):string {
        let translateTag:string = 'GENERAL_REUSABLE.MEDIUM_DAY.';
        let weekDayNumber:number = date.getDay();
        switch (weekDayNumber) {
            case 0:
                translateTag += 'SUNDAY';
                return translateTag;
            case 1:
                translateTag += 'MONDAY';
                return translateTag;
            case 2:
                translateTag += 'TUESDAY';
                return translateTag;
            case 3:
                translateTag += 'WEDNESDAY';
                return translateTag;
            case 4:
                translateTag += 'THURSDAY';
                return translateTag;
            case 5:
                translateTag += 'FRIDAY';
                return translateTag;
            case 6:
                translateTag += 'SATURDAY';
                return translateTag;
            default:
                throw new Error('Date object does not have the corresponding day');
        }
    }

    /**
     * This is a function that returns a day array in a pyramid shape
     *  [previous_date, focus_date, next_date]
     * Must be odd number greater than 2
     * @param focusDate Date object
     * @param pyramidSize greater than 2 and odd
     */
    public getTimeDatesArrayPyramid(focusDate:Date, pyramidSize:number):Array<TimeDay> {
        this.spinnerService.show();
        let halfArraySize:number = Math.floor(pyramidSize/2);

        while(this.timeDates.length > 0) {
            this.timeDates.pop();
        }

        let leftSideQuantity:number = halfArraySize;
        let rightSideQuantity:number = 1;

        if(pyramidSize > 2 && pyramidSize % 2 === 1) {
            while(this.timeDates.length < pyramidSize) {
                if(leftSideQuantity > 0) {
                    let pastDate:Date = Utils.getPastDay(focusDate, leftSideQuantity);
                    let pastDateWrapper:TimeDay = new TimeDay(pastDate, false);
                    this.timeDates.push(pastDateWrapper);
                    leftSideQuantity--;
                    if(leftSideQuantity === 0) {
                        // tip of pyramid
                        let focusDateWrapper:TimeDay = new TimeDay(Utils.getCleanDate(focusDate), true);
                        this.timeDates.push(focusDateWrapper);
                    }
                } else if(rightSideQuantity < pyramidSize + 1) {
                    let futureDate:Date = Utils.getFutureDay(focusDate, rightSideQuantity);
                    let futureDateWrapper:TimeDay = new TimeDay(futureDate, false);
                    this.timeDates.push(futureDateWrapper);
                    rightSideQuantity++;
                }
            }
        } else {
            this.spinnerService.hide();
            throw new Error('arraySize must be greater than 2 and an odd number');
        }
        this.spinnerService.hide();
        return this.timeDates;

    }

}
