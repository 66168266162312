import { ProgramFee } from './program-fee.model';
import { EnhancedModel } from './enhanced.model';

export class ApplicationSummary extends EnhancedModel {
	id: string;
	programCode: string;
	abtcAddon: boolean;
	fee: number;
	abtcFee: number;
	certificationDate : Date;
	source : string = 'GOES';
	feeList : ProgramFee[];
	appType: string = 'IE';
	addFeeInd : string;
    preConvertProgramCode : string = '';

  // For display purposes
  programLogoAlt: string;
  programLogo: string;
  programName: string;


	constructor(obj:any) {
			super();
			if (obj instanceof ApplicationSummary) {
					this.mapClone(obj);
			}
			else if(obj instanceof Object) {
					// this must be a json object let's deserialize it
					this.mapJson(obj);
			}
			else {
					throw new Error("You must specify the correct parameter");
			}
	}

	public clone() {
			let cloned:ApplicationSummary = new ApplicationSummary(this);
			return cloned;
	}

	protected mapClone(application:ApplicationSummary) {
			this.id = application.id;
			this.appType = application.appType;
			this.programCode = application.programCode;
			this.source = application.source;
			this.abtcAddon = application.abtcAddon;
			this.abtcFee = application.abtcFee;
			this.certificationDate = application.certificationDate;
			this.feeList = application.feeList;
			this.fee = application.fee;
			this.addFeeInd = application.addFeeInd;
            this.preConvertProgramCode = application.preConvertProgramCode;
	}

	protected mapJson(jsonObject: any) {
			if (jsonObject.hasOwnProperty('applicationId')) {
					this.id = jsonObject.applicationId;
			}
			if (jsonObject.hasOwnProperty('appType')) {
					this.appType = jsonObject.appType;
			}
			if (jsonObject.hasOwnProperty('programCode')) {
					this.programCode = jsonObject.programCode;
			}
			if (jsonObject.hasOwnProperty('source')) {
					this.source = jsonObject.source;
			}
			if (jsonObject.hasOwnProperty('abtcAddon')) {
					this.abtcAddon = jsonObject.abtcAddon;
			}
			if (jsonObject.hasOwnProperty('fee')) {
					this.fee = jsonObject.fee;
			}
			if (jsonObject.hasOwnProperty('feeList')) {
					this.feeList = jsonObject.feeList;
			}
			if (jsonObject.hasOwnProperty('certificationDate')) {
					this.certificationDate = new Date(jsonObject.certificationDate);
			}
			if (jsonObject.hasOwnProperty('addFeeInd')) {
					this.addFeeInd = jsonObject.addFeeInd;
			}
			if (jsonObject.hasOwnProperty('abtcFee')) {
					this.abtcFee = jsonObject.abtcFee;
			}
            if (jsonObject.hasOwnProperty('preConvertProgramCode')) {
					this.preConvertProgramCode = jsonObject.preConvertProgramCode;
			}
	}
}
