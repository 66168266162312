export class TimeDay {

    private _isFocus:boolean;
    public get isFocus():boolean {
        return this._isFocus;
    }
    public set isFocus(value:boolean) {
        this._isFocus = value;
    }

    private _date:Date = null;
    public get date():Date {
        return this._date;
    }
    public set date(value:Date) {
        this._date = value;
    }

    constructor(date:Date, isFocus:boolean) {
        this.date = date;
        this.isFocus = isFocus;
    }

}
