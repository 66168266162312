
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../common/services/auth.service';
import { SpinnerService } from '../shared/spinner';
@Component({
    selector: 'app-gec',
    template: ``,
    standalone: false
})
export class GECRoutingComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private spinner: SpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.login();
  }

  private login() {

    this.spinner.show();

    this.route.queryParams.subscribe((qparams: Params) => {
          const lang = qparams.lang;
          const passId = qparams.passId;

          if (!passId) {
            this.router.navigate(['/']).then(() => {
              this.spinner.hide();
            });
          } else {

            this.authService.reLogin().subscribe(res => {
              if (res) {
                const user = this.authService.getUser();
                if (user && user.name) {
                  if (user.membershipOrPassId !== passId) {
                    this.router.navigate(['/']).then(() => {
                      this.spinner.hide();
                    });
                  } else {
                    this.router.navigate(['/dashboard']).then(() => {
                      this.spinner.hide();
                    });
                  }
                } else {
                  this.router.navigate(['/']).then(() => {
                    this.spinner.hide();
                  });
                }
              } else {
                this.router.navigate(['/']).then(() => {
                  this.spinner.hide();
                });
              }
            });
        }
    });
  }
}
