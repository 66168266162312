import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ScheduleService } from '../../common/services/schedule.service';
import { ModalService } from '../../shared/modals/modal.service';
import { DashboardService } from '../../common/services/dashboard.service';
import { AuthService } from '../../common/services/auth.service';
import { SchedulerModel } from '../../common/models/appointments/scheduler.model';
import { GESAppointmentModel } from '../../common/models/appointments/ges-appointment.model';
import { ApplicationMetadata } from '../../common/models/application-metadata.model';
import { ObservableQueue } from '../../common/utils/observable-queue';
import { ObservableQueueFunction } from '../../common/utils/observable-queue-function';
import { User } from '../../common/models/user';
import { SETTINGS } from '../../app.constants';

/**
 * This component handles all incoming requests from the external scheduler and
 * tries to sync up data with GES and then redirect back to the dashboard
 */
@Component({
    templateUrl: 'schedule.component.html',
    standalone: false
})
export class ScheduleComponent implements OnInit {

    protected schedulerModel: SchedulerModel = new SchedulerModel();
    public mode: string;
    protected observableQueue: ObservableQueue = new ObservableQueue();
    public showSpinner = true;
    public programName: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private scheduleService: ScheduleService,
                private dashboardService: DashboardService,
                private authService: AuthService,
                private modalService: ModalService) {

                }

    ngOnInit() {
        this.setup();
    }

    private setup(){

        this.route.queryParams.subscribe( (params: any) => {

            this.schedulerModel.status = params.status;
            this.schedulerModel.userId = params.zone;
            this.schedulerModel.token = params.token;
            this.schedulerModel.appointmentStartTimestamp = params.appointmentStartTimestamp;
            this.schedulerModel.appointmentEndTimestamp = params.appointmentEndTimestamp;
            this.schedulerModel.appointmentLocationId = params.locationId;
            this.schedulerModel.applicationId = params.appCode;
            this.schedulerModel.reason = params.reason;
            this.schedulerModel.appointmentLocationName = params.locationName;

            this.schedulerModel.tzData = params.tzData;

            if ( this.schedulerModel.status === SchedulerModel.STATUS_WILL_SCHEDULE &&
                this.schedulerModel.isLoginDataSet() &&
                this.schedulerModel.isWillCreateNewAppointmentDataSet() ){

                //
                this.mode = SchedulerModel.START;
                this.createAppointment();

            } else if (this.schedulerModel.status === SchedulerModel.STATUS_WILL_RESCHEDULE &&
                this.schedulerModel.isLoginDataSet() &&
                this.schedulerModel.isWillUpdateAppointmentDataSet() ){

                //
                this.mode = SchedulerModel.START;
                this.updateAppointment();

            } else if ( this.schedulerModel.status === SchedulerModel.STATUS_ABORTED &&
                this.schedulerModel.isLoginDataSet() ){

                //
                this.mode = SchedulerModel.START_ABORT;
                this.abortAppointment();

            } else if (this.schedulerModel.status === SchedulerModel.STATUS_SESSION) {
                this.authService.logOut();
            } else {
                this.mode = SchedulerModel.PARAM_ERROR;
                this.showSpinner = false;
            }

        });

    }

    /**
     * CREATE APPOINTMENT
     */
    private createAppointment(){

        this.observableQueue.stop();
        this.observableQueue.nextEmitter.subscribe(
            (res: any) => {
                this.mapMemberIdIfExists(res);
                this.observableQueue.next();
            }
        );

        this.observableQueue.add(new ObservableQueueFunction(this.authService.reLogin, [], this.authService));
        this.observableQueue.add(new ObservableQueueFunction(this.dashboardService.getDashboard,
            [this.schedulerModel.userId], this.dashboardService));

        const gesAppointment: GESAppointmentModel = new GESAppointmentModel();

        this.observableQueue.completeEmitter.subscribe(

            (res: any) => {

                const applicationMetadataArray: Array<ApplicationMetadata> = this.dashboardService.getActiveApplications();
                const matchedApplicationMetaData: ApplicationMetadata = this.matchApplicationMetaData(applicationMetadataArray);
                let hasErrors = false;

                if (matchedApplicationMetaData != null && matchedApplicationMetaData.programs.length > 0) {

                    gesAppointment.applicationCode = Number(this.schedulerModel.applicationId);
                    gesAppointment.startTimestamp = this.schedulerModel.appointmentStartTimestamp;
                    gesAppointment.endTimestamp = this.schedulerModel.appointmentEndTimestamp;

                    gesAppointment.locationId = Number(this.schedulerModel.appointmentLocationId);

                    // This is the enrollment center name that will be sent to GES
                    if (gesAppointment.locationId === SETTINGS.REMOTE_LOCATION_ID) {
                        gesAppointment.enrollmentCenterName = 'Remote';
                    } else {
                        gesAppointment.enrollmentCenterName = this.schedulerModel.appointmentLocationName;
                    }

                    gesAppointment.passId = this.authService.getUser().membershipOrPassId;
                    gesAppointment.programId = matchedApplicationMetaData.programs[0];
                    gesAppointment.type = SchedulerModel.GES_STATUS_SCHEDULED;
                    gesAppointment.eventTypeId = SchedulerModel.APPOINTMENT_TYPE_CREATE_ID;
                    gesAppointment.rescheduledEventTypeId = null;

                    gesAppointment.source = matchedApplicationMetaData.source;
                    gesAppointment.goesUserId = this.schedulerModel.userId;

                    gesAppointment.type = SchedulerModel.GES_STATUS_SCHEDULED;
                    gesAppointment.lastUpdatedBy = this.authService.getUser().name;

                    if (this.schedulerModel.tzData) {
                        gesAppointment.tzData = this.schedulerModel.tzData;
                    }

                    this.scheduleService.createAppointment(gesAppointment).subscribe(
                        (res: any) => {
                            this.showSpinner = false;
                            if (res){
                                this.scheduleService.focusedAppointmentApp = matchedApplicationMetaData;
                                this.scheduleService.showDashboardNotification = true;
                                this.scheduleService.notificationMessage = 'APPOINTMENT.CREATED';
                                this.goToDashboard();
                            } else {
                                hasErrors = true;
                                this.mode = SchedulerModel.SERVICE_ERROR;
                                this.showSpinner = false;
                            }
                        },
                        (error: any) => {
                            hasErrors = true;
                            this.mode = SchedulerModel.SERVICE_ERROR;
                            this.showSpinner = false;
                        }
                    );

                } else {
                    hasErrors = true;
                }

                if (hasErrors){
                    this.mode = SchedulerModel.SERVICE_ERROR;
                    this.showSpinner = false;
                }

            }

        );

        this.observableQueue.errorEmitter.subscribe (
            (res: any) => {
                // todo:
                this.observableQueue.stop();
                this.mode = SchedulerModel.SERVICE_ERROR;
                this.showSpinner = false;
            }
        );

        this.observableQueue.start();

    }

    /**
     * UPDATE APPOINTMENT
     */
    private updateAppointment(){

        this.observableQueue.stop();
        this.observableQueue.nextEmitter.subscribe(
            (res: any) => {
                this.mapMemberIdIfExists(res);
                this.observableQueue.next();
            }
        );

        this.observableQueue.add(new ObservableQueueFunction(this.authService.reLogin, [], this.authService));
        const appCodePieces = this.schedulerModel.applicationId.split('-');
        this.schedulerModel.applicationId = appCodePieces[1];
        this.schedulerModel.source = appCodePieces[0];
        this.observableQueue.add(new ObservableQueueFunction(this.scheduleService.getAppointment,
            [this.schedulerModel.applicationId, this.schedulerModel.source, this.schedulerModel.token], this.scheduleService));
        this.observableQueue.add(new ObservableQueueFunction(this.dashboardService.getDashboard,
            [this.schedulerModel.userId], this.dashboardService));

        const gesAppointment: GESAppointmentModel = new GESAppointmentModel();

        this.observableQueue.completeEmitter.subscribe(

            (res: any) => {

                const applicationMetadataArray: Array<ApplicationMetadata> = this.dashboardService.getActiveApplications();
                const matchedApplicationMetaData: ApplicationMetadata = this.matchApplicationMetaData(applicationMetadataArray);
                let hasErrors = false;

                if (matchedApplicationMetaData != null && matchedApplicationMetaData.programs.length > 0
                    && this.scheduleService.appointmentModel != null) {

                    gesAppointment.applicationCode = Number(this.schedulerModel.applicationId);
                    gesAppointment.startTimestamp = this.schedulerModel.appointmentStartTimestamp;
                    gesAppointment.endTimestamp = this.schedulerModel.appointmentEndTimestamp;

                    gesAppointment.locationId = Number(this.schedulerModel.appointmentLocationId);

                    // This is the enrollment center name that will be sent to GES
                    if (gesAppointment.locationId === SETTINGS.REMOTE_LOCATION_ID) {
                        gesAppointment.enrollmentCenterName = 'Remote';
                    } else {
                        gesAppointment.enrollmentCenterName = this.schedulerModel.appointmentLocationName;
                    }

                    gesAppointment.appointmentId = this.scheduleService.appointmentModel.id;

                    gesAppointment.passId = this.authService.getUser().membershipOrPassId;
                    gesAppointment.programId = matchedApplicationMetaData.programs[0];
                    gesAppointment.type = SchedulerModel.GES_STATUS_RESCHEDULED;
                    gesAppointment.eventTypeId = SchedulerModel.APPOINTMENT_TYPE_CREATE_ID;
                    gesAppointment.rescheduledEventTypeId = SchedulerModel.APPOINTMENT_TYPE_RESCHEDULE_ID;
                    gesAppointment.cancellationReason = this.schedulerModel.reason;

                    gesAppointment.source = matchedApplicationMetaData.source;
                    gesAppointment.goesUserId = this.schedulerModel.userId;
                    gesAppointment.lastUpdatedBy = this.authService.getUser().name;

                    // for other sections to fill out
                    // gesAppointment.cancellationReason;
                    // gesAppointment.type = ''; see getters for GES

                    //
                    gesAppointment.type = SchedulerModel.GES_STATUS_SCHEDULED;
                    // console.log('GES Appointment to UPDATE ', gesAppointment);

                    if (this.schedulerModel.tzData) {
                        gesAppointment.tzData = this.schedulerModel.tzData;
                    }

                    this.scheduleService.updateAppointment(gesAppointment).subscribe(
                        (res: any) => {
                            this.showSpinner = false;
                            if (res){
                                this.scheduleService.focusedAppointmentApp = matchedApplicationMetaData;
                                this.scheduleService.showDashboardNotification = true;
                                this.scheduleService.notificationMessage = 'APPOINTMENT.UPDATED';
                                this.goToDashboard();
                            } else {
                                hasErrors = true;
                                this.mode = SchedulerModel.SERVICE_ERROR;
                                this.showSpinner = false;
                            }
                        },
                        (error: any) => {
                            hasErrors = true;
                            this.mode = SchedulerModel.SERVICE_ERROR;
                            this.showSpinner = false;
                        }
                    );

                } else {
                    hasErrors = true;
                }

                if (hasErrors){
                    this.mode = SchedulerModel.SERVICE_ERROR;
                    this.showSpinner = false;
                }

            }

        );

        this.observableQueue.errorEmitter.subscribe (
            (res: any) => {
                // todo:
                this.observableQueue.stop();
                this.mode = SchedulerModel.SERVICE_ERROR;
                this.showSpinner = false;
            }
        );

        this.observableQueue.start();
    }

    /**
     * ABORT
     */
    private abortAppointment(){
        //
        this.observableQueue.stop();
        this.observableQueue.nextEmitter.subscribe(
            (res: any) => {
                this.mapMemberIdIfExists(res);
                this.observableQueue.next();
            }
        );

        this.observableQueue.add(new ObservableQueueFunction(this.authService.reLogin, [], this.authService));
        this.observableQueue.add(new ObservableQueueFunction(this.dashboardService.getDashboard,
            [this.schedulerModel.userId], this.dashboardService));

        // after essential data is retreived, then let's update GES
        this.observableQueue.completeEmitter.subscribe(
            (res: any) => {
                const applicationMetadataArray: Array<ApplicationMetadata> = this.dashboardService.getActiveApplications();
                const matchedApplicationMetaData: ApplicationMetadata = this.matchApplicationMetaData(applicationMetadataArray);
                this.goToDashboard();
            }
        );

        this.observableQueue.errorEmitter.subscribe(
            (res: any) => {
                this.observableQueue.stop();
                this.mode = SchedulerModel.SERVICE_ERROR;
                this.showSpinner = false;
            }
        );

        this.observableQueue.start();

    }

    /**
     * Set user must occur in order to have full authService
     */
    private mapMemberIdIfExists(res: any) {
        if (res.hasOwnProperty('userId') && res.hasOwnProperty('dateOfBirth')) {
            const user = new User();
            if (res.hasOwnProperty('membershipId')){
                // manual remap in case it still exists on this prop
                res.membershipOrPassId = res.membershipId;
                user.membershipOrPassId = res.membershipOrPassId;
            } else {
                user.membershipOrPassId = '';
            }

            user.userId = res.userId;
            user.username = res.emailAddress || res.userId;
            user.dateOfBirth = res.dateOfBirth;
            user.name = res.firstName + ' ' + res.lastName;
            user.name = user.name.replace('undefined', '').trim();
            user.userId = user.userId.replace('undefined', '').trim();

            this.authService.setUser(user);

        }
    }

    /**
     * Matches application to use for appointment
     * @param applicationMetadataArray matched application metadata
     */
    private matchApplicationMetaData(applicationMetadataArray: Array<ApplicationMetadata>): ApplicationMetadata{

        let matchedApplicationMetaData: ApplicationMetadata = null;

        for (let i = 0; i < applicationMetadataArray.length; i++) {
            const app: ApplicationMetadata = applicationMetadataArray[i];
            if (app.applicationId === this.schedulerModel.applicationId){
                matchedApplicationMetaData = app;
                this.scheduleService.applicationMetadata = app;
                break;
            }
        }

        return matchedApplicationMetaData;

    }

    //
    // ROUTES
    //
    public returnHome(event: any) {
        this.router.navigate(['/']);
    }
    //
    protected goToDashboard(){
        this.router.navigate(['/dashboard']);
    }
    //
    protected goToAppointmentSummary(){
        this.router.navigate(['/interview-summary']);
    }
    protected goToCancelAppointment(){
        this.router.navigate(['/cancellation-summary']);
    }

}
