<div class="page-content">
    <div class="row">
        <div class="col-12 progress-top-level centered">
            <h1><span translate>PROGRESS.SCHEDULE_INTERVIEW</span>: {{programName}}</h1>
            <p class="divider d-none d-md-block"></p>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12" style="text-align: center;" *ngIf="showSpinner">
                <div class="spinner-embedded"></div>
            </div>
            <div class="col-lg-12" style="text-align: center;" *ngIf="mode == 'start'">
                <h3 translate>SCHEDULING_PROGRESS.FINALIZING</h3>
            </div>
            <div class="col-lg-12" style="text-align: center;" *ngIf="mode == 'start abort'">
                <h3 translate>SCHEDULING_PROGRESS.FINALIZING_ABORTED</h3> 
            </div>
            <div class="col-lg-12" style="text-align: center;" *ngIf="mode == 'service error'">
                <h3 translate>SCHEDULING_PROGRESS.SERVICE_ERROR</h3>
                <p><span translate>SCHEDULING_PROGRESS.RETURN</span> <a (click)="returnHome($event)" translate>SCHEDULING_PROGRESS.HOME</a>.</p>
            </div>
            <div class="col-lg-12" style="text-align: center;" *ngIf="mode == 'params error'">
                <h3 translate>SCHEDULING_PROGRESS.ERROR_PARAMS</h3>
                <p><span translate>SCHEDULING_PROGRESS.RETURN</span> <a (click)="returnHome($event)" translate>SCHEDULING_PROGRESS.HOME</a>.</p>
            </div>
        </div>
    </div>
</div>
