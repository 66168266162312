<div class="page-content">
    <div class="row">
        <div class="col-12 progress-top-level centered">
            <h1><span translate>PROGRESS.SCHEDULE_INTERVIEW</span>: {{programName}}</h1>
            <p class="divider d-none d-md-block"></p>
        </div>
    </div>

    <div class="container">

        <div class="row">
            <div class="col-lg-12">
                <div class="content-panel interview-review">
                    <div class="row">
                        <div class="col-8">
                            <h2 translate>INTERVIEW_CANCEL.CANCEL.TITLE</h2>
                        </div>
                        <div class="col-4 required-div">
                            <span class="float-end required" translate>GENERAL_REUSABLE.REQUIRED_FIELD</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="appointmentModel">
                        <div class="col-12">
                            
                            <div class="page-hint">
                                <i class="far fa-lightbulb"></i>
                                <span translate>INTERVIEW_CANCEL.CANCEL.INFO</span> 
                            </div>
                            
                            <table class="payment-table table table-striped">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="title-row" translate>INTERVIEW_CANCEL.CANCEL.APPOINTMENT_SUMMARY</td>
                                    </tr>
                                    <tr *ngIf="!appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.ENROLLMENT_CENTER</td>
                                        <td>{{appointmentModel.locationName}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.DATE</td>
                                        <td>{{appointmentModel.startDateObject | date: 'EEE, MMM dd, y'}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.TIME</td>
                                        <td>
                                            {{appointmentModel.startTime}} 
                                            <span *ngIf="appointmentModel?.startDateObject?.getHours() >= 12" translate>INTERVIEW_CONFIRMATION.PM</span>
                                            <span *ngIf="appointmentModel?.startDateObject?.getHours() < 12" translate>INTERVIEW_CONFIRMATION.AM</span>
                                            <span> {{appointmentModel.tzData}} ({{moment.tz(appointmentModel.tzData).format('z')}})</span>
                                            <a tabindex="0" href="javascript:void(0)" (click)="goToReschedule($event)" (keyup.enter)="goToReschedule($event)"><i class="fas fa-edit"></i> <span translate>INTERVIEW_CONFIRMATION.RESCHEDULE_APPT</span></a> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.PHONE</td>
                                        <td>{{appointmentModel.locationPhone}}</td>
                                    </tr>
                                    <tr *ngIf="!appointmentModel?.remote">
                                        <td translate>INTERVIEW_CONFIRMATION.DIRECTIONS</td>
                                        <td>{{appointmentModel.locationDirections}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="payment-table table table-striped">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="title-row" translate>INTERVIEW_CANCEL.APPLICATION</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CANCEL.CANCEL.APPLICANT_NAME</td>
                                        <td>{{user.name}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.PASSID</td>
                                        <td>{{user.membershipOrPassId}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.PROGRAM</td>
                                        <td>{{programName}}</td>
                                    </tr>
                                    <tr>
                                        <td translate>INTERVIEW_CONFIRMATION.APPLICATION_ID</td>
                                        <td>{{applicationMetadata.applicationId}}</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6 cancel-reason">
                            <form>
                                <label for="cancellationReason"><span class="required">*</span> <span translate>INTERVIEW_CANCEL.REASON</span></label>
                                <textarea *ngIf="!isIE" [value-type]="'textPattern'" class="form-control" name="cancellationReason" rows="4" required [(ngModel)]="cancelReason" maxlength="2000"></textarea>
                                <input *ngIf="isIE" id="cancellationReason" [value-type]="'textPattern'"  tabindex="0" class="form-control" name="cancellationReason" maxlength="2000"  [(ngModel)] = "cancelReason">
                            </form>
                            <div class="field-error" *ngIf="hasCancelReasonError">
                                <p translate>INTERVIEW_CANCEL.REQUIRED_EXPLAIN</p>
                            </div>
                        </div>
                    </div>

                </div> <!-- end content panel -->

            </div>
        </div>

        <!-- print/cancel -->
        <div class="row row-button-group">
            <div class="col-12 col-md-4 col-lg-3 ms-auto order-md-2">
                <button class="btn btn-primary btn-nav d-block w-100" (click)="onSubmit($event)"><span translate>INTERVIEW_CANCEL.CANCEL_BUTTON</span> <i class="fa fa-check"></i></button>
                <div class="col-12" style="text-align: right; color: #666">
                    <i translate>SIGN_UP.RETURN_TO_DASHBOARD</i>
                </div>
            </div>
            <div class="col-12 col-nd-4 col-lg-3 order-md-1">
                <button class="btn btn-default d-block w-100 btn-nav" (click)="onBack($event)"><span translate>GENERAL_REUSABLE.BACK_BUTTON</span></button>
            </div>
        </div> <!-- end button group -->

    </div> <!-- end container -->
</div> <!-- end page content -->