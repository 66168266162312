declare var $: any;

export class Utils {

    static scrollUp(id?:string) {
		var target = 0;
		var body = $('html, body');
		if(id) {
			target = $('#' + id).offset().top;
		}
        //body.scrollTop(target);
		body.stop().animate({scrollTop: target}, '500', 'swing', function() {/**/});
		body.focus();
	}

	/**
	 * Compares two dates
	 * 
	 * if Date1 occures before Date2 (or Date1 is null) returns 1
	 * if Date2 occures before Date1 (or Date2 is null) returns -1
	 * if Date1 and Date2 are equal (or both are null) returns 0
	 * 
	 * Does not take into account time
	 */
	static CompareDates(Date1: Date, Date2: Date): number {
		let _Date1: Date = null;
		let _Date2: Date = null;
		if (Date1) {
			_Date1 = new Date(Date1.getFullYear(), Date1.getMonth(), Date1.getDate(), 0, 0, 0, 0);
		}
		if (Date2) {
			_Date2 = new Date(Date2.getFullYear(), Date2.getMonth(), Date2.getDate(), 0, 0, 0, 0);
		}

		if (_Date1 === null && _Date2 === null) {
			return 0;
		}
		if (_Date1 === null) {
			return 1;
		}
		if (_Date2 === null) {
			return -1;
		}

		if (_Date1.getFullYear() === _Date2.getFullYear() &&
			_Date1.getMonth() === _Date2.getMonth() && _Date1.getDate() === _Date2.getDate()) {
			return 0;
		}
		if (_Date1 > _Date2) {
			return -1;
		} else {
			return 1;
		}
	}

	static getShortISOYearMonthDay(date:Date) {
		let year: string = date.getFullYear().toString();
		let month: string;
		if (date.getMonth() + 1 < 10) {
			month = '0' + (date.getMonth() + 1);
		} else {
			month = (date.getMonth() + 1).toString();
		}
		let day: string;
		if (date.getDate() < 10) {
			day = '0' + date.getDate();
		} else {
			day = date.getDate().toString();
		}
		let str:string = year + '-' + month + '-' + day;
		return str;
	}

	/**
	 * Time string, expecting in format specified in param
	 * @param time (08:30)
	 */
	static extractHourFromTimeString(time:string):number {
		if(time && time.length > 4) {
            let pieces:Array<string> = time.split(':');
            if(pieces.length > 1) {
                let hour:number = Number(pieces[0]);
                return hour;
            }
        }
		return null;
	}

	/**
	 * Time string, expecting in format specified in param
	 * @param time (08:30)
	 */
	static extractMinuteFromTimeString(time:string):number {
		if(time && time.length > 4) {
            let pieces:Array<string> = time.split(':');
            if(pieces.length > 1) {
                let minute:number = Number(pieces[1]);
                return minute;
            }
        }
		return null;
	}

	/**
	 * Stringify any obj without prefixing converted privates with _
	 * modified from http://stackoverflow.com/questions/40080473/using-json-stringify-in-conjunction-with-typescript-getter-setter
	 * @param obj 
	 */
	static JSONStringify(obj:any):string {
			let json = JSON.stringify(obj);
			Object.keys(obj).filter(key => key[0] === '_').forEach(key => {
				json = json.replace(key, key.substring(1));
			});
			return json;
	}

	/**
	 * @param dateString (mm/dd/yyyy format)
	 */
	static isValidShortDateString(dateString: string): boolean {
		if (!dateString) {
			return false;
		}

		if (dateString.length < 10) {
			return false;
		}

		let tempDate = dateString.split('/');

		if (tempDate.length < 3) {
			return false;
		}

		let year: number = 0;
		let month: number = 0;
		let day: number = 0;

		if (tempDate[0].length === 2 && tempDate[1].length === 2 && tempDate[2].length === 4) {
			year = parseInt(tempDate[2]);
            month = parseInt(tempDate[0]);
            day = parseInt(tempDate[1]);
		} else {
			return false;
		}

		// Check the ranges of month and year
        if(year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;

        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

        // Adjust for leap years
        if(this.isLeapYear(year)){
            monthLength[1] = 29;
        }

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
	}

	/**
     * 
     * @param dateString (ISO format YYYY-MM-DDTHH:mm:ss.sssZ || Simple (ISO) String Format) YYYY-MM-DD)
     */
    static isValidDateInput(dateString : string) : boolean {

        if(!dateString) {
            return false;
        }

        if(dateString.length < 10){
            return false;
        }

        let tempDate = dateString.split('-');

        if(tempDate.length < 3) {
            return false;
        }

        let year:number = 0;
        let month:number = 0;
        let day:number = 0;

        // Check individual lengths
        if(tempDate[0].length === 4) {
            if(tempDate[1].length !== 2) {
                return false;
            }
            if(tempDate[2].length >= 2) {
                // cut off excess iso code data if present
                tempDate[2] = tempDate[2].substr(0, 2);
            } else {
                return false;
            }
            // iso format
            year = parseInt(tempDate[0]);
            month = parseInt(tempDate[1]);
            day = parseInt(tempDate[2]);
        } else {
			// Year str length for ISO or simple is not correct.
            return false;
        }

        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;

        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

        // Adjust for leap years
        if(this.isLeapYear(year)){
            monthLength[1] = 29;
        }

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];

    }

	/**
	 * There are inaccuracies when converting a simple date string like YYYY-MM-DD and time is excluded
	 * @param str Simple (ISO) String Format) YYYY-MM-DD
	 */
	static extendYearMonthDayFormatToFullISO(str:string):string {

		if (str && str.length !== 10) {
			//console.error('String length does not meet criteria for extending to full ISO format, supplied input: ' + str);
			return str;
		} else {
			let newStr: string = str + 'T00:00:00';
			if (Date.parse(newStr)) {
				return newStr;
			} else {
				//console.error('Not a valid ISO date format, supplied input: ' + newStr);
				return str;
			}
		}

	}

	/**
	 * Returns a Date object in local string formatt with no UTC or time zone offsets
	 * @param {Date} date the date to return as a formatted string
	 * @returns {string} the ISO string representation of the local date time ex. 2017-05-15T08:30:00
	 */
	static getLocalISOTimeDateString(date: Date) {

		let returnStr:string = date.getFullYear().toString();

		if(date.getMonth() + 1 < 10){
			returnStr += '-' + '0' + (date.getMonth() + 1);
		} else {
			returnStr += '-' + (date.getMonth() + 1);
		}
		if(date.getDate() < 10){
			returnStr += '-' + '0' + date.getDate();
		} else {
			returnStr += '-' + date.getDate();
		}
		
		returnStr += 'T';

		if (date.getHours() < 10) {
			returnStr += '0' + date.getHours() + ':';
		} else {
			returnStr += date.getHours() + ':';
		}
		if (date.getMinutes() < 10) {
			returnStr += '0' + date.getMinutes() + ':00';
		} else {
			returnStr += date.getMinutes() + ':00';
		}

		return returnStr;
		
	}

	static isLeapYear(year:number):boolean {
		let isLeap:boolean = (year % 400 === 0) || ((year % 4 === 0) && (year % 100 !== 0));
		return isLeap;
	}

	static getFutureDay(date:Date, multiplier:number = 1):Date {
		let cleanDate:Date = this.getCleanDate(date);
		cleanDate.setHours(6);
        if(multiplier < 1) {
            throw new Error('multiplier must be greater than 0');
        }
        let newDate:Date = new Date(cleanDate.getTime() + ((24 * 60 * 60 * 1000) * multiplier ));
        return this.getCleanDate(newDate);
    }

    static getPastDay(date:Date, multiplier:number = 1):Date {
		let cleanDate:Date = Utils.getCleanDate(date);
		cleanDate.setHours(6);
        if(multiplier < 1) {
            throw new Error('multiplier must be greater than 0');
        }
        let newDate:Date = new Date(cleanDate.getTime() - ((24 * 60 * 60 * 1000) * multiplier ));
        return this.getCleanDate(newDate);
    }

    static getCleanDate(date:Date):Date {
    	if (date) {
	        let cleanedDate:Date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    	    return cleanedDate;
    	}
    	let now = new Date();
    	return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    }
    
        /**
     * detect IE
     * returns version of IE or false, if browser is not Internet Explorer
     */
    static detectIE() {
        var ua = window.navigator.userAgent;

        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // IE 12 / Spartan
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge (IE 12+)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }
    
     static parseISOLocal(date: string): Date {

        let splitDate = date.split(/\D/);

        return new Date(Number(splitDate[0]), Number(splitDate[1])-1, Number(splitDate[2]), Number(splitDate[3]), Number(splitDate[4]), Number(splitDate[5] || '0'));

    }

	static generateSuffix() {
		return Math.floor(1000 + Math.random()  * 9000);
	}
}
