import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ErrorHandlerService } from "./error-handler.service";
import { LegalGuardian } from "../models/legal-guardian.model";
import { Observable } from "rxjs";

@Injectable()
export class LegalGuardianService {

    constructor(private http: HttpClient,
                private errorHandler: ErrorHandlerService) { }

    public getLegalGuardianInfo(userId: string, applicationId: number) : Observable<LegalGuardian> {
        const requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/applications/' + applicationId + '/legal-guardian';

        return  this.http.get(requestURI).pipe(
            map((res: any) => {
            return new LegalGuardian(res);
        }),
        catchError(error => this.errorHandler.handleError(error)));
    }

    public updateLegalGuardianInfo(userId: string, legalGuardian: LegalGuardian) : Observable<any>{ 
        const requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/legal-guardian';

        return this.http.put(requestURI, legalGuardian).pipe(
            map((res: Response) => {
                    // No JSON response
                return true;
            }),
            catchError(error => this.errorHandler.handleError(error)));
    }
}